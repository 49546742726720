// First we need to import axios.js
import axios from 'axios';

// Check if the Server Base URL has been set, if not throw an Error

// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
});

// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common.Authorization = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...

export default instance;
