/* eslint-disable react/prop-types */

import { useState, useEffect, useContext } from 'react';

import { Dialog } from 'primereact/dialog';

import { Steps } from 'primereact/steps';

import { Button } from 'primereact/button';
import ProductionPositionQRScan from 'elements/ProductionPositionRetriever';

import PlaceStackIllustration from 'elements/ProductionPosition/PlaceStackIllustration';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

function PickProductionPosition({
  onResult,
  onClose,
  display,
  productionPositionShouldExist = true,
  productionPositionShouldBeActive = false,
  boxList,
}) {
  const toast = useContext(ToastContext);

  const items = [{ label: 'Scan Position' }, { label: 'Confirm' }];

  const [activeIndex, setActiveIndex] = useState(0);
  const [productionPositionId, setProductionPositionId] = useState(null);

  useEffect(() => {
    if (productionPositionId && activeIndex === 0) {
      setActiveIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionPositionId]);
  const closeModal = () => {
    onClose();
  };

  const showPlaceStackIllustration = (productionPosition) => {
    toast.pushToast({
      severity: ToastSeverity.SUCCESS,
      detail: `ProductionPositionId: ${productionPosition}`,
    });
    // eslint-disable-next-line func-names
    setProductionPositionId(productionPosition);
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '40vw', height: '67vh' }}
      modal
      visible={display}
      onHide={closeModal}
    >
      <div className=" grid grid-nogutter full-height    ">
        <div className="col-12">
          <Steps model={items} activeIndex={activeIndex} />
        </div>
        {activeIndex === 0 && (
          <div className="col-10  mt-5 col-offset-1 ">
            <ProductionPositionQRScan
              onResult={(result) => showPlaceStackIllustration(result)}
              onClose={closeModal}
              productionPositionShouldExist={productionPositionShouldExist}
              productionPositionShouldBeActive={
                productionPositionShouldBeActive
              }
            />
          </div>
        )}

        {activeIndex === 1 && (
          <div className="col-12 mt-5">
            <PlaceStackIllustration
              boxList={boxList}
              productionPositionId={productionPositionId}
            />
          </div>
        )}

        <div className="col-12 flex justify-content-end align-items-center">
          {activeIndex === 1 && (
            <Button
              onClick={() => {
                onResult(productionPositionId);
                closeModal();
              }}
              icon="pi pi-check"
              className="p-button-rounded p-button-success mr-3 "
            />
          )}
          <Button
            onClick={closeModal}
            icon="pi pi-times"
            className="p-button-rounded p-button-danger  "
          />
        </div>
      </div>
    </Dialog>
  );
}

export default PickProductionPosition;
