/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';

function CreateFood({ data, display, onClose, onCompletion }) {
  const [charges, setCharges] = useState();
  const { name, foodID } = data;
  const toast = useContext(ToastContext);

  const columnsCharges = [
    { header: 'Charge ID', field: 'chargeID' },
    { header: 'Stack ID', field: 'stackID' },
    { header: 'Batch ID', field: 'batchID' },
    { header: 'Served Date', field: 'servedDate' },
    { header: 'Served Amount [kg]', field: 'servedAmount' },
  ];

  useEffect(() => {
    fetchFoodList(foodID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFoodList = async (FoodID) => {
    const ID = parseInt(FoodID.substring(3), 10);
    try {
      const chargesTemp = await axiosConfig
        .get('/foodLogistics/allCharges', { params: { ID } })
        .then((res) => res.data);
      setCharges(chargesTemp);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Food List',
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '90vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h3 className="flex align-items-center">
          {' '}
          Charges served with food: {name} (ID: {foodID})
        </h3>
      </div>
      <DataTable
        emptyMessage="No charges found"
        size="small"
        value={charges}
        responsiveLayout="scroll"
      >
        {columnsCharges.map(({ header, field }) => (
          <Column header={header} field={field} />
        ))}
      </DataTable>

      <div className="p-fluid formgrid grid">
        <div className="field col-12 mt-2 mb-5">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Close"
            onClick={() => onClose()}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default CreateFood;
