/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
/* eslint-disable func-names */

import { useState, useEffect, useContext } from 'react';

import axiosConfig from 'utils/axiosConfig';

import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';

import downloadPng from 'utils/downloadPng';

import { SelectedStackPositionProvider } from 'pages/Stabling/SelectStackPositionContext';
import AreaGrid from 'pages/FarmSetup/Area/AreaGrid';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

function FarmSetup() {
  const toast = useContext(ToastContext);

  // Fetch farm setup data from database, if there is no data, show a button with connected dialog to add data
  // const [loading, setLoading] = useState(true);
  const [productionPositionQR, setProductionPositionQR] = useState(null);
  const [farmSetup, setFarmSetup] = useState({}); // Farm setup has the following structure: {productionSiteLayout: {areaRows: 3, areaColumns:1} ,areas: [{ areaId: 1, areaPosition: {productionSiteRow: 0, productionSiteColumn: 1}, areaWidth: 1, areaLength: 1, areaLayoutRows: 1, areaLayoutColumns: 1, areaStackPositions: [] }, { areaId: 2, areaPosition: {productionSiteRow: 0, productionSiteColumn: 1}, areaWidth: 1, areaLength: 1, areaLayoutRows: 1, areaLayoutColumns: 1, areaStackPositions: [] }]}
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const farmSetupDb = await axiosConfig
          .get('/api/farmsetup')
          .then((res) => res.data);

        if (farmSetupDb.length !== 0) {
          setFarmSetup(farmSetupDb);
        }
        // setLoading(false);
      } catch (error) {
        console.log(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching Data',
        });
      }
    };

    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const printBoxQR = async (text) => {
    try {
      const qrCode = await axiosConfig.get(`/api/services/generateQRpng`, {
        params: { text },
      });
      const { png, text: filename } = qrCode.data;

      downloadPng(png, filename);

      // setBoxId(filename);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="main-card main-card-content ">
      <div className="mt-2 mb-5">
        <h2>Farm Setup</h2>
      </div>
      <div className="grid">
        <div className="col-4">
          <div className="grid">
            <div className="col-12">
              <div className="card-content ">
                <div className="mt-2">
                  <div className="grid">
                    <div className=" col-12 ">
                      <h3>Add New Area</h3>
                    </div>

                    <div> choose production site position</div>
                    {/* <div> choose area type</div> */}
                    <div>choose number of rows and columns of positions</div>

                    <div> choose at which positions boxes are placeable</div>

                    {/* <div> (print qr codes for each position)</div> */}

                    <div className="field col-12 flex justify-content-center mt-3 mb-2">
                      <Button
                        className="p-button-success p-button-rounded mr-2  "
                        // icon="pi pi-plus"
                        // onClick={() => dialogSubmitItemList()}
                        // disabled={!sievingItemList.length || !machineId}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card-content ">
                <div className="mt-2">
                  <div className="grid">
                    <div className=" col-12 ">
                      <h3>Download Production Position QR Codes</h3>

                      <InputMask
                        mask="PS-99999"
                        value={productionPositionQR}
                        type="text"
                        placeholder="PS-"
                        onChange={(e) =>
                          setProductionPositionQR(e.target.value)
                        }
                        keyfilter="pint"
                        // disabled
                      />
                      <Button
                        className="p-button-rounded p-button-outlined mr-2 mb-2"
                        onClick={() => printBoxQR(productionPositionQR)}
                        icon="pi pi-print"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-8 ">
          <div className="card-content ">
            <div className="mt-2 mb-3">
              <h3>Overview</h3>
            </div>
            {Object.keys(farmSetup).length ? (
              <SelectedStackPositionProvider>
                <AreaGrid farmSetup={farmSetup} />
              </SelectedStackPositionProvider>
            ) : (
              <div>
                <div>
                  <h6>No Farm Setup found</h6>
                </div>
                <div>
                  <Button
                    className="p-button-success p-button-rounded mr-2  "
                    // icon="pi pi-plus"
                    // onClick={() => dialogSubmitItemList()}
                    // disabled={!sievingItemList.length || !machineId}
                  >
                    Add Farm Setup
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FarmSetup;
