/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { useState, useContext, createContext } from 'react';

// A context that holds the active step index in the input page (There are 4 possible steps: Tell me about yourself, Passenger Data, Vehicle Data, and Tweet/Save)
const SelectedStackPosition = createContext(null);

const useSelectedStackPosition = () => {
  const [selectedPosition, setSelectedPosition, clickable] = useContext(
    SelectedStackPosition
  );

  const handleSelectedPosition = (position) => {
    setSelectedPosition(position);
  };

  return {
    selectedPosition,
    handleSelectedPosition,
    clickable,
  };
};

function SelectedStackPositionProvider({ children, clickable = false }) {
  const [selectedPosition, setSelectedPosition] = useState({});
  return (
    <SelectedStackPosition.Provider
      value={[selectedPosition, setSelectedPosition, clickable]}
    >
      {children}
    </SelectedStackPosition.Provider>
  );
}

export { SelectedStackPositionProvider, useSelectedStackPosition };
