/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-filename-extension */
import { createContext, useState, useRef } from 'react';

import { Toast } from 'primereact/toast';

export const ToastContext = createContext(undefined);

export const ToastSeverity = {
  SUCCESS: 'success',
  WARN: 'warn',
  ERROR: 'error',
};

// eslint-disable-next-line react/prop-types
export default function ToastContextWrapper({ children }) {
  const [toastData, setToastData] = useState({});
  const pushToast = (newToast) => setToastData(newToast);

  const toast = useRef(null);

  if (Object.keys(toastData).length) {
    toast.current.show({
      severity: toastData.severity,
      // summary: 'Error', // Not used for now, maybe later
      detail: toastData.detail,
      life: 3000,
    });
    setToastData({});
  }

  return (
    <>
      <Toast ref={toast} />
      <ToastContext.Provider
        value={{
          value: toastData,
          pushToast,
        }}
      >
        {children}
      </ToastContext.Provider>
    </>
  );
}
