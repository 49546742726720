import React from 'react';
import ReactDOM from 'react-dom';

import ToastContextWrapper from 'utils/toastContextWrapper';
import { AuthContextProvider } from 'store/auth-context';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <ToastContextWrapper>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </ToastContextWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
