import { useState, useMemo } from 'react';

import { Button } from 'primereact/button';
// eslint-disable-next-line import/no-unresolved
import FoodMain from './FoodMain';
import FoodDelivery from './FoodDelivery';
import Recipes from './Recipes';
import { FoodContext } from './FoodContext';

function FoodLogisticsMain() {
  // STATES
  const [show, setShow] = useState('');

  const providerValue = useMemo(() => ({ show, setShow }), [show, setShow]);

  //   useEffect(() => {
  //     setShow('create');
  //   }, []);

  const renderSwitch = () => {
    switch (show) {
      case 'food':
        return (
          <FoodContext.Provider value={providerValue}>
            <FoodMain />
          </FoodContext.Provider>
        );
      case 'delivery':
        return (
          <FoodContext.Provider value={providerValue}>
            <FoodDelivery />
          </FoodContext.Provider>
        );
      case 'recipes':
        return (
          <FoodContext.Provider value={providerValue}>
            <Recipes />
          </FoodContext.Provider>
        );
      default:
        return (
          <div className="main-card flex align-items-center justify-content-center">
            <div style={{ height: '50%', width: '30%', marginRight: '2rem' }}>
              <Button
                style={{ height: '100%', width: '100%', fontSize: '28px' }}
                label="Food"
                className="p-button-outlined   "
                onClick={() => setShow('food')}
              />
            </div>
            <div style={{ height: '50%', width: '30%', marginRight: '2rem' }}>
              <Button
                style={{ height: '100%', width: '100%', fontSize: '28px' }}
                label="Ingredient Delivery"
                className="p-button-outlined  "
                onClick={() => setShow('delivery')}
              />
            </div>
            <div style={{ height: '50%', width: '30%' }}>
              <Button
                style={{ height: '100%', width: '100%', fontSize: '28px' }}
                label="Recipes"
                className="p-button-outlined"
                onClick={() => setShow('recipes')}
              />
            </div>
          </div>
        );
    }
  };

  // RENDER
  return <>{renderSwitch()}</>;
}

export default FoodLogisticsMain;
