const checkInputMissingExceptComment = (item) => {
  const { comment, ...itemNoComment } = item;
  return Object.values(itemNoComment).some(
    (field) => field === '' || field === null
  );
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

export { checkInputMissingExceptComment, getCookie };
