/* eslint-disable react/prop-types */
import { useState, useContext, useEffect, useCallback } from 'react';
import axiosConfig from 'utils/axiosConfig';
import QrReader from 'react-qr-reader';

import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { InputMask } from 'primereact/inputmask';

export async function checkBoxes({
  boxIdList,
  boxesShouldExist = true,
  boxesShouldBeActive = false,
}) {
  const boxes = await axiosConfig
    .get(`/boxLogistics/checkList`, {
      params: { boxIdList },
    })
    .then((res) => res.data);
  // Return a toast if the box does not exist
  if (boxes.length !== boxIdList.length && boxesShouldExist) {
    return [
      {
        severity: ToastSeverity.ERROR,
        detail: `Box or Boxes do not exist!`,
      },
    ];
  }

  const toasts = [];
  boxes.forEach((box, idx) => {
    switch (true) {
      case box && !boxesShouldExist:
        toasts[idx] = {
          severity: ToastSeverity.ERROR,
          detail: `${box.boxId} already exists!`,
        };
        break;

      case box.Status === 'active' && !boxesShouldBeActive:
        toasts[idx] = {
          severity: ToastSeverity.ERROR,
          detail: `${box.boxId} is already active!`,
        };
        break;

      case (box.Status === 'inactive' || box.Status === 'new') &&
        boxesShouldBeActive:
        toasts[idx] = {
          severity: ToastSeverity.ERROR,
          detail: `${box.boxId} is ${box.Status} and has no Stack!`,
        };
        break;

      default:
        return false;
    }
    return false;
  });
  if (toasts && toasts.length !== 0) {
    return toasts;
  }
  return false;
}

export async function getBoxIds(chargeIds, cb, toast) {
  try {
    const boxAndChargeIds = await axiosConfig
      .get('/boxLogistics/boxesForCharges', {
        params: {
          chargeIds,
        },
      })
      .then((res) => res.data);
    cb(boxAndChargeIds);
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: error.response.status + error.response.statusText,
    });
  }
}

export async function getBoxIdsFromBatchIds(batchIds, cb, toast) {
  try {
    const boxAndChargeIds = await axiosConfig
      .get('/boxLogistics/boxesForBatches', {
        params: {
          batchIds,
        },
      })
      .then((res) => res.data);
    cb(boxAndChargeIds);
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: error.response.status + error.response.statusText,
    });
  }
}

// check Incomplete Batches
// takes in list of boxes [{boxIds,....}] and callback function that return list of incomplete batchIds
export async function checkIncompleteBatch(boxes, cb, toast) {
  if (!boxes || !boxes.length) cb([]);
  else
    try {
      const inCompleteBatchIds = await axiosConfig
        .get('/boxLogistics/checkIncompleteBatch', {
          params: {
            boxIds: boxes.map(({ boxId }) => boxId),
          },
        })
        .then((res) => res.data);
      cb(inCompleteBatchIds);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.statusText,
      });
    }
}

export async function handleExternalBoxScan(
  scanResult,
  mask,
  itemList,
  toast,
  cb,
  boxIdNotList = [],
  scanMode = 'Box',
  boxesShouldExist = true,
  boxesShouldBeActive = true
) {
  // Check if box is already in list
  if (itemList.some((item) => Object.values(item).includes(scanResult))) {
    return false;
  }
  if (
    scanResult === null &&
    scanResult.replace(/_/g, '').length === mask.length
  ) {
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'No QR Code found!',
    });
  }

  if (
    scanResult !== null &&
    scanResult.replace(/_/g, '').length === mask.length
  ) {
    const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

    if (resultItemPrefix !== 'BX' || !resultItemIdValue) {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'QR Code not valid!',
      });
      return false;
    }

    // Check if box is already in current stack list or one of the other stacks or batch
    const boxAlreadyInStackList = boxIdNotList.includes(scanResult);

    if (boxAlreadyInStackList) {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Box is already in one of the Stack Lists!',
      });
      return false;
    }

    // Check if boxes exist and are active
    const toastMsgs = await checkBoxes({
      boxIdList: [{ boxId: scanResult }],
      boxesShouldExist,
      boxesShouldBeActive,
      boxIdNotList,
    });

    let boxIdList = [];
    // If scanMode = 'stack' or 'batch', get all boxes in current stack or batch
    // get charge id for the box
    if (
      !toastMsgs &&
      boxesShouldBeActive &&
      (scanMode === 'stack' || scanMode === 'batch')
    ) {
      boxIdList = await axiosConfig
        .get(`/boxLogistics/${scanMode}`, {
          params: {
            boxId: scanResult,
          },
        })
        .then((res) => res.data);
    } else if (!toastMsgs && boxesShouldBeActive) {
      boxIdList = await axiosConfig
        .get('/boxLogistics/chargesForBoxes', {
          params: {
            boxIds: [scanResult],
          },
        })
        .then((res) => res.data);
    } else if (!toastMsgs) {
      boxIdList = [{ boxId: scanResult }];
    } else {
      toastMsgs.forEach((msg) => toast.pushToast(msg));
      return false;
    }

    // Check if all boxes in boxIdList are not already in list, stack list or other stack list

    // Add boxes to list
    cb([
      ...itemList,
      ...boxIdList.filter(
        ({ boxId }) => !itemList.some((box) => box.boxId === boxId)
      ),
    ]);

    return true;
  }

  return false;
}

/* 
OUTPUT: 
  onResult: List of boxIds
  onClose: Closes the dialog

*/
function BoxQRScan({
  onResult,
  onClose,
  display,
  boxesShouldBeActive = false,
  itemListInit = [],
  boxesShouldExist = false,
  boxIdNotList = [], // List of boxIds that should not be in the list
  scanModeList = ['series', 'stack', 'batch'], // List of scan modes to use
  batchesShouldBeComplete = false,
}) {
  const MASK = 'aa-99999';
  // CONTEXTS
  const toast = useContext(ToastContext);

  // STATES
  const [charges, setCharges] = useState([]);
  const [boxList, setBoxList] = useState([]);
  const [triggerPressed, setTriggerPressed] = useState(false);
  const [itemList, setItemList] = useState(itemListInit);
  const [scanMode, setScanMode] = useState(scanModeList[0]);
  const [incompleteBatchIds, setIncompleteBatchIds] = useState([]);

  const callbackRef = useCallback((inputElement) => {
    if (inputElement && !inputElement.props) {
      inputElement.focus();
      inputElement.select();
    }
  }, []);

  const handleFocus = (event) => event.target.select();

  const selectButtonOptions = scanModeList.map((mode) => ({
    name: mode[0].toUpperCase() + mode.substring(1),
    value: mode,
  }));
  // CONSTANTS replace with env
  const DEV_ENVIRONMENT = true;

  const getChargeIds = useCallback(
    async (boxIds) => {
      try {
        const boxAndChargeIds = await axiosConfig
          .get('/boxLogistics/chargesForBoxes', {
            params: {
              boxIds,
            },
          })
          .then((res) => res.data);
        setItemList(boxAndChargeIds);
      } catch (error) {
        console.error(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: error.response.status + error.response.statusText,
        });
      }
    },
    [setItemList, toast]
  );

  useEffect(() => {
    DEV_ENVIRONMENT && getCharges();
    DEV_ENVIRONMENT && getBoxList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // get box Ids for charge id
    DEV_ENVIRONMENT &&
      itemList.some((charge) => charge.chargeId && !charge.boxId) &&
      getBoxIds(
        itemList.map((item) => item.chargeId),
        (boxListTemp) => setBoxList(boxListTemp),
        toast
      );
    // get charge id for box ids
    boxesShouldBeActive &&
      itemList.some(
        (charge) => (!charge.chargeId || !charge.batchId) && charge.boxId
      ) &&
      getChargeIds(itemList.map((item) => item.boxId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    itemList.some((charge) => !charge.boxId && charge.chargeId) &&
      getBoxIds(
        itemList.map((item) => item.chargeId),
        (boxAndChargeIds) => setItemList(boxAndChargeIds),
        toast
      );
    batchesShouldBeComplete &&
      boxesShouldBeActive &&
      checkIncompleteBatch(
        itemList,
        (batchIds) => setIncompleteBatchIds(batchIds),
        toast
      );
  }, [
    DEV_ENVIRONMENT,
    boxesShouldBeActive,
    batchesShouldBeComplete,
    getChargeIds,
    itemList,
    toast,
  ]);
  const getCharges = async () => {
    try {
      const batchesTemp = await axiosConfig
        .get('/boxLogistics/charges')
        .then((res) => res.data);
      const chargesTemp = [];
      if (Array.isArray(batchesTemp)) {
        batchesTemp.map(
          (batch) =>
            Array.isArray(batch.stacks) &&
            batch.stacks.map(
              (stack) =>
                Array.isArray(stack.boxes) &&
                stack.boxes.map(
                  (box) =>
                    !chargesTemp.some(
                      (charge) => charge.chargeId === box.chargeId
                    ) && chargesTemp.push(box)
                )
            )
        );
      }
      setCharges(chargesTemp);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching charges list',
      });
    }
  };

  const getBoxList = async () => {
    try {
      return axiosConfig
        .get('/boxLogistics/table')
        .then((res) =>
          setBoxList(
            res.data.filter(({ boxId }) => !boxIdNotList.includes(boxId))
          )
        );
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const boxOptionTemplate = (rowData) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>{rowData.boxId}</div>
        <span className={`charge-badge charge-badge-${rowData.Status} `}>
          {rowData.Status}
        </span>
      </div>
    );
  };

  const boxValueTemplate = (option) => {
    if (option) {
      return <span>{option.boxId}; </span>;
    }
    return '<dev> Select boxes...';
  };

  const handleScan = async (scanResult) => {
    if (scanResult === null && triggerPressed) {
      setTriggerPressed(false);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No QR Code found!',
      });
    }

    if (scanResult !== null && triggerPressed) {
      setTriggerPressed(false);

      const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

      if (resultItemPrefix !== 'BX' || !resultItemIdValue) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code not valid!',
        });
        return false;
      }

      // Check if box is already in list
      const boxAlreadyInList = itemList.some((item) =>
        Object.values(item).includes(scanResult)
      );
      if (boxAlreadyInList) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Box is already in List!',
        });
        return false;
      }

      // Check if box is already in current stack list or one of the other stacks or batch
      const boxAlreadyInStackList = boxIdNotList.includes(scanResult);

      if (boxAlreadyInStackList) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Box is already in one of the Stack Lists!',
        });
        return false;
      }

      let boxIdList = [];
      // If scanMode = 'stack' or 'batch', get all boxes in current stack or batch
      // get charge id for the box
      if (scanMode === 'stack' || scanMode === 'batch') {
        boxIdList = await axiosConfig
          .get(`/boxLogistics/${scanMode}`, {
            params: {
              boxId: scanResult,
            },
          })
          .then((res) => res.data);
      } else {
        // eslint-disable-next-line no-multi-assign
        boxIdList = boxIdList = await axiosConfig
          .get('/boxLogistics/chargesForBoxes', {
            params: {
              boxIds: [scanResult],
            },
          })
          .then((res) => res.data);
      }

      // Check if boxes exist and are active
      const toastMsgs = await checkBoxes({
        boxIdList,
        boxesShouldExist,
        boxesShouldBeActive,
        boxIdNotList,
      });
      // push toast message if there are any
      if (toastMsgs.length) {
        toastMsgs.forEach((msg) => toast.pushToast(msg));
        return false;
      }

      // Check if all boxes in boxIdList are not already in list, stack list or other stack list

      // Add boxes to list
      setItemList([...itemList, ...boxIdList]);

      return true;
    }

    return false;
  };

  const deleteItem = async (itemData) => {
    // filter out the item to be deleted from itemlist and set it to the state
    const newItemList = itemList.filter((item) => {
      return item.boxId !== itemData.boxId;
    });
    setItemList(newItemList);
  };

  const deleteItemTemplate = (itemData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-sm p-button-outlined "
        onClick={() => deleteItem(itemData)}
      />
    );
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-end mb-2">
          <Button
            onClick={() => onClose()}
            icon="pi pi-times"
            className="p-button-rounded p-button-warning p-button-outlined "
          />
        </div>
        <div className="col-6">
          <div className="grid grid-no-gutters">
            <div className="col-8 col-offset-1 mb-2">
              <SelectButton
                value={scanMode}
                options={selectButtonOptions}
                onChange={(e) => setScanMode(e.value)}
                optionLabel="name"
              />
            </div>
            <div className="col-10 ">
              <QrReader delay={300} onScan={handleScan} />
              <InputMask
                id="boxId"
                name="boxIdInputMask"
                ref={callbackRef}
                onFocus={handleFocus}
                mask={MASK}
                placeholder="Scan Box Barcode or QR Code"
                onChange={(e) =>
                  handleExternalBoxScan(
                    e.value,
                    MASK,
                    itemList,
                    toast,
                    (list) => setItemList(list),
                    boxIdNotList,
                    scanMode,
                    boxesShouldExist,
                    boxesShouldBeActive
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="col-4 col-offset-1">
          <DataTable
            value={itemList.filter(
              ({ boxId }) => !boxIdNotList.includes(boxId)
            )}
            size="small"
            showGridlines
            scrollable
            scrollHeight="351px"
          >
            <Column field="boxId" header="Box ID" sortable />
            <Column field="chargeId" header="Charge ID" sortable />
            <Column field="batchId" header="Batch ID" sortable />
            <Column
              body={deleteItemTemplate}
              className="justify-content-center"
            />
          </DataTable>
          <div className="col-12">
            {DEV_ENVIRONMENT && (
              <MultiSelect
                value={
                  Array.isArray(itemList) &&
                  itemList.map((box) =>
                    charges.find(({ boxId }) => boxId === box.boxId)
                  )
                }
                onChange={(e) => setItemList(Array.isArray(e.value) && e.value)}
                options={charges}
                placeholder="<dev> Select charges..."
                optionLabel="chargeId"
              />
            )}
            {DEV_ENVIRONMENT && (
              <MultiSelect
                value={
                  Array.isArray(itemList) &&
                  itemList.map((box) =>
                    boxList.find(({ boxId }) => boxId === box.boxId)
                  )
                }
                onChange={(e) => setItemList(Array.isArray(e.value) && e.value)}
                options={boxList.filter((box) =>
                  boxesShouldBeActive
                    ? box.Status === 'active'
                    : box.Status !== 'active'
                )}
                itemTemplate={boxOptionTemplate}
                selectedItemTemplate={boxValueTemplate}
              />
            )}
          </div>
        </div>
        <div className=" col-12 flex justify-content-between mt-2">
          <Button
            onClick={() => setTriggerPressed(true)}
            icon="pi pi-camera"
            className="p-button-rounded p-button-outlined"
          />
          {batchesShouldBeComplete && incompleteBatchIds.length ? (
            <span style={{ color: 'orange' }}>
              The following incomplete batch
              {incompleteBatchIds.length === 1 ? null : 'es'} will be split on
              work item: {incompleteBatchIds.join(', ')}
            </span>
          ) : null}
          <Button
            onClick={() => {
              onResult(
                itemList.filter(({ boxId }) => !boxIdNotList.includes(boxId))
              );
              onClose();
            }}
            icon="pi pi-check"
            className="p-button-rounded p-button-success  p-button-outlined "
            disabled={!itemList.length}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default BoxQRScan;
