/* eslint-disable react/prop-types */
import { useContext, useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { FoodDeliveryContext } from './FoodContext';

function FoodAssignDelivery({ data, display, onClose, onCompletion }) {
  const [inComplete, setInComplete] = useState(true);
  const [assignedDelivery, setAssignedDelivery] = useState({
    weight: 0.0,
    delivery: { deliveryID: null, remainingAmount: 0.0 },
  });
  const { ingredientList, setIngredientList } = useContext(FoodDeliveryContext);

  if (data.deliveries === undefined) onClose();

  const deliveriesList = data.deliveries
    .filter(({ remainingAmount }) => remainingAmount > 0)
    .map((delivery) => ({
      value: delivery,
      label: delivery.deliveryID,
    }));

  useEffect(() => {
    setInComplete(false);
    for (const key of Object.keys(assignedDelivery)) {
      if (
        key !== 'comment' &&
        (assignedDelivery[key] === null ||
          assignedDelivery[key] === undefined ||
          assignedDelivery[key] === '' ||
          assignedDelivery[key] === 0)
      ) {
        setInComplete(true);
      }
    }
  }, [assignedDelivery]);

  const handleFocus = (event) => event.target.select();

  const headerText = () => {
    const text = `Assign Deliveries to Ingredient: ${data.ingredientName}`;
    return <h3>{text}</h3>;
  };

  const assignDelivery = () => {
    // temporty ingredient list loaded from context
    const ingredientListTemp = ingredientList;

    // get indexes for ingredient and selected delivery
    const ingredientIndex = ingredientListTemp.findIndex(
      (ingredient) => ingredient.ingredientID === data.ingredientID
    );
    const deliveriesIndex = ingredientListTemp[
      ingredientIndex
    ].deliveries.findIndex(
      (delivery) => delivery.deliveryID === assignedDelivery.delivery.deliveryID
    );

    // get subtract assigned weight from delivery weight
    ingredientListTemp[ingredientIndex].deliveries[
      deliveriesIndex
    ].remainingAmount -= assignedDelivery.weight;

    // add assigned weight total assigned weight
    ingredientListTemp[ingredientIndex].assignedWeight +=
      assignedDelivery.weight;

    // if delivery isn't assigned to once already, push assigned to delivery to assigned list
    // and add one to the number assigned deliveries. Else add weight to previously assigned delivery
    if (
      ingredientListTemp[ingredientIndex].assignedDeliveries.filter(
        (delivery) =>
          delivery.deliveryID === assignedDelivery.delivery.deliveryID
      ).length === 0
    ) {
      ingredientListTemp[ingredientIndex].assignedDeliveries.push({
        deliveryID: assignedDelivery.delivery.deliveryID,
        assignedAmount: assignedDelivery.weight,
        currentAmount:
          ingredientListTemp[ingredientIndex].deliveries[deliveriesIndex]
            .remainingAmount,
        bestBeforeDate: assignedDelivery.delivery.bestBeforeDay,
      });

      ingredientListTemp[ingredientIndex].numberOfAssignedDeliveries += 1;
    } else {
      const assignedDeliveriesIndex = ingredientListTemp[
        ingredientIndex
      ].assignedDeliveries.findIndex(
        (delivery) =>
          delivery.deliveryID === assignedDelivery.delivery.deliveryID
      );
      ingredientListTemp[ingredientIndex].assignedDeliveries[
        assignedDeliveriesIndex
      ] = {
        ...ingredientListTemp[ingredientIndex].assignedDeliveries[
          assignedDeliveriesIndex
        ],
        currentAmount:
          ingredientListTemp[ingredientIndex].deliveries[deliveriesIndex]
            .remainingAmount,
        assignedAmount:
          ingredientListTemp[ingredientIndex].assignedDeliveries[
            assignedDeliveriesIndex
          ].assignedAmount + assignedDelivery.weight,
        bestBeforeDate: assignedDelivery.delivery.bestBeforeDay,
      };
    }
    setIngredientList(ingredientListTemp);
    onCompletion();
    onClose();
  };

  const deliveryTemplate = (rowData) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <span
          style={{ color: rowData.value?.bestBeforeDay < -1 ? 'red' : 'black' }}
        >
          {rowData.label}
        </span>
        <span className="charge-badge">
          Best By: {rowData.value.bestBeforeDate}
        </span>
        <span className="charge-badge">Supplier: {rowData.value.supplier}</span>
        <span
          className={`charge-badge charge-badge-${
            rowData.value?.remainingAmount ? 'active' : 'inactive'
          } `}
        >
          {`${rowData.value.remainingAmount} kg`}
        </span>
      </div>
    );
  };

  const handleSelectDelivery = (value) => {
    setAssignedDelivery({
      ...assignedDelivery,
      delivery: value,
      weight:
        value.remainingAmount > data.targetWeight
          ? data.targetWeight
          : value.remainingAmount,
    });
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className="field col-12 flex justify-content-between mb-2">
          {headerText()}
        </div>
        <div className="field col-8">
          <label htmlFor="deliveryID">Delivery ID</label>
          <Dropdown
            id="deliveryID"
            options={deliveriesList}
            value={assignedDelivery.delivery}
            onChange={(e) => handleSelectDelivery(e.value)}
            placeholder="Select Delivery..."
            itemTemplate={deliveryTemplate}
          />
        </div>
        <div className="field col-4">
          <label htmlFor="weight">
            Weight (Target Weight: {data?.targetWeight} kg):
          </label>
          <div className="p-inputgroup">
            <InputNumber
              id="weight"
              value={assignedDelivery.weight}
              max={assignedDelivery.delivery.remainingAmount}
              onChange={(e) =>
                setAssignedDelivery({
                  ...assignedDelivery,
                  weight:
                    e.value > assignedDelivery.delivery.remainingAmount
                      ? assignedDelivery.delivery.remainingAmount
                      : e.value,
                })
              }
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              maxFractionDigits={3}
              onFocus={handleFocus}
              disabled={
                assignedDelivery.delivery.deliveryID === null ||
                assignedDelivery.delivery.deliveryID === undefined
              }
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Target food weight"
            >
              kg
            </span>
          </div>
        </div>
        <div className=" col-4" />
        <div className=" col-4 ">
          <Button
            onClick={() => onClose()}
            label="Cancel"
            className="p-button-rounded p-button-secondary"
          />
        </div>
        <div className="field col-4 ">
          <Button
            label="Assign"
            onClick={() => assignDelivery()}
            className="p-button-rounded p-button-success"
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default FoodAssignDelivery;
