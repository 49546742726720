/* eslint-disable react/prop-types */
import classNames from 'classnames';

import { useSelectedStackPosition } from 'pages/Stabling/SelectStackPositionContext';

function Stack({ areaColumns, spot, areaId, rowId, columnId, status }) {
  const { handleSelectedPosition, clickable } = useSelectedStackPosition();

  const stackClass = classNames('stack-badge', `stack-badge-${status}`, {
    'stack-badge-multiple-columns': areaColumns > 1,
    clickable,
  });

  return clickable ? (
    <span
      className={stackClass}
      role="button"
      tabIndex={0}
      onClick={() => {
        handleSelectedPosition({ areaId, rowId, columnId });
      }}
      onKeyDown={() => {
        handleSelectedPosition({ areaId, rowId, columnId });
      }}
    >
      {spot}
    </span>
  ) : (
    <span className={stackClass}>{spot}</span>
  );
}

export default Stack;
