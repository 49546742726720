import { useEffect, useContext } from 'react';
import AuthContext from 'store/auth-context';
import { getCookie } from 'utils/utils';
import { Navigate } from 'react-router-dom';

function LandingPage() {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  function handleCallbackResponse(response) {
    // send the JWT to the backend for verification
    // and save the user in the state
    authContext.login(response.credential);
  }

  function initAndRenderGoogleSignin() {
    const { google } = window;
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      theme: 'outline',
      size: 'large',
    });
  }

  useEffect(() => {
    if (!getCookie('session-token')) {
      // check if the google-sign in script is loaded
      if (!window.google) {
        // on first render, only load if the google sign in script is loaded
        document
          .getElementById('google-signin')
          .addEventListener('load', () => {
            initAndRenderGoogleSignin();
          });
      } else {
        initAndRenderGoogleSignin();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) {
    return <Navigate to="/overview" />;
    // return <h1> You are signed in! </h1>;
  }
  return (
    <div
      id="signInDiv"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  );
}

export default LandingPage;
