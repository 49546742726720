/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { FaUtensils } from 'react-icons/fa';
import { Dropdown } from 'primereact/dropdown';
import { FoodContext } from './FoodContext';
import CreateRecipe from './RecipeCreate';
import DeleteRecipeConfirmation from './RecipeDeleteConfirmation';

const addPrefix = (id, prefix, numLeadingZeroes) =>
  `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;

function Recipes() {
  const toast = useContext(ToastContext);
  const [action, setAction] = useState();
  const [expandedRows, setExpandedRows] = useState(null);
  const { setShow } = useContext(FoodContext);
  const [recipeList, setRecipeList] = useState([]);
  const [newRecipeId, setNewRecipeId] = useState();
  const [refreshFoodList, setRefreshFoodList] = useState(false);

  useEffect(() => {
    fetchRecipeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRecipeList();
    setRefreshFoodList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFoodList]);

  const ratioDecimal = (rowData) => {
    // eslint-disable-next-line no-nested-ternary
    return typeof rowData.ratio === 'number'
      ? rowData.ratio.toFixed(2)
      : typeof rowData.ratio === 'string'
      ? parseFloat(rowData.ratio).toFixed(2)
      : '0.00';
  };

  const columns = [
    { header: 'Name', field: 'recipeName' },
    { header: 'Recipe-Id', field: 'recipeId' },
    { header: 'Ingredients', field: 'numberOfIngredients' },
    { header: 'Notes', field: 'notes' },
  ];

  const columnsRecipe = [
    { header: 'Ingredient Type', field: 'ingredient.ingredientName' },
    { header: 'Proportion [%]', field: 'ratio', body: ratioDecimal },
  ];

  const fetchRecipeList = async () => {
    try {
      const recipeListJSON = await axiosConfig
        .get('/foodLogistics/recipes')
        .then((res) => res.data);
      if (recipeListJSON !== null) {
        const recipeListTemp = [];
        recipeListJSON.map(
          // eslint-disable-next-line array-callback-return
          ({ RecipeID, Name, Status, Notes, Ingredients }, key) => {
            if (Status === 'active' || Status === 'new') {
              recipeListTemp.push({
                key,
                recipeName: Name,
                recipeId: addPrefix(RecipeID, 'RE', 5),
                numberOfIngredients: Ingredients ? Ingredients.length : 0,
                ingredients: Ingredients
                  ? Ingredients.map((ingredient, prop) => ({
                      key: prop,
                      ingredient: {
                        ingredientId: ingredient.IngredientID,
                        ingredientName: ingredient.Name,
                      },
                      ratio: ingredient.RecipeIngredient.Ratio * 100,
                    }))
                  : [],
                notes: Notes,
              });
            }
          }
        );
        const newRecipeIDTemp =
          1 + Math.max(...recipeListJSON.map((recipe) => recipe.RecipeID));
        setNewRecipeId(newRecipeIDTemp);
        setRecipeList(recipeListTemp);
      }
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching recipes List',
      });
    }
  };

  const recipeTable = (rowData) => {
    if (!rowData.ingredients) return <span>no data</span>;
    return (
      <div className="grid orders-subtable">
        <div className="col-6">
          <h5>Recipe{`'`}s Ingredients</h5>
          <DataTable value={rowData.ingredients} size="small">
            {columnsRecipe.map(({ header, field, body }) => (
              <Column header={header} field={field} body={body || null} />
            ))}
          </DataTable>
        </div>
      </div>
    );
  };

  const actionDialog = () => {
    if (action === null || action === undefined) return null;
    switch (action.name) {
      case 'edit':
        return (
          <CreateRecipe
            data={action.actionData}
            newRecipeId={newRecipeId}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'create':
        return (
          <CreateRecipe
            data={null}
            display={Boolean(true)}
            newRecipeId={newRecipeId}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'delete':
        return (
          <DeleteRecipeConfirmation
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      default:
        return null;
    }
  };

  const rowAction = (rowData) => (
    <Dropdown
      options={[
        { label: 'Delete', value: 'delete' },
        { label: 'Edit', value: 'edit' },
      ]}
      onChange={(e) => {
        setAction({ name: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  return (
    <div className="main-card main-card-content">
      {action !== null && actionDialog()}
      <div className="formgrid grid">
        <div className="field col-1 mt-2 mb-5">
          <Button
            className="p-button-rounded p-button-outlined p-button-secondary"
            icon="pi pi-arrow-left"
            onClick={() => setShow(null)}
          />
        </div>
        <div className="field col-11 mt-2 mb-5">
          <h2>
            <FaUtensils className="mr-3" /> Recipes
          </h2>
        </div>
        <div className="field col-12 mt-2 mb-5">
          <DataTable
            emptyMessage="No Recipes found"
            value={recipeList}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            responsiveLayout="scroll"
            rowExpansionTemplate={recipeTable}
            dataKey="recipeId"
          >
            <Column expander style={{ width: '3em' }} />
            {columns.map(({ header, field, body }) => (
              <Column header={header} field={field} body={body || null} />
            ))}
            <Column header="Action" body={rowAction} />
          </DataTable>
        </div>
      </div>
      <div className="p-fluid formgrid grid">
        <div className="field col-12 mt-2 mb-5">
          <Button
            className="p-button-outlined p-button-success p-button-rounded"
            label="Create Recipe"
            onClick={() => setAction({ name: 'create', actionData: null })}
          />
        </div>
      </div>
    </div>
  );
}

export default Recipes;
