import { useState, useEffect, useMemo } from 'react';

import { Button } from 'primereact/button';
// eslint-disable-next-line import/no-unresolved
import StablingCreateStack from './StablingCreateStack';
import StablingExtendStack from './StablingExtendStack';
import StablingContext from './StablingContext';

function StablingMain() {
  // STATES
  const [show, setShow] = useState('create');
  const [stablingValues, setStablingValues] = useState(null);

  const providerValue = useMemo(
    () => ({ stablingValues, setStablingValues }),
    [stablingValues, setStablingValues]
  );

  useEffect(() => {
    setShow('create');
  }, []);

  const renderSwitch = () => {
    switch (show) {
      case 'create':
        return (
          <StablingContext.Provider value={providerValue}>
            <StablingCreateStack />
          </StablingContext.Provider>
        );
      case 'extend':
        return (
          <StablingContext.Provider value={providerValue}>
            <StablingExtendStack />
          </StablingContext.Provider>
        );
      default:
        return (
          <div className="main-card flex align-items-center justify-content-center">
            <div style={{ height: '50%', width: '30%', marginRight: '5rem' }}>
              <Button
                style={{ height: '100%', width: '100%', fontSize: '28px' }}
                label="Create Batch"
                className="p-button-outlined   "
                onClick={() => setShow('create')}
              />
            </div>
            <div style={{ height: '50%', width: '30%' }}>
              <Button
                style={{ height: '100%', width: '100%', fontSize: '28px' }}
                label="Extend Batch"
                className="p-button-outlined p-button-secondary  "
                onClick={() => setShow('extend')}
                disabled
              />
            </div>
          </div>
        );
    }
  };

  // RENDER
  return <>{renderSwitch()}</>;
}

export default StablingMain;
