const downloadPng = (png, filename) => {
  const a = document.createElement('a'); // Create <a>
  a.href = png;
  a.download = `${filename}.png`;
  a.click(); // Downloaded file
  // Delete element !!remove is github copilot, might be wrong
  a.remove();
};

export default downloadPng;
