import { handleExternalBoxScan } from 'elements/BoxQRScan';
import { handleExternalEggScan } from 'elements/EggQRScan';
import { handleExternalFoodScan } from 'elements/FoodQRScan';
import { ToastSeverity } from './toastContextWrapper';

export default function handleExternalInput(
  input,
  element,
  mask,
  toast,
  onResult,
  cb,
  boxes = [],
  boxIdNotList = [],
  boxesShouldExist = true,
  boxesShouldBeActive = false
) {
  const list = input.split('-');
  const maskList = mask.split('-');
  if (
    list.length === maskList.length &&
    list[0].length === maskList[0].length &&
    list[1].length === maskList[1].length
  ) {
    switch (list[0]) {
      case 'EG':
        handleExternalEggScan(input, mask, 'EG', toast, onResult.EG, null);
        break;
      case 'FD':
        handleExternalFoodScan(input, mask, 'FD', toast, onResult.FD, null);
        break;
      case 'BX':
        handleExternalBoxScan(
          input,
          mask,
          boxes,
          toast,
          onResult.BX,
          boxIdNotList,
          'Box',
          boxesShouldExist,
          boxesShouldBeActive
        );
        break;
      default:
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code prefix is not valid',
        });
        break;
    }
    element && element.focus();
    cb && cb();
  } else {
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'QR Code format not valid',
    });
  }
}
