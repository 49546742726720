import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useContext, useState } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

// eslint-disable-next-line react/prop-types
export default function CreateInspectionPlan({ visible, onClose, onComplete }) {
  const [inspectionPlan, setInspectionPlan] = useState({
    name: '',
    description: '',
    tasks: [],
  });
  const toast = useContext(ToastContext);

  const createInspectionPlan = async () => {
    try {
      await axiosConfig.post('/inspection/create', inspectionPlan).then(() => {
        toast.pushToast({
          severity: ToastSeverity.SUCCESS,
          detail: 'Created inspection plan',
        });
        onComplete();
        onClose();
      });
    } catch (err) {
      console.error(err);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: err.response.status + err.response.statusText,
      });
    }
  };

  const types = ['Bool', 'Float', 'Integer'];

  const deleteTaskTemplate = (rowData) => (
    <Button
      className="p-button-outlined"
      icon="pi pi-trash"
      onClick={() =>
        setInspectionPlan({
          ...inspectionPlan,
          tasks: inspectionPlan.tasks.filter((row) => row !== rowData),
        })
      }
    />
  );

  const onEditorValueChange = (e) => {
    e.rowData[e.field] = e.newValue;
  };

  const typeEditor = (options) => {
    // TO DO TEST OPTIONS
    console.log(options);
    return (
      <Dropdown
        value={options && options.value}
        options={types || []}
        onChange={(e) => {
          if (options) {
            const updatedPlan = inspectionPlan.tasks;
            updatedPlan[options.rowIndex][options.field] = e.target.value;
            setInspectionPlan({
              ...inspectionPlan,
              tasks: updatedPlan,
            });
          } else {
            console.log('options does not exist');
          }
        }}
        placeholder={options?.value || 'Select a type'}
      />
    );
  };

  const textEditor = (options) => (
    <InputText
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />
  );

  const onRowReorder = (event) => {
    setInspectionPlan({ ...inspectionPlan, tasks: event.value });
  };

  const columns = [
    { rowReorder: true, style: { width: '3em' }, field: 'reorder' },
    { header: 'Prompt', field: 'name', editor: textEditor },
    { header: 'Type', field: 'type', editor: typeEditor },
    { header: 'Unit', field: 'unit', editor: textEditor },
    { header: 'Notes', field: 'note', editor: textEditor },
    { body: deleteTaskTemplate, field: 'delete' },
  ];

  return (
    <div>
      <Dialog
        visible={visible}
        modal
        onHide={onClose}
        closable={false}
        style={{ width: '80vw' }}
      >
        <div className="formgrid grid p-fluid align-items-end">
          <div className="field col-12">
            <h2>Create Inspection Plan</h2>
          </div>
          <div className="field col-6">
            <label htmlFor="name">Inspection plan name:</label>
            <InputText
              id="name"
              value={inspectionPlan?.name}
              onChange={(e) =>
                setInspectionPlan({ ...inspectionPlan, name: e.target.value })
              }
            />
          </div>
          <div className="field col-6">
            <label htmlFor="description">Inspection plan description:</label>
            <InputText
              id="description"
              value={inspectionPlan?.description}
              onChange={(e) =>
                setInspectionPlan({
                  ...inspectionPlan,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className="field col-12">
            <DataTable
              value={inspectionPlan?.tasks}
              editMode="cell"
              reorderableRows
              onRowReorder={onRowReorder}
            >
              {columns.map(
                ({ header, field, body, editor, rowReorder, style }, prop) => (
                  <Column
                    // eslint-disable-next-line react/no-array-index-key
                    key={prop}
                    header={header}
                    field={field}
                    body={body}
                    editor={editor}
                    onCellEditComplete={onEditorValueChange}
                    rowReorder={rowReorder}
                    style={style}
                  />
                )
              )}
            </DataTable>
          </div>
          <div className="field col-12" style={{ display: 'flex' }}>
            <Button
              className="p-button-rounded p-button-success p-button-outlined"
              label="Add new inspection task"
              onClick={() =>
                setInspectionPlan({
                  ...inspectionPlan,
                  tasks: inspectionPlan.tasks?.length
                    ? [
                        ...inspectionPlan.tasks,
                        {
                          key:
                            inspectionPlan.tasks.reduce(
                              (maxObject, currentObject) => {
                                return currentObject.key > maxObject.key
                                  ? currentObject
                                  : maxObject;
                              }
                            ).key + 1,
                          name: null,
                          type: 'Bool',
                          unit: null,
                        },
                      ]
                    : [
                        {
                          key:
                            (inspectionPlan.tasks?.length
                              ? inspectionPlan.tasks.reduce(
                                  (maxObject, currentObject) => {
                                    return currentObject.key > maxObject.key
                                      ? currentObject
                                      : maxObject;
                                  }
                                ).key
                              : 0) + 1,
                          name: null,
                          type: 'Bool',
                          unit: null,
                        },
                      ],
                })
              }
              style={{
                flex: 1,
              }}
            />
          </div>
          <div className="field col-6" style={{ display: 'flex' }}>
            <Button
              className="p-button-rounded p-button-secondary p-button-outlined"
              label="Cancel"
              onClick={() => onClose(true)}
              style={{
                flex: 1,
              }}
            />
          </div>
          <div className="field col-6" style={{ display: 'flex' }}>
            <Button
              className="p-button-rounded p-button-success"
              label="Create Inspection Plan"
              onClick={() => createInspectionPlan()}
              style={{
                flex: 1,
              }}
              disabled={
                !inspectionPlan.name ||
                !inspectionPlan.tasks?.length ||
                (Array.isArray(inspectionPlan.tasks) &&
                  inspectionPlan.tasks.some(
                    (task) =>
                      !task.type ||
                      (task.type === 'Bool' ? false : !task.unit) ||
                      !task.name
                  ))
              }
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
