/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useContext } from 'react';
import { Button } from 'primereact/button';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';

function CustomerDelete({ data, display, onClose, onCompletion }) {
  const [item] = useState(data);

  const toast = useContext(ToastContext);

  const submitCustomer = async (customer) => {
    try {
      await axiosConfig.post('/customer/delete', {
        customerId: customer.customerId,
      });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: `Successful deletion of customer!`,
      });
      onClose();
      onCompletion();
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h4 className="flex align-items-center">
          Confirm deletion of customer {data.customerName} (ID:{' '}
          {data.customerId})
        </h4>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            label="Delete Customer"
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => submitCustomer({ ...item, status: 'hidden' })}
            className="p-button-success  p-button-rounded"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default CustomerDelete;
