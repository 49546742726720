import { useState, useContext, useCallback } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { PropTypes } from 'prop-types';
import QrReader from 'react-qr-reader';

import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { InputMask } from 'primereact/inputmask';

export async function checkEgg(
  eggId,
  eggShouldExist = true,
  eggShouldBeActive = false
) {
  const egg = await axiosConfig
    .get(`/eggLogistics/check`, {
      params: { eggId },
    })
    .then((res) => res.data);
  // For adding new items to a list with unique eggIds
  switch (true) {
    case !egg && eggShouldExist:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Egg does not exist!',
      };

    case egg && !eggShouldExist:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Egg already exists!',
      };

    case (egg.Status === 'active' || egg.Status === 'new') &&
      !eggShouldBeActive:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Egg is already active!',
      };

    case egg.Status !== 'active' && egg.Status !== 'new' && eggShouldBeActive:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Egg is not active!',
      };

    default:
      return false;
  }
}

// handle external scans
export async function handleExternalEggScan(
  scanResult,
  mask,
  ID,
  toast,
  onResult,
  onClose = null
) {
  // Check if box is already in list
  if (
    scanResult === null &&
    scanResult.replace(/_/g, '').length === mask.length
  ) {
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'No QR Code found!',
    });
  }

  if (
    scanResult !== null &&
    scanResult.replace(/_/g, '').length === mask.length
  ) {
    const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

    if (resultItemPrefix !== ID || !resultItemIdValue) {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'QR Code not valid!',
      });
      return false;
    }

    // // Check if eggs exist
    const toastMsg = await checkEgg(scanResult, true, true);
    // push toast message if there are any
    if (toastMsg) {
      toast.pushToast(toastMsg);
      return false;
    }
    onResult(scanResult);
    onClose && onClose();
    return true;
  }

  return false;
}

export async function handleFocus(event) {
  event.target.select();
}

function EggQRScan({
  onResult,
  onClose,
  display,
  eggShouldExist = true,
  eggShouldBeActive = false,
  itemList = [],
}) {
  const toast = useContext(ToastContext);

  // STATES
  // const [showItemQR, setShowItemQR] = useState(''); // determines which QR window should be shown. Empty string if no QR modal
  const [triggerPressed, setTriggerPressed] = useState(false);

  const callbackRef = useCallback((inputElement) => {
    if (inputElement && !inputElement.props) {
      inputElement.focus();
      inputElement.select();
    }
  }, []);

  const MASK = 'aa-99999';

  const closeModal = () => {
    onClose();
  };

  const parseResult = (scanResult) => {
    // format and check scanResult e.g. BX-0012, return
    const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

    const scannableItemsPrefixes = ['BX', 'EG', 'FD', 'PS'];

    const resultItemPrefixFound = scannableItemsPrefixes.find(
      (prefix) => prefix === resultItemPrefix
    );
    // const resultItemValue = resultItem ? parseInt(resultItemIdValue, 10) : null;
    const resultItemValue = resultItemPrefixFound ? resultItemIdValue : null;

    return [resultItemPrefixFound, resultItemValue];
  };

  // add error handling if something goes wrong with QR scanner (while scanning)
  const handleScanError = (error) => {
    console.error(error);
  };

  // This function has to be modified once DB is clearly set up
  const handleScan = async (scanResult) => {
    if (scanResult === null && triggerPressed) {
      setTriggerPressed(false);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No QR Code found!',
      });
    }
    if (scanResult !== null && triggerPressed) {
      setTriggerPressed(false);

      const [parseResultItemPrefix, parseResultItemValue] =
        parseResult(scanResult);

      if (!parseResultItemPrefix || !parseResultItemValue) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code does not belong to scannable Item!',
        });

        return false;
      }

      if (parseResultItemPrefix === 'EG') {
        const toastMsg = await checkEgg(
          scanResult,
          eggShouldExist,
          eggShouldBeActive,
          itemList
        );
        if (toastMsg) {
          toast.pushToast(toastMsg);
          return false;
        }
        onClose();
        onResult(scanResult);
      } else {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: `Wrong QR code type! Got ${parseResultItemPrefix} but expected EG`,
        });
      }
    }
    return true;
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '30vw' }}
      modal
      visible={display}
      onHide={closeModal}
    >
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-end mb-2">
          <Button
            onClick={() => onClose()}
            icon="pi pi-times"
            className="p-button-rounded p-button-warning p-button-outlined "
          />
        </div>
        <div className="col-10 ">
          <QrReader
            delay={300}
            // style={{ width: '100%' }}
            onError={handleScanError}
            onScan={handleScan}
          />
          <InputMask
            id="eggId"
            name="eggIdInputMask"
            ref={callbackRef}
            onFocus={handleFocus}
            mask={MASK}
            placeholder="Scan Egg Barcode or QR Code"
            onChange={(e) =>
              handleExternalEggScan(
                e.value,
                MASK,
                'EG',
                toast,
                onResult,
                onClose
              )
            }
          />
        </div>

        <div className=" col-12 flex justify-content-end mt-2">
          <Button
            onClick={() => setTriggerPressed(true)}
            icon="pi pi-camera"
            className="p-button-rounded p-button-outlined"
          />
        </div>
      </div>
    </Dialog>
  );
}

EggQRScan.propTypes = {
  eggShouldExist: PropTypes.bool.isRequired,
  eggShouldBeActive: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onResult: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
};

export default EggQRScan;
