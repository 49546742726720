/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useContext } from 'react';
import { Button } from 'primereact/button';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';

function DeleteRecipeConfirmation({ data, display, onClose, onCompletion }) {
  const toast = useContext(ToastContext);
  const [recipe] = useState(data);

  const sendRecipe = async (action) => {
    try {
      const recipeIngredients = [];
      // eslint-disable-next-line array-callback-return
      recipe.ingredients.map((ingredient) => {
        recipeIngredients.push({
          ratio: ingredient.ratio / 100,
          ingredientId: ingredient.ingredient.ingredientId,
        });
      });
      await axiosConfig.post('/foodLogistics/createRecipe', {
        recipeId: parseInt(recipe.recipeId.substring(3), 10),
        recipeName: recipe.recipeName,
        status: 'hidden',
        notes: recipe.notes,
        recipeIngredients,
      });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successfully unlisted!',
      });
      onClose();
      onCompletion();
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '60vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h3 className="flex align-items-center">
          Confirm Deletion of Recipe: {recipe.recipeName} (ID: {recipe.recipeId}
          )
        </h3>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <div className="field col-6">
          <Button
            onClick={() => sendRecipe('delete')}
            label="Delete recipe"
            className="p-button-success  p-button-rounded"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteRecipeConfirmation;
