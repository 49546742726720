import { ToastContext } from 'utils/toastContextWrapper';
import { InputText } from 'primereact/inputtext';
import { useCallback, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import handleExternalInput from 'utils/handleExternalBoxScan';

export default function QRCodeTextField({
  MASK,
  resultFunctionDict,
  boxIdNotList,
  boxes,
  setElement,
  boxesShouldExist,
  boxesShouldBeActive,
}) {
  const [QRCodeText, setQRCodeText] = useState(null);
  const [elementTemp, setElementTemp] = useState(null);
  const toast = useContext(ToastContext);
  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && !inputElement.props) {
        setElement(inputElement);
        setElementTemp(inputElement);
      }
    },
    [setElement]
  );
  return (
    <InputText
      style={{ opacity: 0 }}
      value={QRCodeText}
      ref={callbackRef}
      placeholder="Search"
      onKeyDown={(e) =>
        (e.key === 'Enter' || e.key === 'tab') &&
        handleExternalInput(
          QRCodeText,
          elementTemp,
          MASK,
          toast,
          resultFunctionDict,
          setQRCodeText(''),
          [],
          [
            ...(boxIdNotList || []),
            ...(boxes?.length ? boxes.map(({ boxId }) => boxId) : []),
          ],
          boxesShouldExist,
          boxesShouldBeActive
        )
      }
      onChange={(e) => setQRCodeText(e.target.value)}
    />
  );
}

QRCodeTextField.propTypes = {
  MASK: PropTypes.string.isRequired,
  setElement: PropTypes.func.isRequired,
  resultFunctionDict: PropTypes.shape({
    BX: PropTypes.func.isRequired,
    EG: PropTypes.func.isRequired,
    FD: PropTypes.func.isRequired,
  }).isRequired,
  boxIdNotList: PropTypes.arrayOf(PropTypes.string).isRequired,
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      boxId: PropTypes.string.isRequired,
      Status: PropTypes.string,
    })
  ).isRequired,
  boxesShouldExist: PropTypes.bool.isRequired,
  boxesShouldBeActive: PropTypes.bool.isRequired,
};
