/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import classNames from 'classnames';

import Stack from 'pages/FarmSetup/Stack/Stack';

function Area({ area, productionSiteLayout }) {
  const {
    AreaID: id,
    AreaLength: length,
    AreaWidth: width,
    AreaLayoutRows: layoutRows,
    AreaLayoutColumns: layoutColumns,
    ProductionPositions: productionPositions,
  } = area;

  const areaAspectRatio = length / width;

  const areaClass = classNames('farm-area', `aspect-ratio-${areaAspectRatio}`);

  const stackStatus = (spot) => {
    const stack = productionPositions.find((pos) => pos.SpotID === spot);

    if (stack) {
      return stack.Status;
    }
    return 'unused';
  };

  return (
    <div className={areaClass}>
      <div
        className="stackLayoutGrid"
        style={{
          gridTemplateColumns: `repeat(${layoutColumns}, 1fr)`,
          gridTemplateRows: `repeat(${layoutRows}, 1fr)`,
        }}
      >
        {Array(layoutRows)
          .fill(0)
          .map((_e, rowIndex) => {
            return (
              <>
                {Array(layoutColumns)
                  .fill(0)
                  .map((_, columnIndex) => {
                    const spot = columnIndex + 1 + rowIndex * layoutColumns;
                    return (
                      <Stack
                        areaColumns={productionSiteLayout.areaColumns}
                        spot={spot}
                        rowId={rowIndex}
                        columnId={columnIndex}
                        areaId={id}
                        status={stackStatus(spot)}
                        key={`${id}-${spot}`}
                      />
                    );
                  })}
              </>
            );
          })}
      </div>
    </div>
  );
}

export default Area;
