/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import Area from 'pages/FarmSetup/Area/Area';

// import { useSelectedStackPosition } from 'pages/Stabling/SelectStackPositionContext';

function AreaGrid({ farmSetup }) {
  const {
    productionSite: { AreaColumns: areaColumns, AreaRows: areaRows },
    areas,
  } = farmSetup;

  return (
    <div
      className="productionSiteGrid"
      style={{
        gridTemplateColumns: `repeat(${areaColumns}, 1fr)`,
        gridTemplateRows: `repeat(${areaRows}, 1fr)`,
      }}
    >
      {Array(areaRows)
        .fill(0)
        .map((_e, rowIndex) => {
          return (
            <>
              {Array(areaColumns)
                .fill(0)
                .map((_, columnIndex) => {
                  const area = areas.find((cont) => {
                    return (
                      cont.AreaPositionRow === rowIndex &&
                      cont.AreaPositionColumn === columnIndex
                    );
                  });

                  if (area) {
                    return (
                      <Area
                        area={area}
                        productionSiteLayout={{
                          areaRows,
                          areaColumns,
                        }}
                        key={area.AreaID}
                      />
                    );
                  }

                  return (
                    <div
                      style={{
                        gridColumn: `${columnIndex + 1} / span 1`,
                        gridRow: `${rowIndex + 1} / span 1`,
                      }}
                      key={`${rowIndex}-${columnIndex}`}
                    />
                  );
                })}
            </>
          );
        })}
    </div>
  );
}

export default AreaGrid;
