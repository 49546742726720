/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { FaUserTag } from 'react-icons/fa';
import { Dropdown } from 'primereact/dropdown';
import SupplierEdit from './SupplierEdit';
import SupplierDelete from './SupplierDelete';

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('.');
}

function Supplier() {
  const toast = useContext(ToastContext);
  const [supplierList, setSupplierList] = useState([]);
  const [supplierListRaw, setSupplierListRaw] = useState([]);
  const [action, setAction] = useState();
  const [refreshSupplierList, setRefreshSupplierList] = useState(false);

  const columns = [
    { header: 'Supplier Name', field: 'supplierName' },
    { header: 'Supplier ID', field: 'supplierId' },
    {
      header: 'Registered',
      field: 'createdAt',
      body: (rowData) => formatDate(new Date(rowData.createdAt)),
    },
    { header: 'Address', field: 'address' },
    { header: 'Category', field: 'category' },
  ];

  useEffect(() => {
    fetchSupplierList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSupplierList();
    setRefreshSupplierList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSupplierList]);

  const fetchSupplierList = async () => {
    try {
      const suppliersTemp = await axiosConfig
        .get('/supplier/suppliers')
        .then((res) => res.data);
      if (suppliersTemp) {
        setSupplierListRaw(suppliersTemp);
        setSupplierList(
          suppliersTemp.filter((supplier) => supplier.status === 'active')
        );
      }
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Food List',
      });
    }
  };

  const rowAction = (rowData) => (
    <Dropdown
      options={[
        { label: 'Details/Edit', value: 'edit' },
        { label: 'Delete', value: 'delete' },
      ]}
      onChange={(e) => {
        setAction({ actionValue: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  const actionDialog = () => {
    if (action === null || action === undefined) return null;
    switch (action.actionValue) {
      case 'delete':
        return (
          <SupplierDelete
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshSupplierList(true)}
          />
        );
      case 'edit':
        return (
          <SupplierEdit
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshSupplierList(true)}
            List={supplierListRaw}
          />
        );
      case 'create':
        return (
          <SupplierEdit
            data={null}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshSupplierList(true)}
            List={supplierListRaw}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-card main-card-content">
      {action !== null && actionDialog()}
      <div className="formGrid grid">
        <div className="field col-12">
          <h2 className="flex align-items-center">
            <FaUserTag className="mr-3" /> Suppliers
          </h2>
        </div>
        <div className="field col-12">
          <DataTable
            emptyMessage="No suppliers found"
            value={supplierList}
            responsiveLayout="scroll"
            dataKey="supplierId"
          >
            {columns.map(({ header, field, body }) => (
              <Column header={header} field={field} body={body} />
            ))}
            <Column header="Action" body={rowAction} />
          </DataTable>
        </div>
      </div>
      <div className="p-fluid formGrid grid">
        <div className="field col-12 mt-2 mb-5">
          <Button
            className="p-button-outlined p-button-success p-button-rounded"
            label="Create new supplier"
            onClick={() => {
              setAction({ actionValue: 'create', actionData: null });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Supplier;
