/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';
import { COUNTRYLIST } from 'utils/Constants';

const addPrefix = (id, prefix, numLeadingZeroes) =>
  `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;

function SupplierEdit({ data, display, onClose, onCompletion, List }) {
  const initItem = {
    supplierName: '',
    supplierId: addPrefix(
      List
        ? 1 +
            List.reduce(
              (biggest, supplier) =>
                Math.max(
                  parseInt(supplier.supplierId.substring(3), 10),
                  biggest
                ),
              0
            )
        : 1,
      'SP',
      5
    ),
    category: '',
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    streetName: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    country: '',
    notes: '',
    createdAt: new Date(),
    status: 'active',
  };

  const [inComplete, setInComplete] = useState(true);

  const [item, setItem] = useState(data || initItem);

  const toast = useContext(ToastContext);

  useEffect(() => {
    setInComplete(false);
    if (!item.supplierName || !item.category) setInComplete(true);
  }, [item]);

  const submitSupplier = async (supplier) => {
    try {
      await axiosConfig.post('/supplier/supplier', {
        ...supplier,
        supplierId: parseInt(supplier.supplierId.substring(3), 10),
      });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: `Successful ${data ? 'editing' : 'creating'} of supplier!`,
      });
      onClose();
      onCompletion();
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          {data ? 'Edit' : 'Create'} Supplier
        </h2>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <label htmlFor="supplierName">Supplier Name*</label>
          <InputText
            value={item.supplierName}
            onChange={(e) =>
              setItem({
                ...item,
                supplierName: e.target.value.substring(0, 50),
              })
            }
            id="supplierName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="category">Category*</label>
          <Dropdown
            value={item.category}
            options={[
              { label: 'Food', value: 'Food' },
              { label: 'Egg', value: 'Egg' },
              { label: 'Other', value: 'Other' },
            ]}
            onChange={(e) => setItem({ ...item, category: e.target.value })}
            id="supplierName"
            placeholder="Select Category..."
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="supplierId">Supplier Id</label>
          <InputText
            value={item.supplierId}
            onChange={(e) => setItem({ ...item, supplierId: e.target.value })}
            id="supplierId"
            type="text"
            disabled
          />
        </div>
        <div className="field col-6" />
        <div className="field col-12">
          <hr />
        </div>
        <div className="field col-6">
          <label htmlFor="firstName">First Name</label>
          <InputText
            value={item.firstName}
            onChange={(e) => setItem({ ...item, firstName: e.target.value })}
            id="firstName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="lastName">Last Name</label>
          <InputText
            value={item.lastName}
            onChange={(e) => setItem({ ...item, lastName: e.target.value })}
            id="lastName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="email">E-Mail</label>
          <InputText
            value={item.email}
            onChange={(e) => setItem({ ...item, email: e.target.value })}
            id="email"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="email">Telephone</label>
          <InputText
            value={item.telephone}
            onChange={(e) => setItem({ ...item, telephone: e.target.value })}
            id="telephone"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="streetName">Street Name</label>
          <InputText
            value={item.streetName}
            onChange={(e) => setItem({ ...item, streetName: e.target.value })}
            id="streetName"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="streetNumber">Number</label>
          <InputText
            value={item.streetNumber}
            onChange={(e) => setItem({ ...item, streetNumber: e.target.value })}
            id="streetNumber"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="postalCode">Postal Code</label>
          <InputText
            value={item.postalCode}
            onChange={(e) => setItem({ ...item, postalCode: e.target.value })}
            id="postalCode"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="city">City</label>
          <InputText
            value={item.city}
            onChange={(e) => setItem({ ...item, city: e.target.value })}
            id="city"
            type="text"
          />
        </div>
        <div className="field col-6">
          <label htmlFor="country">Country</label>
          <Dropdown
            value={item.country}
            options={COUNTRYLIST}
            onChange={(e) => setItem({ ...item, country: e.target.value })}
            id="country"
            optionLabel="label"
            filter
            showClear
            filterBy="label"
            placeholder="Select a Country"
          />
        </div>
        <div className="field col-12">
          <label htmlFor="notes">Notes</label>
          <InputTextarea
            rows={5}
            value={item.notes}
            onChange={(e) =>
              setItem({
                ...item,
                notes: e.target.value.substring(0, 500),
              })
            }
            autoResize
            id="notes"
            type="text"
          />
        </div>
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            label={`${data ? 'Save' : 'Create supplier'}`}
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => submitSupplier(item)}
            className="p-button-success  p-button-rounded"
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default SupplierEdit;
