/* eslint-disable react/prop-types */
/*
Unterseite "Stabling", hier findet das Tracking bei der Einstallung statt
Diese Seite wird gerenderd
*/

import axiosConfig from 'utils/axiosConfig';
import { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';

import AreaGrid from 'pages/FarmSetup/Area/AreaGrid';
import { SelectedStackPositionProvider } from 'pages/Stabling/SelectStackPositionContext';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Button } from 'primereact/button';
import PositionDialog from 'elements/PositionDialog';
import PackagingEdit from 'pages/Packaging/PackagingEdit';
import { Dropdown } from 'primereact/dropdown';
import { formatDate } from 'pages/Supplier/Supplier';

function Overview({ setTask, setTaskBatches }) {
  const toast = useContext(ToastContext);
  // STATES workStepBatches
  const [workItemList, setWorkItemList] = useState(null);
  const [farmSetup, setFarmSetup] = useState({});
  const [showDialog, setShowDialog] = useState('');
  const [selectedStacks, setSelectedStacks] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [workStepBatches, setWorkStepBatches] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedRowsWorkItems, setExpandedRowsWorkItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingWorkSteps, setLoadingWorkSteps] = useState(true);
  const [loadingActiveBatches, setLoadingActiveBatches] = useState(true);
  const [activeBatches, setActiveBatches] = useState([]);
  const [workStepsDaysAhead, setWorkStepsDaysAhead] = useState(0);

  const [activeIndex, setActiveIndex] = useState(0);
  const [numberOfTodaysWorkItems, setNumberOfTodaysWorkItems] = useState({
    'All Tasks': 0,
    Inspection: 0,
    'Dry Feeding': 0,
    Sieving: 0,
    Positioning: 0,
    Packaging: 0,
  });
  const navigate = useNavigate();

  const dateTemplate = (rowData) =>
    rowData?.dueDate ? formatDate(new Date(rowData.dueDate)) : null;

  const columns = [
    { header: 'Type', field: 'type' },
    { header: 'User', field: 'userName' },
    { header: 'Day', field: 'day' },
    { header: 'Time', field: 'time' },
    { header: 'Comment', field: 'comment' },
  ];

  const columnsExpandedStabling = [
    { header: 'Box ID', field: 'boxId' },
    { header: 'Charge ID', field: 'chargeId' },
    { header: 'Stack ID', field: 'stackId' },
    { header: 'Position in Stack', field: 'positionInStack' },
    { header: 'Area ID', field: 'areaId' },
    { header: 'Spot ID', field: 'spotId' },
  ];

  const columnsExpandedFeeding = [
    { header: 'Box ID ', field: 'boxId' },
    { header: 'Charge ID', field: 'chargeId' },
    { header: 'Food ID', field: 'foodId' },
    { header: 'Recipe ID', field: 'recipeId' },
    { header: 'Food Name', field: 'foodName' },
    { header: 'Food Weight', field: 'foodWeight' },
  ];

  const columnsExpandedReboxing = [
    { header: 'Box ID ', field: 'boxId' },
    { header: 'Charge ID', field: 'chargeId' },
    { header: 'Status', field: 'status' },
    { header: 'Comment', field: 'comment' },
  ];

  const columnsExpandedSievingInput = [
    { header: 'Box ID ', field: 'boxId' },
    { header: 'Charge ID', field: 'chargeId' },
    { header: 'Comment', field: 'comment' },
  ];

  const columnsExpandedSievingResult = [
    { header: 'Beetles Weight ', field: 'BeetlesWeight' },
    { header: 'Excrement Weight', field: 'ExcrementWeight' },
    { header: 'Insect Weight', field: 'InsectWeight' },
    { header: 'PuppetsWeight', field: 'PuppetsWeight' },
    { header: 'Skin Weight', field: 'SkinWeight' },
    { header: 'Small Insect Weight', field: 'SmallInsectWeight' },
    { header: 'Residual Food Weight', field: 'ResidualFood' },
    { header: 'Comment', field: 'comment' },
  ];

  const columnsExpandedSievingOutput = [
    { header: 'Box ID ', field: 'boxId' },
    { header: 'Charge ID', field: 'chargeId' },
    { header: 'Input Weight', field: 'InputWeight' },
    { header: 'Comment', field: 'comment' },
  ];

  const columnsExpandedPositioning = [
    { header: 'Stack ID ', field: 'stackId' },
    { header: 'Production Position ID', field: 'positionId' },
    { header: 'Comment', field: 'comment' },
  ];

  const workItemsTabs = [
    {
      tabBody: 'All Tasks',
      tabField: 'All Tasks',
    },
    {
      tabBody: 'Inspection',
      tabField: 'Inspection',
    },
    {
      tabBody: 'Feeding',
      tabField: 'Dry Feeding',
    },
    {
      tabBody: 'Sieving',
      tabField: 'Sieving',
    },
    {
      tabBody: 'Positioning',
      tabField: 'Positioning',
    },
    {
      tabBody: 'Packaging',
      tabField: 'Packaging',
    },
  ];

  const activeBatchesColumn = [
    { header: 'Batch ID', field: 'batchId' },
    { header: 'Number of Boxes', field: 'numberOfBoxes' },
    { header: 'Day Since Stabling', field: 'daySinceStabling' },
  ];

  const workStepsColumns = [
    { header: 'Batch ID', field: 'batchId' },
    { header: 'Work Plan ID', field: 'workPlanId' },
    { header: 'Due Date ', field: 'dueDate', body: dateTemplate },
  ];

  const daysAheadOptions = [
    { option: 'Today Work Tasks', value: 0 },
    { option: '5 Days Ahead', value: 5 },
    { option: '7 Days Ahead', value: 7 },
  ];

  const getWorkSteps = async (daysAhead) => {
    try {
      setLoadingWorkSteps(true);
      await axiosConfig
        .get('/overview/workSteps', { params: { daysAhead: daysAhead || 0 } })
        .then(({ data }) => {
          setWorkStepBatches(data);
          data.length &&
            setNumberOfTodaysWorkItems({
              'All Tasks': data.length,
              ...data.reduce((acc, cur) => {
                acc[cur.workTaskName] = acc[cur.workTaskName]
                  ? acc[cur.workTaskName] + 1
                  : 1;
                return acc;
              }, {}),
            });
          setLoadingWorkSteps(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveBatches = async () => {
    try {
      setLoadingActiveBatches(true);
      const batches = await axiosConfig
        .get('/overview/activeBatches')
        .then(({ data }) => data);
      setActiveBatches(batches);
      setLoadingActiveBatches(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTableData = useCallback(async () => {
    try {
      const workItems = await axiosConfig
        .get('/overview/workitems')
        .then((res) => res.data);
      const farmSetupDb = await axiosConfig
        .get('/farmsetup')
        .then((res) => res.data);
      setWorkItemList(workItems);
      setFarmSetup(farmSetupDb);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Data',
      });
    }
  }, [setWorkItemList, setFarmSetup, setLoading, toast]);

  useEffect(() => {
    setTask(null);
    getWorkSteps(0);
    fetchTableData();
    getActiveBatches();
  }, [fetchTableData, setTask, setTaskBatches]);

  const navigateTo = (rowData) => {
    const attribute = rowData.batchId
      ? rowData.attributes
      : rowData.charges[0].attributes;
    switch (rowData?.workTaskName) {
      case 'Inspection':
        setTask(attribute);
        setTaskBatches([rowData?.batchId]);
        navigate('/inspection');
        break;
      case 'Dry Feeding':
        setTask(attribute);
        setTaskBatches([rowData?.batchId]);
        navigate('/feeding');
        break;
      case 'Sieving':
        setTask(attribute);
        setTaskBatches([rowData?.batchId]);
        navigate('/sieving');
        break;
      case 'Positioning':
        setSelectedStacks(rowData?.stacks);
        setShowDialog('positionDialog');
        break;
      case 'Packaging':
        setSelectedBatch(rowData?.batchId);
        setShowDialog('packagingDialog');
        break;
      default:
        navigate('/overview');
    }
  };

  const navigateButtonTemplate = (rowData) => {
    return (
      <Button
        className="p-button-secondary p-button-outlined flex"
        label={`${rowData?.workTaskName} →`}
        onClick={() => navigateTo(rowData)}
      />
    );
  };

  const stackTableTemplate = (rowData) =>
    `Number of Boxes: ${rowData?.boxesFromBatch};  ${
      rowData?.attributes
        ? `${rowData.attributes
            .map((attribute) => `${attribute.name}: ${attribute.value}`)
            .join(';  ')};  `
        : ''
    }Stacks: ${(rowData?.stacks?.length ? rowData.stacks : []).join(', ')}`;

  const workItemOverview = (tab, tabBody) => {
    if (!workStepBatches) return null;
    return (
      <>
        <DataTable
          value={workStepBatches.filter(
            ({ workTaskName }) => tab === 'All Tasks' || workTaskName === tab
          )}
          expandedRows={expandedRowsWorkItems}
          onRowToggle={(e) => setExpandedRowsWorkItems(e.data)}
          rowExpansionTemplate={stackTableTemplate}
          responsiveLayout="scroll"
          sortOrder={-1}
          emptyMessage={`No work items for work item: ${tabBody}`}
          paginator
          rows={5}
          loading={loadingWorkSteps}
        >
          <Column expander />
          {workStepsColumns.map(({ header, field, body }) => (
            <Column header={header} field={field} body={body} sortable filter />
          ))}
          {tab === 'All Tasks' ? (
            <Column
              header="Work Task Name"
              field="workTaskName"
              body={navigateButtonTemplate}
            />
          ) : (
            <Column
              header=""
              field="workTaskName"
              body={navigateButtonTemplate}
            />
          )}
        </DataTable>
        {tab === 'Dry Feeding'
          ? `Total Feeding Weight: ${workStepBatches
              .filter(
                ({ workTaskName }) =>
                  tab === 'All Tasks' || workTaskName === tab
              )
              .reduce(
                (acc, cur) =>
                  acc +
                  (cur.attributes?.find(
                    (attribute) => attribute.name === 'Food Weight'
                  ).value || 0) *
                    (cur.boxesFromBatch || 0),
                0
              )} kg`
          : null}
      </>
    );
  };

  const rowExpansionTemplate = (data) => {
    // if there is no data, return null
    if (!data.workItems) {
      return null;
    }
    // Switch statement to determine which type of workitem is being expanded
    let columnsExpanded = [];
    let tableData = [];
    switch (data.type) {
      case 'stabling':
        columnsExpanded = columnsExpandedStabling;
        tableData = data.workItems.workItemStablings;
        break;
      case 'feeding':
        columnsExpanded = columnsExpandedFeeding;
        tableData = data.workItems.workItemFeedings.map((workItem) => ({
          boxId: workItem.ChargeHistory.BoxID,
          chargeId: workItem.ChargeHistory.ChargeID,
          foodId: workItem.FoodHistory.FoodID,
          recipeId: workItem.FoodHistory.Food.RecipeID,
          foodName: workItem.FoodHistory.Food.FoodName,
          foodWeight: workItem.FoodWeight,
          remainingAmount: workItem.FoodHistory.RemainingAmount,
          comment: workItem.Comment,
        }));
        break;
      case 'reboxing':
        columnsExpanded = columnsExpandedReboxing;
        tableData = data.workItems.workItemReboxings.map((workItem) => ({
          boxId: workItem.ChargeHistory.BoxID,
          chargeId: workItem.ChargeHistory.ChargeID,
          status: workItem.ChargeHistory.Status,
          comment: workItem.comment,
        }));
        break;
      case 'sievinginput':
        columnsExpanded = columnsExpandedSievingInput;
        tableData = data.workItems.workItemSievingInputs.map((workItem) => ({
          boxId: workItem.ChargeHistory.BoxID,
          chargeId: workItem.ChargeHistory.ChargeID,
          comment: workItem.comment,
        }));
        break;
      case 'sievingoutput':
        columnsExpanded = columnsExpandedSievingOutput;
        tableData = data.workItems.workItemSievingOutputs.map((workItem) => ({
          boxId: workItem.ChargeHistory.BoxID,
          chargeId: workItem.ChargeHistory.ChargeID,
          InputWeight: workItem.InputWeight,
          comment: workItem.Comment,
        }));
        break;
      case 'sievingresult':
        columnsExpanded = columnsExpandedSievingResult;
        tableData = data.workItems.workItemSievingResults;
        break;
      case 'positioning':
        columnsExpanded = columnsExpandedPositioning;
        tableData = data.workItems.workItemsPositioning.map((workItem) => ({
          comment: workItem.Comment,
          stackId: workItem.StackHistory.StackID,
          positionId: workItem.StackHistory.ProductionPositionID,
        }));
        break;
      default:
        break;
    }

    return (
      <div className="orders-subtable">
        <h5>Work Items</h5>
        <DataTable value={tableData} responsiveLayout="scroll">
          {columnsExpanded.map((column) => {
            const { header, field } = column;
            return (
              <Column key={header} field={field} header={header} sortable />
            );
          })}
        </DataTable>
      </div>
    );
  };

  return (
    <div className="main-card main-card-content">
      {showDialog === 'positionDialog' && (
        <PositionDialog
          visible={showDialog === 'positionDialog'}
          onHide={() => {
            setShowDialog('');
            setSelectedStacks([]);
          }}
          stacks={selectedStacks}
          onComplete={() => {
            setSelectedStacks([]);
          }}
        />
      )}
      {showDialog === 'packagingDialog' && selectedBatch && (
        <PackagingEdit
          data={{ batchId: selectedBatch }}
          getBoxesFromBatch
          readOnly={false}
          display={showDialog === 'packagingDialog'}
          onClose={() => {
            setSelectedBatch([]);
            setShowDialog('');
          }}
          onCompletion={() => {
            setSelectedBatch([]);
            setShowDialog('');
          }}
        />
      )}
      <div className="mt-2 mb-5">
        <h2>Overview</h2>
      </div>

      <div className="grid ">
        <div className="col-12">
          <div className="card-content p-fluid">
            <div className="grid mt-2 mb-5">
              <div className="col-9">
                <h5>Today{`'`}s Work Items Overview</h5>
              </div>
              <div className="col-3">
                <Dropdown
                  value={workStepsDaysAhead}
                  options={daysAheadOptions}
                  optionLabel="option"
                  onChange={(e) => {
                    setWorkStepsDaysAhead(e.value);
                    getWorkSteps(e.value);
                  }}
                />
              </div>
            </div>
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              {workItemsTabs.map(({ tabBody, tabField }) => {
                return (
                  <TabPanel
                    header={`${tabBody} (${
                      numberOfTodaysWorkItems[tabField] || 0
                    })`}
                    headerTemplate={null}
                  >
                    {workItemOverview(tabField, tabBody)}
                  </TabPanel>
                );
              })}
            </TabView>
          </div>
        </div>
        <div className="col-12">
          <div className="card-content p-fluid">
            <div className="mt-2 mb-5">
              <h5>Active Batches</h5>
            </div>
            <DataTable
              value={activeBatches}
              loading={loadingActiveBatches}
              expandedRows={expandedRows}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
              rowExpansionTemplate={(rowData) => rowData?.stacks || null}
              dataKey="batchId"
              paginator
              rows={10}
            >
              <Column expander style={{ width: '3em' }} />
              {activeBatchesColumn.map((column) => {
                const { header, field } = column;
                return (
                  <Column key={header} field={field} header={header} sortable />
                );
              })}
            </DataTable>
          </div>
        </div>
        <div className="col-12">
          <div className="card-content p-fluid">
            <div className="mt-2 mb-5">
              <h5>Work Task Overview</h5>
            </div>

            <DataTable
              value={workItemList}
              expandedRows={expandedRows}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
              responsiveLayout="scroll"
              loading={loading}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="workItemId"
              paginator
              rows={10}
            >
              <Column expander style={{ width: '3em' }} />
              {columns.map((column) => {
                const { header, field } = column;
                return (
                  <Column key={header} field={field} header={header} sortable />
                );
              })}
            </DataTable>
          </div>
        </div>

        <div className="col-8">
          <div className="card-content ">
            <div className="grid">
              <div className="col-12 ">
                <div className="grid ">
                  <div className=" col-10 flex align-items-center">
                    <h5>Farm</h5>
                  </div>

                  <div className="col-12">
                    {Object.keys(farmSetup).length ? (
                      <SelectedStackPositionProvider>
                        <AreaGrid farmSetup={farmSetup} />
                      </SelectedStackPositionProvider>
                    ) : (
                      <div>
                        <h6>No Farm Setup found</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card-content ">
            <div className="grid justify-content-center">
              <div className="col-12">
                <h5>Legend</h5>
              </div>
              <div className="col-2">
                <span
                  className="stack-badge stack-badge-active"
                  style={{
                    aspectRatio: '1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  #
                </span>
              </div>
              <div
                className="col-10"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p>Fully occupied position</p>
              </div>
              <div className="col-2">
                <p
                  className="stack-badge stack-badge-occupied"
                  style={{
                    aspectRatio: '1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  #
                </p>
              </div>
              <div
                className="col-10"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p>Partly occupied position</p>
              </div>
              <div className="col-2">
                <p
                  className="stack-badge stack-badge-empty"
                  style={{
                    aspectRatio: '1',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  #
                </p>
              </div>
              <div
                className="col-10"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p>Empty position</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
