/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';
import CreateRecipeConfirmation from './RecipeCreateConfirmation';

const addPrefix = (id, prefix, numLeadingZeroes) =>
  `${prefix}-${id?.toString()?.padStart(numLeadingZeroes, '0')}`;

function CreateRecipe({ data, newRecipeId, display, onClose, onCompletion }) {
  const toast = useContext(ToastContext);

  const initRecipe = {
    recipeName: '',
    recipeId: addPrefix(newRecipeId, 'FD', 5),
    notes: '',
    ingredients: [],
  };

  const [action, setAction] = useState(null);
  const [recipe, setRecipe] = useState(data || initRecipe);
  const [oldRecipe] = useState(
    data
      ? { ...data, ingredients: JSON.parse(JSON.stringify(data.ingredients)) }
      : null
  );
  const [inComplete, setInComplete] = useState(false);
  const [ingredientList, setIngredientList] = useState([]);

  const [inCompleteIngredientList, setInCompleteIngredientList] =
    useState(true);

  const ingredientTemplate = (rowData) => {
    return (
      <span>
        {rowData.ingredient.ingredientName
          ? rowData.ingredient.ingredientName
          : 'Select ingredient...'}
      </span>
    );
  };

  const ratioDecimal = (rowData) => {
    // eslint-disable-next-line no-nested-ternary
    return typeof rowData.ratio === 'number'
      ? rowData.ratio.toFixed(2)
      : typeof rowData.ratio === 'string'
      ? parseFloat(rowData.ratio).toFixed(2)
      : '0.00';
  };

  const columns = [
    {
      key: 'ingredient',
      field: 'ingredientName',
      body: ingredientTemplate,
      header: 'Ingredient',
      width: '40%',
    },
    {
      key: 'ratio',
      field: 'ratio',
      header: 'Proportion [%]',
      width: '40%',
      body: ratioDecimal,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchIngredientsTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchIngredientsTable().then();
    setInComplete(false);
    for (const key of Object.keys(recipe)) {
      if (
        key !== 'notes' &&
        key !== 'key' &&
        key !== 'numberOfIngredients' &&
        (recipe[key] === null ||
          recipe[key] === undefined ||
          recipe[key] === '' ||
          recipe[key] === 0)
      ) {
        setInComplete(true);
      }
    }
    setInCompleteIngredientList(false);
    recipe.ingredients
      ? recipe.ingredients.map((ingredient) =>
          ingredient.ingredient.ingredientId
            ? null
            : setInCompleteIngredientList(true)
        )
      : setInCompleteIngredientList(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipe]);

  const fetchIngredientsTable = async () => {
    try {
      const ingredientTableJSON = await axiosConfig
        .get('/foodLogistics/ingredients')
        .then((res) => res.data);
      const ingredientTableTemp = [];
      ingredientTableJSON.map((ingredient) => {
        ingredientTableTemp.push({
          label: ingredient.Name,
          value: {
            ingredientId: ingredient.IngredientID,
            ingredientName: ingredient.Name,
          },
        });
        return null;
      });
      setIngredientList(ingredientTableTemp);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching ingredient List',
      });
    }
  };

  const IDList = (newRecipe) => {
    return newRecipe
      ? newRecipe.ingredients.map(
          (ingredient) => ingredient.ingredient.ingredientId
        )
      : [];
  };

  const handleFocus = (event) => event.target.select();

  const getRowIndex = (_, prop) => {
    return <span>{prop.rowIndex + 1}</span>;
  };

  const ingredientEditor = (options) => {
    return (
      <Dropdown
        value={options.rowData.ingredient}
        options={ingredientList.filter(
          (ingredient) =>
            !IDList(recipe).includes(ingredient.value.ingredientId)
        )}
        onChange={(e) => {
          const ingredientsTemp = [...recipe.ingredients];
          ingredientsTemp[
            ingredientsTemp.findIndex(
              (ingredient) => ingredient.key === options.rowData.key
            )
          ].ingredient = e.value;
          setRecipe({ ...recipe, ingredients: ingredientsTemp });
          options.editorCallback(e.value);
        }}
        // placeholder="drop down"
        placeholder={
          options.rowData.ingredient.ingredientName
            ? options.rowData.ingredient.ingredientName
            : 'Select Ingredient ...'
        }
        disabled={data}
      />
    );
  };

  const ratioEditor = (options) => {
    return (
      <InputNumber
        value={
          options.rowData[options.field]
            ? options.rowData[options.field].toFixed(2)
            : '0.00'
        }
        max={100}
        mode="decimal"
        locale="de-DE"
        minFractionDigits={1}
        maxFractionDigits={3}
        onChange={(e) => {
          let resultEvent = e.value;
          if (resultEvent > 100) {
            resultEvent = 100;
          }
          const ingredientsTemp = [...recipe.ingredients];
          ingredientsTemp[
            ingredientsTemp.findIndex(
              (ingredient) => ingredient.key === options.rowData.key
            )
          ][options.field] = resultEvent;
          setRecipe({ ...recipe, ingredients: ingredientsTemp });
          options.editorCallback(resultEvent);
        }}
        onFocus={handleFocus}
        disabled={data}
      />
    );
  };

  const addIngredient = () => {
    const ingredientsTemp = recipe.ingredients ? recipe.ingredients : [];
    ingredientsTemp.push({
      key: ingredientsTemp.length,
      ingredient: { ingredientId: null, ingredientName: null },
      ratio: 0,
    });
    setRecipe({ ...recipe, ingredients: ingredientsTemp });
  };

  const cellEditor = (options) => {
    if (options.field === 'ingredientName') return ingredientEditor(options);
    if (options.field === 'ratio') return ratioEditor(options);
  };

  const deleteIngredient = (itemData) => {
    return (
      <Button
        icon="pi pi-trash"
        className=" p-button-sm p-button-outlined"
        onClick={() =>
          setRecipe({
            ...recipe,
            ingredients: recipe.ingredients.filter(
              (ingredient) => ingredient.key !== itemData.key
            ),
          })
        }
        disabled={data}
      />
    );
  };

  const actionDialog = () => {
    if (action === null || action === undefined) return null;
    switch (action.name) {
      case 'create':
        return (
          <CreateRecipeConfirmation
            data={recipe}
            oldRecipe={oldRecipe}
            newRecipeId={newRecipeId}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => {
              onCompletion();
              onClose();
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '90vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      {action !== null && actionDialog()}
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          {data ? 'Edit Recipe' : 'Create Recipe'}
        </h2>
      </div>
      <div className="p-fluid grid">
        <div className="field col-6">
          <label htmlFor="recipeName">Name*</label>
          <InputText
            value={recipe.recipeName}
            id="recipeName"
            type="text"
            onChange={(e) =>
              setRecipe({
                ...recipe,
                recipeName: e.target.value.substring(0, 50),
              })
            }
          />
        </div>
        <div className="field col-6">
          <label htmlFor="recipeId">Recipe ID</label>
          <InputText
            value={recipe.recipeId}
            id="recipeId"
            type="text"
            disabled
          />
        </div>
        <div className="field col-12">
          <label htmlFor="notes">Notes</label>
          <InputText
            value={recipe.notes}
            id="notes"
            type="text"
            onChange={(e) =>
              setRecipe({
                ...recipe,
                notes: e.target.value.substring(0, 50),
              })
            }
          />
        </div>
        <div className="field col-12">
          <h5>Ingredients*</h5>
          <DataTable
            value={recipe.ingredients}
            emptyMessage="Add ingredients"
            showGridlines
            scrollable
            size="small"
            scrollHeight="50vh"
            sortField="#"
            sortOrder={-1}
            editMode="cell"
          >
            <Column header="#" body={getRowIndex} style={{ minWidth: '10%' }} />
            {columns.map(({ key, field, header, width, body }) => (
              <Column
                key={key || null}
                field={field}
                header={header}
                body={body || null}
                style={{ minWidth: width }}
                editor={data ? null : (options) => cellEditor(options)}
              />
            ))}
            <Column
              key="delete"
              body={deleteIngredient}
              style={{ minWidth: '10%' }}
            />
          </DataTable>
        </div>
        {data && (
          <div className="field col-12" style={{ color: 'orange' }}>
            Editing of ingredients and ratios is disabled for exisiting recipes
            please create a new recipe to change said parameters
          </div>
        )}
        <div className="field col-12">
          <Button
            className="p-button-success p-button-outlined p-button-rounded mr-2 mb-2"
            label="Add another ingredient"
            onClick={() => addIngredient()}
            disabled={
              data || ingredientList.length <= recipe.ingredients.length
            }
          />
        </div>
        <div className="field col-12">
          <span style={{ color: 'red' }}>
            {recipe === data && 'No changes have been made. '}
            {inComplete ? 'Complete all recipe fields! ' : null}
            {inCompleteIngredientList
              ? 'Complete all ingredients fields! '
              : null}
            {(recipe.ingredients
              ? recipe.ingredients.reduce(
                  (sum, ingredient) => sum + ingredient.ratio,
                  0
                )
              : 0) !== 100
              ? 'The proportions needs to add up to 100%!'
              : null}
          </span>
        </div>
        <div className="field col-6 mt-2 mb-5">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => {
              onCompletion();
              onClose();
            }}
          />
        </div>
        <div className="field col-6 mt-2 mb-5">
          <Button
            label={data ? 'Save' : 'Create Recipe'}
            onClick={() => setAction({ name: 'create' })}
            className="p-button-success  p-button-rounded"
            disabled={
              inComplete ||
              recipe === data ||
              inCompleteIngredientList ||
              (recipe.ingredients
                ? recipe.ingredients.reduce(
                    (sum, ingredient) => sum + ingredient.ratio,
                    0
                  )
                : 0) !== 100
            }
          />
        </div>
      </div>
    </Dialog>
  );
}

export default CreateRecipe;
