import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { FaBox } from 'react-icons/fa';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { useContext, useEffect, useState } from 'react';
import { formatDate } from 'pages/Supplier/Supplier';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import PackagingEdit, { fetchPackagesList } from './PackagingEdit';
import createDeliveryBill from './GenerateDeliveryBill';

function Packaging({ task, taskBatches }) {
  const MILISECONDINDAY = 24 * 3600 * 1000;
  const toast = useContext(ToastContext);
  const [packagesList, setPackageList] = useState([]);
  const [action, setAction] = useState();
  const [expandedRows, setExpandedRows] = useState([]);

  const originalAmountDecimal = (rowData) => {
    return rowData.amount ? rowData.amount.toFixed(2) : '0.00';
  };

  const bestBeforeTemplate = (rowData) =>
    rowData.status === 'prepared' &&
    Math.floor((rowData.bestBefore - new Date()) / MILISECONDINDAY) <= -1 ? (
      <span style={{ color: 'red' }}>{formatDate(rowData.bestBefore)}</span>
    ) : (
      formatDate(rowData.bestBefore)
    );

  const columns = [
    { header: 'Package ID', field: 'packageId' },
    { header: 'Customer', field: 'company' },
    {
      header: 'Date of Delivery',
      field: 'deliveryDate',
      body: (rowData) => formatDate(rowData.bestBefore),
    },
    { header: 'Best Before', field: 'bestBefore', body: bestBeforeTemplate },
    { header: 'Assigned Boxes', field: 'assignedBoxes' },
    {
      header: 'Amount',
      field: 'amount',
      body: originalAmountDecimal,
    },
    { header: 'Status', field: 'status' },
  ];

  const deletePackage = async (actionData) => {
    try {
      await axiosConfig.post('/packaging/delete', {
        packageId: actionData.packageId,
      });

      fetchPackagesList(
        [],
        (packageListTemp) => setPackageList(packageListTemp),
        (actionTemp) => setAction(actionTemp),
        toast
      );
      setAction(null);
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful package deletion',
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.statusText,
      });
    }
  };

  useEffect(() => {
    fetchPackagesList(
      taskBatches,
      (packageListTemp) => setPackageList(packageListTemp),
      (actionTemp) => setAction(actionTemp),
      toast
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowAction = (rowData) => (
    <Dropdown
      options={
        rowData.status === 'prepared'
          ? [
              { label: 'Edit', value: 'edit' },
              { label: 'Delete', value: 'delete' },
              { label: 'Delivery Bill ', value: 'deliveryBill' },
            ]
          : [
              { label: 'View', value: 'view' },
              { label: 'Delivery Bill ', value: 'deliveryBill' },
            ]
      }
      onChange={(e) => {
        setAction({ actionValue: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  const columnsBoxes = [
    { header: 'Box ID', field: 'boxId' },
    { header: 'Charge ID', field: 'chargeId' },
    { header: 'Batch ID', field: 'batchId' },
    { header: 'Amount', field: 'amount' },
  ];

  const rowExpansionTemplate = (rowData) => (
    <DataTable value={rowData.boxes}>
      {columnsBoxes.map(({ header, field, body }) => (
        <Column header={header} field={field} body={body} />
      ))}
    </DataTable>
  );

  const actionDialog = () => {
    if (!action || action === null || action === undefined) return null;
    switch (action.actionValue) {
      case 'create':
        return (
          <PackagingEdit
            data={null}
            display={action?.actionValue === 'create'}
            onCompletion={() =>
              fetchPackagesList(
                [],
                (packageListTemp) => setPackageList(packageListTemp),
                (actionTemp) => setAction(actionTemp),
                toast
              )
            }
            onClose={() => setAction(null)}
            readOnly={false}
          />
        );
      case 'edit':
        return (
          <PackagingEdit
            data={action.actionData}
            display={action?.actionValue === 'edit'}
            onCompletion={() =>
              fetchPackagesList(
                [],
                (packageListTemp) => setPackageList(packageListTemp),
                (actionTemp) => setAction(actionTemp),
                toast
              )
            }
            onClose={() => setAction(null)}
            readOnly={false}
          />
        );
      case 'view':
        return (
          <PackagingEdit
            data={action.actionData}
            display={action?.actionValue === 'view'}
            onCompletion={() =>
              fetchPackagesList(
                [],
                (packageListTemp) => setPackageList(packageListTemp),
                (actionTemp) => setAction(actionTemp),
                toast
              )
            }
            onClose={() => setAction(null)}
            readOnly
          />
        );
      case 'deliveryBill':
        createDeliveryBill(
          action.actionData.packageNumber,
          action.actionData.bestBefore &&
            formatDate(action.actionData.bestBefore),
          action.actionData.deliveryDate &&
            formatDate(action.actionData.deliveryDate),
          action.actionData.costumerName,
          'breed-placeholder',
          `${action.actionData.amount} kg`,
          `${action.actionData.streetName} ${action.actionData.streetNumber}, ${action.actionData.postalCode} ${action.actionData.city}, ${action.actionData.country}`,
          action.actionData.invoiceNumber || ''
        );
        setAction(null);
        return null;
      case 'delete':
        confirmDialog({
          header: 'Confirm Deletion',
          icon: 'pi pi-exclamation-triangle',
          rejectLabel: 'Cancel',
          acceptLabel: 'Delete',
          message: 'Are you sure you want to delete this package?',
          reject: () => setAction(null),
          accept: () => deletePackage(action.actionData),
        });
        // setAction(null);
        return null;
      default:
        setAction(null);
        return null;
    }
  };
  return (
    <div className="main-card main-card-content">
      <ConfirmDialog />
      {action !== null && actionDialog()}
      <div className="formgrid grid">
        <div className="field col-6 mt-2 mb-5">
          <h2 className="flex align-items-center">
            <FaBox className="mr-3" /> Packaging
          </h2>
        </div>
        <div className="field col-12">
          <DataTable
            emptyMessage="No packages deliveries"
            value={Array.isArray(packagesList) ? packagesList : []}
            responsiveLayout="scroll"
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            <Column expander />
            {columns.map(({ header, field, body }) => (
              <Column header={header} field={field} body={body || null} />
            ))}
            <Column header="Action" body={rowAction} />
          </DataTable>
        </div>
        {/* */}
      </div>
      <div className="p-fluid formgrid grid">
        <div className="field col-12 mt-2 mb-5">
          <Button
            className="p-button-outlined p-button-success p-button-rounded"
            label="Create new package"
            onClick={() =>
              setAction({ actionValue: 'create', actionData: null })
            }
          />
        </div>
      </div>
    </div>
  );
}

Packaging.propTypes = {
  task: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  taskBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Packaging;
