/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import { useState, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { InputNumber } from 'primereact/inputnumber';
import axiosConfig from 'utils/axiosConfig';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

import { checkInputMissingExceptComment } from 'utils/utils';

function SievingSaveResultsDialog({ itemData, display, onResult, onClose }) {
  const toast = useContext(ToastContext);
  // STATES

  const [confirmation, setConfirmation] = useState(false);

  const [sievingResult, setSievingResult] = useState({
    excrementWeight: '0',
    skinWeight: '0',
    smallInsectWeight: '0',
    insectWeight: '',
    puppetsWeight: '0',
    beetlesWeight: '0',
    residualFood: '0',
    comment: ' ',
  });

  if (!itemData) return null;
  const { machineId, machineName, processId, user } = itemData;

  const saveSievingResults = async () => {
    try {
      await axiosConfig.post('/sieving/result', {
        sievingResult,
        machineId,
        processId,
        email: user.email,
      });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Saving Sieving Results!',
      });

      onResult();
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while saving Sieving Results!',
      });
    }
  };

  const handleFocus = (event) => event.target.select();

  const headerText = () => {
    const text = 'Enter Sieving Results';
    return <h4>{text}</h4>;
  };

  const confirmationDialog = (confirmationTemp) => (
    <ConfirmDialog
      visible={confirmationTemp}
      onHide={() => setConfirmation(false)}
      message="Save sieving results?"
      rejectLabel="Cancel"
      acceptLabel="Confirm"
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
      accept={() => saveSievingResults()}
    />
  );

  const firstPage = () => {
    return (
      <div className="formgrid p-fluid grid grid-nogutter align-items-end">
        {/* Input Form Components */}
        <>
          <div className="field col-2 col-offset-3">
            <label htmlFor="process">Process ID</label>
            <InputText
              value={itemData.processId}
              id="process"
              type="text"
              disabled
            />
          </div>

          <div className="field col-1" />

          <div className="field col-2">
            <label htmlFor="workitem">Machine</label>
            <InputText value={machineName} id="workitem" type="text" disabled />
          </div>
          <div className="field col-3" />

          <div className="field col-2  ">
            <label htmlFor="insectweight">Insects</label>
            <div className="p-inputgroup">
              <InputNumber
                id="insectweight"
                value={sievingResult.insectWeight}
                onChange={(e) =>
                  setSievingResult({
                    ...sievingResult,
                    insectWeight: Math.max(e.value, 0),
                  })
                }
                min={0}
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
                onFocus={handleFocus}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>
          <div className="field col-1" />

          <div className="field col-2 ">
            <label htmlFor="smallinsectweight">Small Insects</label>
            <div className="p-inputgroup">
              <InputNumber
                id="smallinsectweight"
                value={sievingResult.smallInsectWeight}
                onChange={(e) =>
                  setSievingResult({
                    ...sievingResult,
                    smallInsectWeight: Math.max(e.value, 0),
                  })
                }
                min={0}
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
                onFocus={handleFocus}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>
          <div className="field col-1" />
          <div className="field col-2 ">
            <label htmlFor="puppetsweight">Puppets</label>
            <div className="p-inputgroup">
              <InputNumber
                id="puppetsweight"
                value={sievingResult.puppetsWeight}
                onChange={(e) =>
                  setSievingResult({
                    ...sievingResult,
                    puppetsWeight: Math.max(e.value, 0),
                  })
                }
                min={0}
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
                onFocus={handleFocus}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>

          <div className="field col-1" />
          <div className="field col-2 ">
            <label htmlFor="beetlesweight">Beetles</label>
            <div className="p-inputgroup">
              <InputNumber
                id="beetlesweight"
                value={sievingResult.beetlesWeight}
                onChange={(e) =>
                  setSievingResult({
                    ...sievingResult,
                    beetlesWeight: Math.max(e.value, 0),
                  })
                }
                min={0}
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>

          <div className="field col-1" />
          <div className="field col-2 ">
            <label htmlFor="excrementweight">Excrement</label>
            <div className="p-inputgroup">
              <InputNumber
                id="excrementweight"
                value={sievingResult.excrementWeight}
                onChange={(e) =>
                  setSievingResult({
                    ...sievingResult,
                    excrementWeight: Math.max(e.value, 0),
                  })
                }
                min={0}
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
                onFocus={handleFocus}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>

          <div className="field col-1" />
          <div className="field col-2 ">
            <label htmlFor="skinweight">Skin</label>
            <div className="p-inputgroup">
              <InputNumber
                id="skinweight"
                value={sievingResult.skinWeight}
                onChange={(e) =>
                  setSievingResult({
                    ...sievingResult,
                    skinWeight: Math.max(e.value, 0),
                  })
                }
                min={0}
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
                onFocus={handleFocus}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>
          <div className="field col-1" />
          <div className="field col-2 ">
            <label htmlFor="residualFood">Residual food</label>
            <div className="p-inputgroup">
              <InputNumber
                id="residualFood"
                value={sievingResult.residualFood}
                onChange={(e) =>
                  setSievingResult({
                    ...sievingResult,
                    residualFood: Math.max(e.value, 0),
                  })
                }
                min={0}
                mode="decimal"
                locale="de-DE"
                minFractionDigits={1}
                onFocus={handleFocus}
              />
              <span
                style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                className="p-inputgroup-addon"
                title="Weight per sieving"
              >
                kg
              </span>
            </div>
          </div>

          <div className="field col-12">
            <label htmlFor="comment">Comment</label>
            <div className="grid">
              <div className="field col-12">
                <InputText
                  value={sievingResult.comment}
                  id="comment"
                  type="text"
                  onChange={(e) =>
                    setSievingResult({
                      ...sievingResult,
                      comment: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </>
        {/* Buttons */}
        <div className="field col-12 mt-2">
          <div className="grid grid-nogutter">
            <div className=" col-4 col-offset-4 ">
              <Button
                className="p-button-rounded p-button-success "
                label="Record Sieving Results"
                icon="pi pi-check"
                onClick={() => setConfirmation(true)}
                disabled={checkInputMissingExceptComment(sievingResult)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // RENDER

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        {confirmation && confirmationDialog(confirmation)}
        <div className=" col-12 flex justify-content-between mb-2">
          {headerText()}

          <Button
            onClick={() => onClose()}
            icon="pi pi-times"
            className="p-button-rounded p-button-warning p-button-outlined  "
          />
        </div>
      </div>

      {firstPage()}
    </Dialog>
  );
}

export default SievingSaveResultsDialog;
