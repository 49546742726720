/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useContext } from 'react';
import { Button } from 'primereact/button';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';

function CreateRecipeConfirmation({
  data,
  oldRecipe,
  newRecipeId,
  display,
  onClose,
  onCompletion,
}) {
  const toast = useContext(ToastContext);
  const [recipe] = useState(data);

  const sendRecipe = async (action) => {
    try {
      const recipeIngredients = [];
      // eslint-disable-next-line array-callback-return
      recipe.ingredients.map((ingredient) => {
        recipeIngredients.push({
          ratio: ingredient.ratio / 100,
          ingredientId: ingredient.ingredient.ingredientId,
        });
      });

      const oldRecipeIngredients = [];
      if (oldRecipe)
        // eslint-disable-next-line array-callback-return
        oldRecipe.ingredients.map((ingredient) => {
          oldRecipeIngredients.push({
            ratio: ingredient.ratio / 100,
            ingredientId: ingredient.ingredient.ingredientId,
          });
        });
      switch (action) {
        case 'create':
          await axiosConfig.post('/foodLogistics/createRecipe', {
            recipeId: parseInt(recipe.recipeId.substring(3), 10),
            recipeName: recipe.recipeName,
            status: 'active',
            notes: recipe.notes,
            recipeIngredients,
          });
          toast.pushToast({
            severity: ToastSeverity.SUCCESS,
            detail: 'Successful creation!',
          });
          break;
        case 'editAndUnlist':
          await axiosConfig.post('/foodLogistics/createRecipe', {
            recipeId: parseInt(oldRecipe.recipeId.substring(3), 10),
            recipeName: oldRecipe.recipeName,
            status: 'hidden',
            notes: oldRecipeIngredients.notes,
            recipeIngredients: oldRecipeIngredients,
          });
          await axiosConfig.post('/foodLogistics/createRecipe', {
            recipeId: parseInt(newRecipeId, 10),
            recipeName: recipe.recipeName,
            status: 'active',
            notes: recipe.notes,
            recipeIngredients,
          });
          toast.pushToast({
            severity: ToastSeverity.SUCCESS,
            detail: 'Successful creation and unlisted!',
          });
          break;
        case 'saveAsNew':
          await axiosConfig.post('/foodLogistics/createRecipe', {
            recipeId: parseInt(newRecipeId, 10),
            recipeName: recipe.recipeName,
            status: 'active',
            notes: recipe.notes,
            recipeIngredients,
          });
          toast.pushToast({
            severity: ToastSeverity.SUCCESS,
            detail: 'Successful creation!',
          });
          break;
        default:
          return null;
      }
      onClose();
      onCompletion();
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '60vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center">
          {oldRecipe
            ? `Confirm Editing of Recipe: ${data.recipeName}`
            : `Confirm Creation of Recipe: ${data.recipeName}`}
        </h2>
      </div>
      <div className="p-fluid grid">
        {oldRecipe ? (
          <>
            <div className="field col-6">
              <Button
                className="p-button-secondary p-button-rounded mr-2 mb-2"
                label="Cancel"
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="field col-6">
              <Button
                label="Save Changes"
                className="p-button-success  p-button-rounded"
                onClick={() => sendRecipe('create')}
              />
            </div>
          </>
        ) : (
          <>
            <div className="field col-6">
              <Button
                className="p-button-secondary p-button-rounded mr-2 mb-2"
                label="Cancel"
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="field col-6">
              <Button
                onClick={() => sendRecipe('create')}
                label="Save as new recipe"
                className="p-button-success  p-button-rounded"
              />
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export default CreateRecipeConfirmation;
