/* eslint-disable react/prop-types */
import { useContext, useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import axiosConfig from 'utils/axiosConfig';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { FoodDeliveryContext } from './FoodContext';

function FoodAddIngredients({ display, onClose, onCompletion }) {
  const toast = useContext(ToastContext);
  const { ingredientList, setIngredientList } = useContext(FoodDeliveryContext);

  const [inComplete, setInComplete] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [addIngredient, setAddIngredient] = useState({
    weight: 0.0,
    ingredient: null,
    deliveries: null,
  });

  const listOfIngredientIds = Array.isArray(ingredientList)
    ? ingredientList.map((ingredient) => ingredient.ingredientID)
    : [];

  useEffect(() => {
    fetchIngredientsTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInComplete(false);
    for (const key of Object.keys(addIngredient)) {
      if (
        key !== 'deliveries' &&
        (addIngredient[key] === null ||
          addIngredient[key] === undefined ||
          addIngredient[key] === '' ||
          addIngredient[key] === 0)
      ) {
        setInComplete(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addIngredient, ingredientList]);

  const handleFocus = (event) => event.target.select();

  const headerText = () => {
    const text = 'Add New Ingredient';
    return <h3>{text}</h3>;
  };

  const fetchIngredientsTable = async () => {
    try {
      const ingredientTableJSON = await axiosConfig
        .get('/foodLogistics/ingredients')
        .then((res) => res.data);
      const ingredientTableTemp = [];
      // eslint-disable-next-line no-unused-expressions
      Array.isArray(ingredientTableJSON)
        ? ingredientTableJSON.map((ingredient) => {
            if (!listOfIngredientIds.includes(ingredient.IngredientID))
              ingredientTableTemp.push({
                label: ingredient.Name,
                value: {
                  ingredientId: ingredient.IngredientID,
                  ingredientName: ingredient.Name,
                },
              });
            return null;
          })
        : null;
      setIngredients(ingredientTableTemp);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching ingredient List',
      });
    }
  };

  const fetchDeliveriesForIngredient = async (ID) => {
    try {
      return await axiosConfig
        .get('/foodLogistics/deliveriesForIngredient', { params: { ID } })
        .then((res) => res.data);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching ingredient List',
      });
    }
    return [];
  };

  const addIngredientToList = () => {
    const ingredientListTemp = !ingredientList ? [] : ingredientList;
    fetchDeliveriesForIngredient(addIngredient.ingredient.ingredientId)
      .then((deliveries) =>
        ingredientListTemp.concat({
          ingredientID: addIngredient.ingredient.ingredientId,
          ingredientName: addIngredient.ingredient.ingredientName,
          targetWeight: addIngredient.weight,
          assignedWeight: 0,
          numberOfAssignedDeliveries: 0,
          assignedDeliveries: [],
          deliveries,
        })
      )
      .then((list) => {
        setIngredientList(list);
        return list;
      })
      .then((list) => onCompletion(list))
      .then(() => onClose());
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className="field col-12 flex justify-content-between mb-2">
          {headerText()}
        </div>
        <div className="field col-8">
          <label htmlFor="ingredient">Ingredient</label>
          <Dropdown
            id="ingredient"
            options={ingredients}
            value={addIngredient ? addIngredient.ingredient : null}
            onChange={(e) =>
              setAddIngredient({ ...addIngredient, ingredient: e.value })
            }
            placeholder={
              !ingredients
                ? 'No additional Ingredients'
                : 'Select ingredient...'
            }
            disabled={ingredients.length === 0}
          />
        </div>
        <div className="field col-4">
          <label htmlFor="weight">Amount</label>
          <div className="p-inputgroup">
            <InputNumber
              id="weight"
              value={addIngredient.weight}
              // max={assignedDelivery.delivery.remainingAmount}
              onChange={(e) =>
                setAddIngredient({ ...addIngredient, weight: e.value })
              }
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              maxFractionDigits={3}
              onFocus={handleFocus}
              disabled={
                addIngredient.ingredient === null ||
                addIngredient.ingredient === undefined
              }
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Target food weight"
            >
              kg
            </span>
          </div>
        </div>
        <div className=" col-4" />
        <div className=" col-4 ">
          <Button
            onClick={() => onClose()}
            label="Cancel"
            className="p-button-rounded p-button-secondary"
          />
        </div>
        <div className="field col-4 ">
          <Button
            label="Add"
            onClick={() => addIngredientToList()}
            className="p-button-rounded p-button-success"
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default FoodAddIngredients;
