/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper.js';

import axiosConfig from 'utils/axiosConfig';

import BoxQRScan from 'elements/BoxQRScan';

import { ImLab } from 'react-icons/im';
import {
  downloadQRCode,
  generateQRCode,
  showQR,
  QRCodeTemplate,
  generateBarcodePNG,
  BarcodeCodeTemplate,
  showBarcode,
} from 'elements/QRGeneration';

function BoxLogistics() {
  const toast = useContext(ToastContext);

  const [showItemQR, setShowItemQR] = useState('');
  const [visible, setVisible] = useState(false);
  const [boxId, setBoxId] = useState('');
  const [QR, setQR] = useState();
  const [barcode, setBarcode] = useState();

  const [itemList, setItemList] = useState(null); // itemList : [{boxId: 1, Status: active}, {boxId: 2, Status: inactive}]

  useEffect(() => {
    // put into custom hook at some point
    const fetchTableData = async () => {
      try {
        const tableDataJSON = await axiosConfig
          .get('/boxLogistics/table')
          .then((res) => res.data);
        setItemList(tableDataJSON);
      } catch (error) {
        console.log(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching Table Data',
        });
      }
    };

    fetchTableData();
    boxId && generateQRCode(boxId, (QRTemp) => setQR(QRTemp));
    boxId &&
      generateBarcodePNG(boxId, (barcodeTemp) => setBarcode(barcodeTemp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxId]);

  const generateBoxId = async () => {
    const boxIdFree = await axiosConfig
      .get(`/boxLogistics/unusedID`)
      .then((res) => res.data);
    setBoxId(boxIdFree);
  };

  const handleFocus = (event) => event.target.select();

  const dialogSubmit = () => {
    // Only open dialog if all inputs are set
    if (boxId) {
      confirmDialog({
        message: 'Add Box to Server?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => sendBox(boxId),
      });
    } else {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Box ID is Missing!',
      });
    }
  };

  const sendBox = async (box) => {
    try {
      if (box !== boxId) {
        toast.pushToast({
          severity: ToastSeverity.WARN,
          detail: 'Scanned BoxID does not match InputMask!',
        });
      }
      await axiosConfig.post('/boxLogistics/create', {
        boxId: box,
      });

      setBoxId('');
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successfully added Box!',
      });
    } catch (error) {
      console.log(error.response);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: `Error: ${error.response.status} ${error.response.data}`,
      });
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span className={`charge-badge charge-badge-${rowData.Status}`}>
        {rowData.Status}
      </span>
    );
  };

  return (
    <div className="main-card main-card-content">
      {visible === 'QR' &&
        QR &&
        showQR({
          QR,
          visible: visible === 'QR',
          setVisible,
        })}
      {visible === 'barcode' &&
        barcode &&
        showBarcode({
          barcode,
          visible: visible === 'barcode',
          setVisible,
        })}
      <div className="mt-2 mb-5">
        <h2>Box Logistics</h2>
      </div>
      <div className="grid">
        <div className="col-5">
          <div className="card-content p-fluid">
            <div className="mt-2 mb-5">
              <h3>Add Boxes</h3>
            </div>
            <div className="grid">
              <div className="col-12">
                <h4>1. Generate or Enter Box ID </h4>

                <div className="grid">
                  <div className=" col-6">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 mb-2"
                      onClick={() => generateBoxId()}
                      label="Generate"
                    />
                  </div>
                  <div className="col-6">
                    <InputMask
                      mask="BX-99999"
                      value={boxId}
                      id="boxid"
                      type="text"
                      placeholder="BX-"
                      onChange={(e) => setBoxId(e.target.value)}
                      keyfilter="pint"
                      onFocus={handleFocus}
                      // disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-12  ">
                <h4>2. Print QR Code </h4>
                <div className="grid">
                  <div className="col-3">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 "
                      label="Show QR"
                      onClick={() => setVisible('QR')}
                      disabled={(!boxId || boxId.includes('_')) && !barcode}
                    />
                  </div>
                  <div className="col-3">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 "
                      label="Show Barcode"
                      onClick={() => setVisible('barcode')}
                      disabled={(!boxId || boxId.includes('_')) && !barcode}
                    />
                  </div>
                  <div className="col-3">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 "
                      label="Print QR"
                      onClick={() => QR && downloadQRCode(QR, boxId)}
                      disabled={(!boxId || boxId.includes('_')) && !barcode}
                    />
                  </div>
                  <div className="col-3">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 "
                      label="Print Barcode"
                      onClick={() => barcode && downloadQRCode(barcode, boxId)}
                      disabled={(!boxId || boxId.includes('_')) && !barcode}
                    />
                  </div>
                </div>
              </div>

              <div className="col-6 ">
                <h4>3. Scan Box </h4>

                <Button
                  className="p-button-rounded mr-2"
                  onClick={() => setShowItemQR('box')}
                  icon="pi pi-qrcode"
                  disabled={boxId.includes('_') || boxId.length === 0}
                />
                {showItemQR === 'box' ? (
                  <BoxQRScan
                    display={Boolean(showItemQR)}
                    boxShouldExist={false}
                    onResult={(box) => {
                      sendBox(box);
                    }}
                    onClose={() => {
                      setShowItemQR('');
                    }}
                  />
                ) : null}
                <Button
                  className="p-button-rounded p-button-help p-button-outlined"
                  onClick={() => dialogSubmit()}
                  icon={<ImLab className="p-button-help" />}
                  disabled={boxId.includes('_') || boxId.length === 0}
                />
              </div>
              <div className="col-6">
                <h4>4. Generate Box </h4>
                <Button
                  className="p-button-success p-button-rounded"
                  label="Generate box"
                  onClick={() => sendBox(boxId)}
                  disabled={!boxId || boxId.includes('_')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-7">
          <div className="card-content p-fluid">
            <div className="mt-2 mb-5">
              <h3>Box Overview</h3>
            </div>
            <DataTable
              value={itemList}
              showGridlines
              scrollable
              scrollHeight="50vh"
            >
              <Column field="boxId" header="Box ID" sortable />
              <Column
                field="Status"
                header="Status"
                body={statusBodyTemplate}
                sortable
              />
              <Column
                header="QR"
                body={(rowData) => QRCodeTemplate(rowData.boxId)}
              />
              <Column
                header="Barcode"
                body={(rowData) => BarcodeCodeTemplate(rowData.boxId)}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxLogistics;
