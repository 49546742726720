/* eslint-disable react/prop-types */
import { useState, useContext } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import AuthContext from 'store/auth-context';
// Login Form Component

export default function LoginModal({ visible, onHide }) {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="User Profile"
      style={{ width: '50vw' }}
    >
      <div className="grid">
        <div className="col-12">
          <div className="avatar">
            {isLoading ? <i className="pi pi-user" /> : null}
            <img
              src={user.picture}
              alt="profilepicture"
              style={isLoading ? { display: 'none' } : { display: 'block' }}
              onLoad={() => {
                setIsLoading(false);
              }}
            />
          </div>
        </div>
        <div className="col-12">
          <p className="username">{user.givenName}</p>
          <p className="role">{String(user.role)}</p>
          {/* <p className="phone"> {user.phone}</p> */}
        </div>
        {/* Logout Button */}
        <div className="col-12 flex justify-content-end">
          <Button
            icon="pi pi-sign-out"
            className="p-button-secondary p-button-outlined"
            onClick={async () => {
              await authContext.logout();
              onHide();
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
