/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { Dialog } from 'primereact/dialog';

const addPrefix = (id, prefix, numLeadingZeroes) =>
  `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;

function DeliveryCreate({ data, display, onClose, onCompletion }) {
  const initItem = {
    rawFoodId: '',
    ingredient: '',
    supplierID: '',
    deliveryDate: new Date(),
    bestBefore: new Date(),
    deliveryAmount: 0,
    notes: '',
  };
  const toast = useContext(ToastContext);
  const [ingredientsList, setIngredientList] = useState([]);
  const [supplierNameList, setSupplierNameList] = useState(true);
  const [supplierIdList, setSupplierIdList] = useState([]);
  const [inComplete, setInComplete] = useState(true);
  const [item, setItem] = useState(initItem);

  useEffect(() => {
    generateFoodId();
    fetchIngredientList();
    fetchSupplierList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInComplete(false);
    for (const key of Object.keys(item)) {
      if (
        key !== 'notes' &&
        (item[key] === null ||
          item[key] === undefined ||
          item[key] === '' ||
          item[key] === 0 ||
          (key !== 'deliveryDate' &&
            key !== 'bestBefore' &&
            item[key] === initItem[key]))
      ) {
        setInComplete(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleFocus = (event) => event.target.select();

  const fetchDeliveryList = async () => {
    try {
      const deliveryListTemp = await axiosConfig
        .get('/foodLogistics/allDelivery')
        .then((res) => res.data);
      return deliveryListTemp;
    } catch (error) {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching delivery list',
      });
    }
  };

  const fetchIngredientList = async () => {
    try {
      const ingredients = await axiosConfig
        .get('/foodLogistics/ingredients')
        .then((res) => res.data);
      if (ingredients) {
        setIngredientList(
          Array.isArray(ingredients)
            ? ingredients.map(({ IngredientID, Name }) => ({
                label: Name,
                value: IngredientID,
              }))
            : []
        );
      }
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching ingredient List',
      });
    }
  };

  const fetchSupplierList = async () => {
    try {
      const suppliersTemp = await axiosConfig
        .get('/foodLogistics/suppliers')
        .then((res) => res.data);
      if (Array.isArray(suppliersTemp)) {
        const suppliers = suppliersTemp.filter(
          (supplier) => supplier.Status === 'active'
        );
        setSupplierNameList(
          suppliers.map(({ SupplierID, Company }) => ({
            label: Company,
            value: addPrefix(SupplierID, 'SP', 5),
          }))
        );
        setSupplierIdList(
          suppliers.map(({ SupplierID }) => ({
            label: addPrefix(SupplierID, 'SP', 5),
            value: addPrefix(SupplierID, 'SP', 5),
          }))
        );
      }
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching suppliers List',
      });
    }
  };

  const generateFoodId = async () => {
    const deliveryListTemp = await fetchDeliveryList();
    if (!deliveryListTemp) return null;
    const maxDeliveryID =
      1 +
      Math.max(
        ...deliveryListTemp.map((delivery) =>
          parseInt(delivery.delivery.substring(3), 10)
        )
      );
    setItem({ ...item, rawFoodId: addPrefix(maxDeliveryID, 'DE', 5) });
  };

  // can be made into one-liner!
  const resetForm = () => {
    setItem(initItem);
  };

  const sendDelivery = async (delivery) => {
    try {
      await axiosConfig.post('/foodLogistics/createDelivery', {
        ...delivery,
        bestBefore: new Date(delivery.bestBefore).getTime(),
        deliveryDate: new Date(delivery.deliveryDate).getTime(),
      });

      resetForm();
      onCompletion();
      onClose();

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful delivery creation',
      });
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.statusText,
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '90vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className="field col-6">
          <h3>New Ingredient Delivery</h3>
        </div>
        <div className="field col-6">
          <label htmlFor="deliveryID">Delivery ID</label>
          <InputMask
            id="deliveryID"
            mask="DE-99999"
            value={item.rawFoodId}
            type="text"
            placeholder="DE-"
            onChange={(e) => setItem({ ...item, rawFoodId: e.target.value })}
            disabled
          />
        </div>
        <div className="field col-6">
          <label htmlFor="ingredient">Ingredient</label>
          <Dropdown
            id="ingredient"
            value={item.ingredient}
            options={Array.isArray(ingredientsList) ? ingredientsList : null}
            onChange={(e) => setItem({ ...item, ingredient: e.value })}
          />
        </div>
        <div className="field col-6">
          <label htmlFor="supplierName">Supplier Name</label>
          <Dropdown
            id="supplierName"
            value={item.supplierID}
            options={Array.isArray(supplierNameList) ? supplierNameList : null}
            onChange={(e) => {
              setItem({ ...item, supplierID: e.value });
            }}
          />
        </div>
        <div className="field col-6">
          <label htmlFor="supplierID">Supplier ID</label>
          <Dropdown
            id="supplierID"
            value={item.supplierID}
            options={Array.isArray(supplierIdList) ? supplierIdList : null}
            onChange={(e) => {
              setItem({ ...item, supplierID: e.value });
            }}
          />
        </div>
        <div className="field col-6">
          <label htmlFor="deliveryDate">Delivery Date</label>
          <Calendar
            dateFormat="dd.mm.yy"
            value={item.deliveryDate}
            id="deliveryDate"
            onChange={(e) =>
              setItem({
                ...item,
                deliveryDate: e.target.value,
              })
            }
            onFocus={handleFocus}
          />
        </div>
        <div className="field col-6">
          <label htmlFor="bestBefore">Best Before</label>
          <Calendar
            dateFormat="dd.mm.yy"
            value={item.bestBefore}
            id="bestBefore"
            onChange={(e) =>
              setItem({
                ...item,
                bestBefore: e.target.value,
              })
            }
            minDate={new Date()}
            onFocus={handleFocus}
          />
        </div>
        <div className="field col-6">
          <label htmlFor="deliveryAmount">Amount</label>
          <div className="p-inputgroup">
            <InputNumber
              value={item.deliveryAmount}
              id="deliveryAmount"
              onChange={(e) =>
                setItem({ ...item, deliveryAmount: Math.abs(e.value) })
              }
              mode="decimal"
              locale="de-DE"
              minFractionDigits={1}
              onFocus={handleFocus}
              min={0}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per sieving"
            >
              kg
            </span>
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="notes">Notes</label>
          <InputText
            value={item.notes}
            id="notes"
            type="text"
            onChange={(e) =>
              setItem({ ...item, notes: e.target.value.substring(0, 50) })
            }
            onFocus={handleFocus}
          />
        </div>
        <div className="field col-6">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
        <div className="field col-6">
          <Button
            className="p-button-success p-button-rounded"
            label="Create Ingredient"
            onClick={() => sendDelivery(item)}
            disabled={inComplete}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default DeliveryCreate;
