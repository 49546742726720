import { useState, useContext } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';

import DataTableEditable from 'elements/DataTableEditable';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
// import useFetchBoxData from 'hooks/useFetchBoxData';
// import useFetchRandomBox from 'hooks/useFetchRandomBox';

function Repositioning() {
  const toast = useContext(ToastContext);

  const [reboxingItemList, setReboxingItemList] = useState([]);
  // const [boxIdCurrent, setBoxIdCurrent] = useState('');
  // const [boxIdNew, setBoxIdNew] = useState('');
  const boxIdCurrent = '1';
  const boxIdNew = '2';
  // const [comment, setComment] = useState('test');
  const workItemComment = 'test';

  const datatableColumns = [
    { key: 'boxIdCurrent', header: 'Current Box ID', field: 'boxIdCurrent' },
    { key: 'boxIdNew', header: 'New Box ID', field: 'boxIdNew' },
    { key: 'chargeId', header: 'Charge ID', field: 'chargeId' },
    { key: 'stackId', header: 'Stack ID', field: 'stackId' },
    { key: 'comment', header: 'Comment', field: 'comment' },
  ];

  const handleFocus = (event) => event.target.select();

  const submitItemList = async () => {
    try {
      // send list of boxes to backend // TODO: add comments

      await axiosConfig.post('/api/reboxing', {
        reboxingItemList,
        workItemComment,
      });

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful Reboxing!',
      });
      setReboxingItemList([]);
      // change to next page
      //   onActiveIndexChange(1);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  const dialogSubmitItemList = () => {
    // Retrieve all chargeIds from reboxingItemList and check if any entry is undefined
    const chargeIds = reboxingItemList.map((item) => item.chargeId);

    if (chargeIds.every((chargeId) => chargeId !== undefined)) {
      confirmDialog({
        message: 'Send Reboxing List?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => submitItemList(),
      });
    } else {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Charge ID Missing!',
      });
    }
  };

  // const RandomBoxActive = async () => {
  //   try {
  //     const rndBoxId = await useFetchRandomBox(
  //       boxIdCurrent,
  //       reboxingItemList.map((item) => {
  //         return { boxId: item.boxIdCurrent };
  //       }),
  //       'active'
  //     );
  //     if (rndBoxId) {
  //       setBoxIdCurrent(rndBoxId);
  //     } else {
  //       toast.pushToast({
  //         severity: ToastSeverity.ERROR,
  //         detail: 'No (more) Active Boxes Found!',
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.pushToast({
  //       severity: ToastSeverity.ERROR,
  //       detail: error.response.status + error.response.statusText,
  //     });
  //   }
  // };

  // const RandomBoxInactive = async () => {
  //   try {
  //     const rndBoxId = await useFetchRandomBox(
  //       boxIdNew,
  //       reboxingItemList.map((item) => {
  //         return { boxId: item.boxIdNew };
  //       }),
  //       'inactive'
  //     );
  //     if (rndBoxId) {
  //       setBoxIdNew(rndBoxId);
  //     } else {
  //       toast.pushToast({
  //         severity: ToastSeverity.ERROR,
  //         detail: 'No (more) Inactive Boxes Found!',
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.pushToast({
  //       severity: ToastSeverity.ERROR,
  //       detail: error.response.status + error.response.statusText,
  //     });
  //   }
  // };

  return (
    <div className="main-card main-card-content">
      <div className="mt-2 mb-5">
        <h2>Repositioning</h2>
      </div>
      <div className="grid">
        <div className="col-4">
          <div className="card-content p-fluid">
            <h3>Stack </h3>
            <div className="mt-5">
              <h5>1. Enter Current Stack Position </h5>
              <div className="grid formgrid align-items-end">
                <div className="field col-4">
                  <InputText
                    value={boxIdNew}
                    type="text"
                    placeholder=" Area"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-3">
                  <InputText
                    value={boxIdCurrent}
                    // type="text"
                    placeholder="Position"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-2">
                  <Button
                    className="p-button-rounded p-button-outlined mt-3 ml-2"
                    label="Set"
                    // onClick={() => {
                    //   setBoxIdCurrent('');
                    //   setBoxIdNew('');
                    //   setComment('');
                    // }}
                  />
                </div>
              </div>

              <h5>2. Enter New Stack Position </h5>
              <div className="grid formgrid align-items-end">
                <div className="field col-4">
                  <InputText
                    value={boxIdNew}
                    type="text"
                    placeholder=" Area"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-3">
                  <InputText
                    value={boxIdCurrent}
                    // type="text"
                    placeholder="Position"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-2">
                  <Button
                    className="p-button-rounded p-button-outlined mt-3 ml-2"
                    label="Set"
                    // onClick={() => {
                    //   setBoxIdCurrent('');
                    //   setBoxIdNew('');
                    //   setComment('');
                    // }}
                  />
                </div>
                <div className="field col-12 flex justify-content-center mt-3 mb-2">
                  <Button
                    className="p-button-success p-button-rounded mr-2  "
                    // onClick={() => dialogSubmitItemList()}
                    disabled={!reboxingItemList.length}
                  >
                    Reposition
                  </Button>
                </div>
              </div>

              {/* <div className="field col-6">
                  <label htmlFor="name1">Work Item Comment</label>
                  <InputText
                    id="name1"
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
                <div className="field col-12 flex justify-content-center">
                  <Button
                    className="p-button-rounded  mt-3 "
                    label="Save "
                    onClick={AddToTable}
                    icon="pi pi-check"
                    disabled={!boxIdCurrent || !boxIdNew}
                  />

                  <Button
                    className="p-button-rounded p-button-outlined mt-3 ml-2"
                    label="Clear"
                    onClick={() => {
                      setBoxIdCurrent('');
                      setBoxIdNew('');
                      setComment('');
                    }}
                    disabled={
                      !Object.values([boxIdCurrent, boxIdNew, comment]).some(
                        Boolean
                      )
                    }
                  />
                </div> */}
            </div>
          </div>
        </div>

        <div className="col-8">
          <div className="card-content ">
            <div className="mt-2">
              <h3>2. Check Boxes</h3>
              <div className="mt-4">
                <DataTableEditable
                  itemList={reboxingItemList}
                  columns={datatableColumns}
                  onChangeItemList={(itemListEdited) =>
                    setReboxingItemList(itemListEdited)
                  }
                />
              </div>
              <div className="field col-12 flex justify-content-center mt-3 mb-2">
                <Button
                  className="p-button-success p-button-rounded mr-2  "
                  onClick={() => dialogSubmitItemList()}
                  disabled={!reboxingItemList.length}
                >
                  Finalize Reboxing
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="card-content ">
            <h3>Box - Position </h3>
            <div className="mt-5">
              <h5>1. Enter Current Box Position</h5>
              <div className="grid formgrid align-items-end p-fluid">
                <div className="field col-4">
                  <InputText
                    value={boxIdNew}
                    type="text"
                    placeholder=" Area"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-3">
                  <InputText
                    value={boxIdCurrent}
                    // type="text"
                    placeholder="Position"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-2">
                  <Button
                    className="p-button-rounded p-button-outlined mt-3 ml-2"
                    label="Set"
                    // onClick={() => {
                    //   setBoxIdCurrent('');
                    //   setBoxIdNew('');
                    //   setComment('');
                    // }}
                  />
                </div>
              </div>

              <h5>2. Enter New Box Position </h5>
              <div className="grid formgrid align-items-end  p-fluid">
                <div className="field col-4">
                  <InputText
                    value={boxIdNew}
                    type="text"
                    placeholder=" Area"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-3">
                  <InputText
                    value={boxIdCurrent}
                    // type="text"
                    placeholder="Position"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-2">
                  <Button
                    className="p-button-rounded p-button-outlined mt-3 ml-2"
                    label="Set"
                  />
                </div>
              </div>
              <div className="flex justify-content-center">
                <Button
                  className="p-button-success p-button-rounded mr-2  "
                  // onClick={() => dialogSubmitItemList()}
                  disabled={!reboxingItemList.length}
                >
                  Reposition
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card-content ">
            <h3>Box - Position In Stack</h3>
            <div className="mt-5">
              <h5>1. Enter or Scan Box</h5>
              <div className="grid formgrid align-items-end p-fluid">
                <div className="field col-4">
                  <InputText
                    value={boxIdNew}
                    type="text"
                    placeholder=" Area"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-3">
                  <InputText
                    value={boxIdCurrent}
                    // type="text"
                    placeholder="Position"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-2">
                  <Button
                    className="p-button-rounded p-button-outlined mt-3 ml-2"
                    label="Set"
                    // onClick={() => {
                    //   setBoxIdCurrent('');
                    //   setBoxIdNew('');
                    //   setComment('');
                    // }}
                  />
                </div>
              </div>

              <h5>2. Enter New Box Position </h5>
              <div className="grid formgrid align-items-end  p-fluid">
                <div className="field col-4">
                  <InputText
                    value={boxIdNew}
                    type="text"
                    placeholder=" Area"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-3">
                  <InputText
                    value={boxIdCurrent}
                    // type="text"
                    placeholder="Position"
                    onFocus={handleFocus}
                  />
                </div>

                <div className="field col-2">
                  <Button
                    className="p-button-rounded p-button-outlined mt-3 ml-2"
                    label="Set"
                  />
                </div>
              </div>
              <div className="flex justify-content-center">
                <Button
                  className="p-button-success p-button-rounded mr-2  "
                  // onClick={() => dialogSubmitItemList()}
                  disabled={!reboxingItemList.length}
                >
                  Reposition
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Repositioning;
