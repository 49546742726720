import bwipjs from 'bwip-js';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import QRCode from 'qrcode';

// generate QR code as a png element for the ID
function generateQRCode(id, cb) {
  return (
    id &&
    QRCode.toDataURL(
      id,
      {
        width: 512,
        margin: 2,
        color: {
          dark: '#000000',
          light: '#FFFFFF',
        },
      },
      (err, QR) => {
        if (err) return console.error(err);
        cb(QR);
        return QR;
      }
    )
  );
}

// displays the png element in a dialog
function showQR({ QR, visible, setVisible }) {
  return (
    <Dialog header="QR code" visible={visible} onHide={() => setVisible(false)}>
      <img src={QR} alt="QR code" />
    </Dialog>
  );
}

function showBarcode({ barcode, visible, setVisible }) {
  return (
    <Dialog header="Barcode" visible={visible} onHide={() => setVisible(false)}>
      <img src={barcode} alt="Barcode" />
    </Dialog>
  );
}

// Download png element as png file
function downloadQRCode(QR, id) {
  const download = document.createElement('a');
  download.href = QR;
  download.download = `${id}.png`;
  download.click();
}

// button in table to create
function QRCodeTemplate(id) {
  return (
    <Button
      icon="pi pi-download"
      onClick={() => generateQRCode(id, (QRTemp) => downloadQRCode(QRTemp, id))}
    />
  );
}

function BarcodeCodeTemplate(id) {
  return (
    <Button
      icon="pi pi-download"
      onClick={() =>
        generateBarcodePNG(id, (barcodeTemp) => downloadQRCode(barcodeTemp, id))
      }
    />
  );
}

function generateBarcodePNG(id, cb) {
  const canvas = document.createElement('canvas');
  id &&
    bwipjs.toCanvas(canvas, {
      bcid: 'code128', // Specify the barcode format you prefer
      text: id,
      scale: 2, // Adjust the barcode scale as needed
      includetext: true,
      extxalign: 'center',
      height: 30, // Adjust the barcode height as needed
      width: 60, // Adjust the barcode width as needed
    });
  id && cb(canvas.toDataURL('image/png'));
}

export {
  generateQRCode,
  showQR,
  downloadQRCode,
  QRCodeTemplate,
  generateBarcodePNG,
  BarcodeCodeTemplate,
  showBarcode,
};
