/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { FaCrosshairs } from 'react-icons/fa';
import PositionDialog from 'elements/PositionDialog';

async function fetchCachedList(toast) {
  try {
    return axiosConfig
      .get('/boxLogistics/cachedStacks')
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'Error while fetching Food List',
    });
  }
}

function Positioning() {
  const toast = useContext(ToastContext);
  const [showDialog, setShowDialog] = useState('');
  const [cachedList, setCachedList] = useState([]);
  const [refreshCacheList, setRefreshCacheList] = useState(false);
  const [selectedStacks, setSelectedStacks] = useState([]);

  const positionButton = (rowData) => (
    <div className="p-fluid">
      <Button
        className="p-button-success p-button-rounded p-button-outlined"
        label="Place now"
        onClick={() => {
          setSelectedStacks([rowData.stackId]);
          setShowDialog('positionDialog');
        }}
      />
    </div>
  );

  const columns = [
    { header: 'Stack ID', field: 'stackId' },
    { header: 'Last Action', field: 'action' },
    { header: 'Inserted', field: 'createdAt' },
    {
      body: (rowData) => positionButton(rowData),
      style: { width: '5rm' },
    },
  ];

  useEffect(() => {
    fetchCachedList(toast).then((stacks) => setCachedList(stacks));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCachedList(toast).then((stacks) => setCachedList(stacks));
    setRefreshCacheList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCacheList]);

  return (
    <div className="main-card main-card-content">
      {showDialog === 'positionDialog' && (
        <PositionDialog
          visible={showDialog}
          onHide={() => {
            setShowDialog('');
            setSelectedStacks([]);
          }}
          stacks={selectedStacks}
          onComplete={() => {
            setSelectedStacks([]);
            setRefreshCacheList(true);
          }}
        />
      )}
      <div className="formgrid grid">
        <div className="field col-8 mt-2 mb-5">
          <h2 className="flex align-items-center">
            <FaCrosshairs className="mr-3" /> Position
          </h2>
        </div>
        <div className="field col-12 mt-2 mb-5">
          <DataTable
            emptyMessage="No cached boxes found"
            value={cachedList}
            responsiveLayout="scroll"
            dataKey="stackId"
          >
            {columns.map(({ header, field, body, style }, key) => (
              <Column
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                header={header}
                field={field}
                body={body}
                style={style}
              />
            ))}
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export { Positioning, fetchCachedList };
