/* eslint-disable react/prop-types */
import { useState, useContext } from 'react';
import axiosConfig from 'utils/axiosConfig';
import QrReader from 'react-qr-reader';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

export async function checkProductionPosition({
  positionId,
  productionPositionShouldExist = true,
  productionPositionShouldBeActive = false,
}) {
  const productionPosition = await axiosConfig
    .get(`/productionposition/getById`, {
      params: {
        productionPositionId: positionId,
      },
    })
    .then((res) => res.data);

  // For adding new items to a list with unique productionPositionIds
  switch (true) {
    case !productionPosition && productionPositionShouldExist:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Production Position does not exist!',
      };

    case productionPosition && !productionPositionShouldExist:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Production Position already exists!',
      };

    case productionPosition.Status === 'occupied' &&
      !productionPositionShouldBeActive:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Production Position is already occupied!',
      };

    case productionPosition.Status === 'inactive' &&
      productionPositionShouldBeActive:
      return {
        severity: ToastSeverity.ERROR,
        detail: `Production Position is ${productionPosition.Status}!`,
      };

    default:
      return false;
  }
}

function ProductionPositionRetriever({
  onResult,
  onClose,
  display,
  productionPositionShouldExist = true,
  productionPositionShouldBeActive = false,
  // List of productionPositionIds that should not be in the list as they have already been selected by one of the other stacks
  modeList = ['scan', 'select'], // List of scan modes to use
  batchList: listBatch,
}) {
  // CONTEXTS
  const toast = useContext(ToastContext);

  const scanOptions = [
    { name: 'Box', value: 'box' },
    { name: 'Position', value: 'position' },
  ];

  // STATES
  const [triggerPressed, setTriggerPressed] = useState(false);

  const [mode, setMode] = useState(modeList.length === 1 ? modeList[0] : '');

  const [selectedStack, setSelectedStack] = useState(0);

  const [scanMode, setScanMode] = useState(
    scanOptions.length === 1 ? scanOptions[0].value : 'box'
  );

  const [batchList, setBatchList] = useState(listBatch);

  const handleScanBox = async (scanResult) => {
    if (scanResult === null && triggerPressed) {
      setTriggerPressed(false);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No QR Code found!',
      });
    }

    if (scanResult !== null && triggerPressed) {
      setTriggerPressed(false);

      const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

      if (resultItemPrefix !== 'BX' || !resultItemIdValue) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code not valid!',
        });
        return false;
      }
      // check in which stack in the batchlist the box is, if it is not in the list, push a toast
      const stackIndex = batchList.findIndex((stack) =>
        stack.stackList.find((box) => box.boxId === scanResult)
      );
      if (stackIndex === -1) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Box not in batch list!',
        });
        return false;
      }
      // Set the selected stack to the one associated with the box
      setSelectedStack(batchList[stackIndex]);
      setScanMode('position');

      return true;
    }

    return false;
  };

  const handleScanPosition = async (scanResult) => {
    if (scanResult === null && triggerPressed) {
      setTriggerPressed(false);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No QR Code found!',
      });
    }

    if (scanResult !== null && triggerPressed) {
      setTriggerPressed(false);

      const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

      if (resultItemPrefix !== 'PS' || !resultItemIdValue) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code not valid!',
        });
        return false;
      }

      // Check if the position is already used by another stack
      const stackIdx = batchList.findIndex(
        (stack) => stack.positionId === scanResult
      );

      if (stackIdx !== -1) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Position already used by this or another stack!',
        });
        return false;
      }

      // Check if boxes exist and are active
      const toastMsg = await checkProductionPosition({
        positionId: scanResult,
        productionPositionShouldExist,
        productionPositionShouldBeActive,
      });
      // push toast message if there are any
      if (toastMsg) {
        toast.pushToast(toastMsg);
        return false;
      }

      // find the index of the currently selected stack
      const stackIndex = batchList.findIndex(
        (stack) => stack === selectedStack
      );
      // set the area and spot id to the currently selected stack, add productionPositionId to the batch list

      const newBatchList = [...batchList];
      newBatchList[stackIndex].positionId = scanResult;

      setBatchList(newBatchList);

      return true;
    }

    return false;
  };

  const headerText = () => {
    let text = '';

    switch (mode) {
      case 'scan':
        text = 'Place Stacks and confirm';
        break;
      case 'select':
        text = 'Select Production Position';
        break;
      default:
        text = '';
        break;
    }
    return <h4>{text}</h4>;
  };

  const batchStackNumBodyTemplate = (options) => {
    // find the index of this row in the batchList
    const stackIndex = batchList.findIndex((stack) => stack === options);
    return stackIndex + 1;
  };

  const areaBodyTemplate = (options) => {
    if (options.positionId !== undefined) {
      const positionIdStr = options.positionId ? options.positionId : '';

      const [, positionId] = positionIdStr.split('-'); // e.g. '01059'

      const areaId = parseInt(positionId.substring(0, 2), 10);
      return areaId;
    }
    return '';
  };

  const spotBodyTemplate = (options) => {
    if (options.positionId !== undefined) {
      const positionIdStr = options.positionId ? options.positionId : '';

      const [, positionId] = positionIdStr.split('-'); // e.g. '01059'

      const spotId = parseInt(positionId.substring(3, 5), 10);
      return spotId;
    }
    return '';
  };

  const returnBatchList = () => {
    // check if production position is set for each stack in batchlist
    const toastMsg = batchList.find((stack) => !stack.positionId);
    if (toastMsg) {
      toast.pushToast(toastMsg);
      return false;
    }
    onResult(batchList);
    onClose();
    return true;
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-between mb-2">
          {headerText()}

          <Button
            onClick={() => onClose()}
            icon="pi pi-times"
            className="p-button-rounded p-button-warning p-button-outlined  "
          />
        </div>
        {(mode === 'scan' || modeList.length === 1) && (
          <>
            <div className="col-6">
              <div className="grid grid-no-gutters">
                <div className="col-8 col-offset-1 mb-2">
                  <SelectButton
                    value={scanMode}
                    options={scanOptions}
                    onChange={(e) => setScanMode(e.value)}
                    optionLabel="name"
                  />
                </div>
                <div className="col-10 ">
                  <QrReader
                    delay={300}
                    onScan={
                      scanMode === 'box' ? handleScanBox : handleScanPosition
                    }
                  />
                </div>
              </div>
            </div>

            <div className="col-5">
              <DataTable
                value={batchList}
                size="small"
                showGridlines
                scrollable
                scrollHeight="351px"
                selectionMode="single"
                selection={selectedStack}
              >
                <Column
                  key="stack"
                  header="Stack"
                  field="stackId"
                  className="justify-content-center"
                  body={batchStackNumBodyTemplate}
                />

                <Column
                  key="area"
                  header="Area"
                  className="justify-content-center"
                  field="areaId"
                  body={areaBodyTemplate}
                />
                <Column
                  key="spot"
                  header="Spot"
                  field="spotId"
                  className="justify-content-center"
                  body={spotBodyTemplate}
                />
              </DataTable>
            </div>

            <div className=" col-12 flex justify-content-between mt-2">
              <Button
                onClick={() => setTriggerPressed(true)}
                icon="pi pi-camera"
                className="p-button-outlined p-button-rounded"
              />

              <Button
                onClick={() => returnBatchList()} // check if productionPositionId for each stack is set // then return batchList with added production positions as result
                icon="pi pi-check"
                className="p-button-rounded p-button-success   "
                disabled={batchList.find((stack) => !stack.positionId)}
              />
            </div>
          </>
        )}
        {mode === 'select' && <div className="col-12">select</div>}

        {!mode && modeList.length !== 1 && (
          <div className="col-12 flex justify-content-between ">
            <Button
              style={{ height: '100%', width: '100%', fontSize: '28px' }}
              label="Scan"
              icon="pi pi-qrcode"
              className="p-button-outlined  "
              onClick={() => {
                setMode('scan');
              }}
            />

            <Button
              style={{ height: '100%', width: '100%', fontSize: '28px' }}
              label="Select"
              className="p-button-outlined   "
              onClick={() => {
                setMode('select');
              }}
              disabled
            />
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default ProductionPositionRetriever;
