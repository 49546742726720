/* eslint-disable react/prop-types */
import { useState, useContext } from 'react';

import classNames from 'classnames';

import AppSidebar from 'elements/AppSidebar';
import AppTopbar from 'elements/AppTopbar';

import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom'; // Router für das Navigieren zwischen den versch. Unterseiten

import LandingPage from 'pages/LandingPage/LandingPage';
import Overview from 'pages/Overview/Overview';
import StablingMain from 'pages/Stabling/StablingMain';
import Reboxing from 'pages/Reboxing/Reboxing';
import Repositioning from 'pages/Repositioning/Repositioning';
import Feeding from 'pages/Feeding/Feeding';
import BoxLogistics from 'pages/Logistics/BoxLogistics';
import EggLogistics from 'pages/Logistics/EggLogistics';
import FoodLogisticsMain from 'pages/Logistics/FoodLogistics/FoodLogisticsMain';
import Dashboard from 'pages/Dashboard/Dashboard';
import Packaging from 'pages/Packaging/Packaging';

import UserProfileModal from 'elements/UserProfileModal';
import Sieving from 'pages/Sieving/Sieving';
import AuthContext from 'store/auth-context';

import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import './sass/main.scss';
import FarmSetup from 'pages/FarmSetup/FarmSetup';
import WorkPlan from 'pages/WorkPlan/WorkPlan';
import Supplier from 'pages/Supplier/Supplier';
import Inspection from 'pages/Inspection/Inspection';
import { Positioning } from 'pages/Positioning/Positioning';
import Footer from 'elements/AppFooter';
import Customer from 'pages/Customer/Customer';

function ProtectedRoute({ isAllowed, redirectPath = '/', children }) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />; // Redirect to login page if not logged in
  }
  // eslint-disable-next-line no-unneeded-ternary
  return children ? children : <Outlet />;
}

function App() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [userAuthVis, setUserAuthVis] = useState(false);
  const [task, setTask] = useState(null);
  const [taskBatches, setTaskBatches] = useState([]);

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const wrapperClass = classNames('layout-wrapper', {
    'layout-static': true,
    'layout-static-sidebar-inactive': false,
    'p-input-filled': false,
  });

  return (
    <div className={wrapperClass}>
      <Router>
        <AppTopbar
          onClick={(vis) => setSidebarVisible(vis)}
          onClickProfile={(vis) => setUserAuthVis(vis)}
        />

        {!!user && (
          <>
            <UserProfileModal
              visible={userAuthVis}
              onHide={() => setUserAuthVis(false)}
            />
            <AppSidebar
              visible={sidebarVisible}
              onHide={() => setSidebarVisible(false)}
            />
          </>
        )}

        <div className="layout-main-container">
          <div className="layout-main">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route element={<ProtectedRoute isAllowed={!!user} />}>
                <Route
                  path="/sieving"
                  element={<Sieving task={task} taskBatches={taskBatches} />}
                />
                <Route
                  path="/overview"
                  element={
                    <Overview
                      setTask={setTask}
                      setTaskBatches={setTaskBatches}
                    />
                  }
                />
                <Route path="/stabling" element={<StablingMain />} />
                <Route path="/reboxing" element={<Reboxing />} />
                <Route path="/repositioning" element={<Repositioning />} />
                <Route
                  path="/feeding"
                  element={<Feeding task={task} taskBatches={taskBatches} />}
                />
                <Route path="/boxlogistics" element={<BoxLogistics />} />
                <Route path="/egglogistics" element={<EggLogistics />} />
                <Route path="/foodlogistics" element={<FoodLogisticsMain />} />
                <Route path="/positioning" element={<Positioning />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/packaging"
                  element={<Packaging task={task} taskBatches={taskBatches} />}
                />
                <Route path="/customer" element={<Customer />} />
                <Route
                  path="/inspection"
                  element={<Inspection task={task} taskBatches={taskBatches} />}
                />
                <Route
                  path="/farmsetup"
                  element={
                    <ProtectedRoute
                      isAllowed={!!user && user.role === 'admin'}
                      redirectPath="/overview"
                    >
                      <FarmSetup />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/workplan"
                  element={
                    <ProtectedRoute
                      isAllowed={!!user && user.role === 'admin'}
                      redirectPath="/overview"
                    >
                      <WorkPlan />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/supplier"
                  element={
                    <ProtectedRoute
                      isAllowed={!!user && user.role === 'admin'}
                      redirectPath="/overview"
                    >
                      <Supplier />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Routes>
          </div>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
