/* eslint-disable react/prop-types */
function PlaceStackIllustration({ productionPositionId, boxList }) {
  const [, positionId] = productionPositionId.split('-'); // e.g. '01059'

  const areaId = parseInt(positionId.substring(0, 2), 10);
  const spotId = parseInt(positionId.substring(3, 5), 10);
  return (
    <div className="grid ">
      <div className="col-4 flex flex-column justify-content-center">
        {boxList.map((box, i) => (
          <div
            //   key={i}
            className="eurobox "
            style={{
              zIndex: boxList.length - i,
              marginBottom: i !== boxList.length - 1 ? '-1rem' : '0',
            }}
          >
            <img
              src="assets/images/box_single_empty.png"
              alt="box_single_empty"
              className="eurobox-image"
            />
            <div className="text-on-image">
              <p> {box.boxId} </p>
            </div>
          </div>
        ))}
      </div>
      <div className="col-4 flex justify-content-center align-items-center">
        <img src="assets/images/arrow.png" alt="arrow" />
      </div>
      <div className="col-4 flex flex-column justify-content-center align-items-center">
        <div className="container">
          <img
            src="assets/images/container.png"
            alt="container"
            className="container-image"
          />
          <div className="text-on-container">
            <p style={{ fontWeight: 'bold', fontSize: '28px' }}>{areaId}</p>
          </div>
        </div>
        <div className="spot mt-3">
          <p
            className="spot-id"
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            Spot: {spotId}
          </p>
        </div>
      </div>
      {/* <div className="col-4 col-offset-8 flex justify-content-center">
        confirm deny
      </div> */}
    </div>
  );
}

export default PlaceStackIllustration;
