/*
Unterseite "Stabling", hier findet das Tracking bei der Einstallung statt
Diese Seite wird gerenderd
*/

import { useState, useEffect, useContext } from 'react';

import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PropTypes from 'prop-types';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

// An editable Datatable: itemList: Object with all columns header: headline (default null)
function DataTableEditable({
  itemList,
  columns,
  header,
  onChangeItemList,
  isDeletable,
}) {
  const toast = useContext(ToastContext);
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const [editItem, setEditItem] = useState({});

  const [itemListCopy, setItemListCopy] = useState(itemList);

  useEffect(() => {
    setItemListCopy(itemList);
  }, [itemList]);

  const deleteItem = async () => {
    // very very hacky, delete item by first column (assuming this is a unique value)
    const primaryKeyItemList = Object.keys(itemList[0])[0];

    const itemListFiltered = itemList.filter(
      (val) => val[primaryKeyItemList] !== editItem[primaryKeyItemList]
    );
    onChangeItemList(itemListFiltered);
    setDeleteItemDialog(false);
    setEditItem({});
    toast.pushToast({
      severity: ToastSeverity.SUCCESS,
      detail: 'Item deleted',
    });
  };

  const confirmDeleteItem = (rowData) => {
    setEditItem(rowData);
    setDeleteItemDialog(true);
  };

  const deleteButtonTemplate = (itemData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-secondary p-button-outlined mr-2 mb-2"
        onClick={() => confirmDeleteItem(itemData)}
      />
    );
  };

  const hideDeleteItemDialog = () => {
    setDeleteItemDialog(false);
  };

  const deleteItemDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteItemDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteItem}
      />
    </>
  );

  return (
    <>
      <DataTable
        value={itemListCopy}
        // reorderableColumns
        // onRowReorder={onRowReorder}
      >
        <Column rowReorder style={{ width: '3em' }} />

        {columns.map((column) => (
          <Column
            key={column.key}
            field={column.field}
            header={column.header}
            sortable={'sortable' in column ? column.sortable : false}
          />
        ))}
        {/* The Item Delete Button in the table */}
        {isDeletable && (
          <Column
            headerStyle={{ width: '4rem', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
            body={deleteButtonTemplate}
          />
        )}
      </DataTable>

      {/* The Item Delete Dialog */}

      {isDeletable && (
        <Dialog
          header="Confirm"
          visible={deleteItemDialog}
          onHide={() => setDeleteItemDialog(false)}
          style={{ width: '350px' }}
          modal
          footer={deleteItemDialogFooter}
        >
          <div className="flex align-items-center justify-content-center">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: '2rem' }}
            />
            {editItem && <span> Are you sure you want to delete?</span>}
          </div>
        </Dialog>
      )}
    </>
  );
}

DataTableEditable.defaultProps = {
  header: '',
  itemList: [],

  isDeletable: true,
};

DataTableEditable.propTypes = {
  itemList: PropTypes.instanceOf(Array),
  header: PropTypes.string,
  onChangeItemList: PropTypes.func.isRequired,
  isDeletable: PropTypes.bool,
  columns: PropTypes.instanceOf(Array).isRequired,
};

export default DataTableEditable;
