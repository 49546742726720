/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from 'store/auth-context';

export default function AppTopbar({ onClick, onClickProfile }) {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  return (
    <div className="layout-topbar">
      {/* hide sidebar when not logged in */}
      <button
        onClick={(vis) => onClick(vis)}
        type="button"
        className="p-link layout-topbar-button"
        style={{ visibility: user ? 'visible' : 'hidden' }}
      >
        <i className="pi pi-bars" />
      </button>

      <Link to="/overview" className="layout-topbar-logo">
        <img
          src="assets/images/Logo.png"
          alt="Logo"
          className="topbar-logo-img"
        />
      </Link>

      <button
        className="p-link layout-topbar-button"
        type="button"
        onClick={(vis) => onClickProfile(vis)}
        style={{ visibility: user ? 'visible' : 'hidden' }}
      >
        <i className="pi pi-user" />
      </button>
    </div>
  );
}
