/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';

function DeliveryFood({ data, display, onClose, onCompletion }) {
  const [food, setFood] = useState();
  const { ingredient, delivery } = data;
  const toast = useContext(ToastContext);

  const currentAmountDecimal = (rowData) => {
    return rowData.currentAmount ? rowData.currentAmount.toFixed(2) : '0.00';
  };

  const columnsFood = [
    { header: 'Name', field: 'name' },
    { header: 'Food-Id', field: 'foodID' },
    { header: 'Created', field: 'createdAt' },
    { header: 'Best Before', field: 'bestBefore' },
    {
      header: 'Current Amount [kg]',
      field: 'currentAmount',
      body: currentAmountDecimal,
    },
    { header: 'Recipe ID', field: 'recipeID' },
  ];

  useEffect(() => {
    fetchFoodList(delivery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFoodList = async (RawFoodID) => {
    const ID = parseInt(RawFoodID.substring(3), 10);
    try {
      const foodTemp = await axiosConfig
        .get('/foodLogistics/allDeliveryFood', { params: { ID } })
        .then((res) => res.data);
      setFood(foodTemp);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Food List',
      });
    }
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '90vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="mt-2 mb-5">
        <h3 className="flex align-items-center">
          {' '}
          Food with ingredient: {ingredient} (ID: {delivery})
        </h3>
      </div>
      <DataTable
        emptyMessage="No charges found"
        size="small"
        value={food}
        responsiveLayout="scroll"
      >
        {columnsFood.map(({ header, field }) => (
          <Column header={header} field={field} />
        ))}
      </DataTable>

      <div className="p-fluid formgrid grid">
        <div className="field col-12 mt-2 mb-5">
          <Button
            className="p-button-secondary p-button-rounded mr-2 mb-2"
            label="Cancel"
            onClick={() => onClose()}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default DeliveryFood;
