import { useState, useContext } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import BoxQRScan, { checkBoxes } from 'elements/BoxQRScan';
import DataTableEditable from 'elements/DataTableEditable';
import AuthContext from 'store/auth-context';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import useFetchBoxData from 'hooks/useFetchBoxData';
import PositionDialog from '../../elements/PositionDialog';

function Reboxing() {
  const toast = useContext(ToastContext);
  const { user } = useContext(AuthContext);

  const [reboxingItemList, setReboxingItemList] = useState([]);
  const [boxIdCurrent, setBoxIdCurrent] = useState('');
  const [boxIdNew, setBoxIdNew] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [comment, setComment] = useState('');
  // const [workItemComment, setWorkItemComment] = useState('test wt');
  const [showItemQR, setShowItemQR] = useState('');

  const datatableColumns = [
    { key: 'boxIdCurrent', header: 'Current Box ID', field: 'boxIdCurrent' },
    { key: 'boxIdNew', header: 'New Box ID', field: 'boxIdNew' },
    { key: 'chargeId', header: 'Charge ID', field: 'chargeId' },
    { key: 'stackId', header: 'Stack ID', field: 'stackId' },
    { key: 'comment', header: 'Comment', field: 'comment' },
  ];

  const submitItemList = async ({ reboxingItems, boxesAndPositions }) => {
    try {
      // send list of boxes to backend // TODO: add comments
      await axiosConfig.post('/boxLogistics/rebox', {
        boxAndChargeIds: reboxingItems.map((reboxingItem) => ({
          oldBoxId: reboxingItem.boxIdCurrent,
          boxId: reboxingItem.boxIdNew,
          chargeId: reboxingItem.chargeId,
          position: 0,
        })),
        workItemComment: comment,
        email: user.email,
        boxesAndPositions,
      });

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful Reboxing!',
      });
      // change to next page
      //   onActiveIndexChange(1);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  const resetValues = () => {
    setReboxingItemList([]);
    setComment('');
  };

  const AddToTable = async () => {
    // Fetch some data interesting for the table
    const { chargeId, stackId } = await useFetchBoxData(boxIdCurrent);
    const toastMsgList = [
      await checkBoxes({
        boxIdList: [boxIdCurrent],
        boxesShouldExist: true,
        boxesShouldBeActive: true,
      }),
      await checkBoxes({
        boxIdList: [boxIdNew],
        boxesShouldExist: true,
        boxesShouldBeActive: false,
      }),
    ];

    // check if list already is in list
    // eslint-disable-next-line no-unused-expressions
    reboxingItemList.find((item) => item.boxIdCurrent === boxIdCurrent)
      ? toastMsgList.push([
          {
            severity: ToastSeverity.ERROR,
            detail: `Box ${boxIdCurrent} already exists in list!`,
          },
        ])
      : null;

    // eslint-disable-next-line no-unused-expressions
    reboxingItemList.find((item) => item.boxIdNew === boxIdNew)
      ? toastMsgList.push([
          {
            severity: ToastSeverity.ERROR,
            detail: `Box ${boxIdNew} already exists in list!`,
          },
        ])
      : null;

    toastMsgList.map(
      (toastMsg) =>
        toastMsg.length && toastMsg.forEach((msg) => toast.pushToast(msg))
    );

    if (toastMsgList.some((toastMsg) => toastMsg)) {
      return false;
    }

    const tableItem = { boxIdCurrent, chargeId, stackId, boxIdNew, comment };
    setReboxingItemList(
      !Array.isArray(reboxingItemList)
        ? [tableItem]
        : [...reboxingItemList, tableItem]
    );
    setBoxIdCurrent('');
    setBoxIdNew('');
    return true;
  };
  const handleFocus = (event) => event.target.select();

  const dialogSubmitItemList = () => {
    // Retrieve all chargeIds from reboxingItemList and check if any entry is undefined
    const chargeIds = reboxingItemList.map((item) => item.chargeId);

    if (chargeIds.every((chargeId) => chargeId !== undefined)) {
      confirmDialog({
        message: 'Please confirm reboxing',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Rebox and place later',
        accept: () =>
          submitItemList({ reboxingItems: reboxingItemList }).then(() =>
            resetValues()
          ),
      });
    } else {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Charge ID Missing!',
      });
    }
  };

  return (
    <div className="main-card main-card-content ">
      {showItemQR && (
        <BoxQRScan
          onResult={(scanResult) =>
            showItemQR === 'current'
              ? setBoxIdCurrent(scanResult[0].boxId)
              : setBoxIdNew(scanResult[0].boxId)
          }
          onClose={() => {
            setShowItemQR(false);
          }}
          display={Boolean(showItemQR)}
          boxesShouldBeActive={showItemQR === 'current'}
          scanModeList={['series', 'stack', 'batch']}
        />
      )}
      <ConfirmDialog />
      {showDialog === true && (
        <PositionDialog
          visible
          onHide={() => {
            setShowDialog(false);
            resetValues();
          }}
          boxes={reboxingItemList?.map((item) => item.boxIdNew)}
          onComplete={() => {
            resetValues();
            setShowDialog(false);
          }}
          stackAndBoxes={reboxingItemList?.map((item) => ({
            stackId: item.stackId,
            boxId: item.boxIdNew,
          }))}
        />
      )}
      <div className="mt-2 mb-5">
        <h2>Reboxing</h2>
      </div>
      <div className="grid">
        <div className="col-4">
          <div className="card-content p-fluid ">
            <div className="mt-2">
              <h3>1. Scan Current and New Box</h3>
              <div className="formgrid grid align-items-end">
                <div className="field col-8">
                  <label htmlFor="boxid">Current Box ID</label>
                  <InputMask
                    mask="BX-99999"
                    value={boxIdCurrent}
                    id="boxid"
                    type="text"
                    placeholder="BX-"
                    onChange={(e) => setBoxIdCurrent(e.target.value)}
                    keyfilter="pint"
                    onFocus={handleFocus}
                    // disabled
                  />
                </div>

                <div className="field col-4 flex justify-content-center ">
                  <Button
                    className="p-button-rounded"
                    onClick={() => setShowItemQR('current')}
                    icon="pi pi-qrcode"
                  />
                </div>

                <div className="field col-8">
                  <label htmlFor="boxid">New Box ID</label>
                  <InputMask
                    mask="BX-99999"
                    value={boxIdNew}
                    id="boxid"
                    type="text"
                    placeholder="BX-"
                    onChange={(e) => setBoxIdNew(e.target.value)}
                    keyfilter="pint"
                    onFocus={handleFocus}
                    // disabled
                  />
                </div>

                <div className="field col-4 flex justify-content-center">
                  <Button
                    className="p-button-rounded"
                    onClick={() => setShowItemQR(true)}
                    icon="pi pi-qrcode"
                  />
                </div>
                <div className="field col-12 flex justify-content-center">
                  <Button
                    className="p-button-rounded  mt-3 "
                    label="Save "
                    onClick={AddToTable}
                    icon="pi pi-check"
                    disabled={!boxIdCurrent || !boxIdNew}
                  />

                  <Button
                    className="p-button-rounded p-button-outlined mt-3 ml-2"
                    label="Clear"
                    onClick={() => {
                      setBoxIdCurrent('');
                      setBoxIdNew('');
                      setComment('');
                    }}
                    disabled={
                      !Object.values([boxIdCurrent, boxIdNew, comment]).some(
                        Boolean
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-8">
          <div className="card-content p-fluid">
            <div className="mt-2">
              <h3>2. Check Boxes</h3>
              <div className="mt-4">
                <DataTableEditable
                  itemList={reboxingItemList}
                  columns={datatableColumns}
                  onChangeItemList={(itemListEdited) =>
                    setReboxingItemList(itemListEdited)
                  }
                />
              </div>
              <div className="field col-12">
                <label htmlFor="name1">Comment</label>
                <InputText
                  id="name1"
                  type="text"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className="grid p-fluid">
                <div className="col-6">
                  <Button
                    label="Complete now and place later"
                    className="p-button-success p-button-rounded mr-2  "
                    onClick={() => dialogSubmitItemList()}
                    disabled={!reboxingItemList.length}
                  />
                </div>
                <div className="col-6">
                  <Button
                    label="Complete and place now"
                    className="p-button-success p-button-rounded mr-2  "
                    onClick={() =>
                      confirmDialog({
                        message: 'Please confirm reboxing',
                        header: 'Confirmation',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: 'Rebox and place now',
                        accept: () =>
                          submitItemList({
                            reboxingItems: reboxingItemList,
                          }).then(() => setShowDialog(true)),
                      })
                    }
                    disabled={!reboxingItemList.length}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reboxing;
