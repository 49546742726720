import { useContext } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import axiosConfig from 'utils/axiosConfig';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

export function addPrefix(id, prefix, numLeadingZeroes) {
  return `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;
}

// eslint-disable-next-line react/prop-types
function WorkPlanDelete({ data, display, onClose, onCompletion }) {
  const toast = useContext(ToastContext);
  // eslint-disable-next-line react/prop-types
  const { WorkPlanName, WorkPlanID, NumberOfCharges } = data;

  const deleteWorkPlan = async (PlanID) => {
    try {
      await axiosConfig.post('/workPlan/delete', { PlanID });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Deleted work plan!',
      });
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while deleting work plan',
      });
    }
    onClose();
    onCompletion();
  };

  const headerText = () => {
    const text = 'Confirm Deletion of Work Plan';
    return <h4>{text}</h4>;
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-between mb-2">
          {headerText()}
        </div>
        <div className=" col-12" style={{ marginBottom: '1em' }}>
          <span />
          {`Are you sure to delete the ${WorkPlanName} (ID: ${addPrefix(
            WorkPlanID,
            'WP',
            5
          )})? On confirmation the work plan will be deleted and the ${NumberOfCharges} assigned charges will be unassigned. \n`}
        </div>
        <div className=" col-8" />
        <div className=" col-2 ">
          <Button
            onClick={() => onClose()}
            label="Cancel"
            className="p-button-rounded p-button-secondary"
          />
        </div>
        <div className=" col-2 ">
          <Button
            onClick={() => deleteWorkPlan(WorkPlanID)}
            label="Confirm"
            className="p-button-rounded p-button-success"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default WorkPlanDelete;
