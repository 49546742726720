import axiosConfig from 'utils/axiosConfig';

const useFetchBoxData = async (boxId) => {
  const boxAndChargeIds = await axiosConfig
    .get('/boxLogistics/chargesAndStacksForBoxes', {
      params: {
        boxIds: [boxId],
      },
    })
    .then((res) => res.data);
  return boxAndChargeIds[0];
};

export default useFetchBoxData;
