import { useState } from 'react';

import PickProductionPosition from 'elements/ProductionPosition/PickProductionPosition';
import { Button } from 'primereact/button';

function StablingExtendStack() {
  const [showItemQR, setShowItemQR] = useState('');
  return (
    <div className="main-card">
      <Button
        onClick={() => setShowItemQR('show')}
        className="btn btn-primary"
        style={{ marginBottom: '10px' }}
      >
        Extend Stack
      </Button>
      <PickProductionPosition
        onResult={(pos) => {
          console.log(pos);
        }}
        onClose={() => {
          setShowItemQR('');
        }}
        display={Boolean(showItemQR)}
      />
    </div>
  );
}

export default StablingExtendStack;
