/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { FaTruck } from 'react-icons/fa';
import { Dropdown } from 'primereact/dropdown';

import {
  downloadQRCode,
  generateBarcodePNG,
  generateQRCode,
} from 'elements/QRGeneration';
import { FoodContext } from './FoodContext';
import FoodDeliveryDisposeRest from './FoodDeliveryDisposeRest';
import DeliveryCharges from './FoodDeliveryCharges';
import DeliveryFood from './FoodDeliveryFood';
import DeliveryCreate from './FoodDeliveryCreate';

function FoodDelivery() {
  const toast = useContext(ToastContext);
  const [deliveryList, setDeliveryList] = useState([]);
  const [deliveryListRaw, setDeliveryListRaw] = useState([]);
  const { setShow } = useContext(FoodContext);
  const [action, setAction] = useState();
  const [scope, setScope] = useState('scopeInStock');
  const [refreshFoodList, setRefreshFoodList] = useState(false);

  const scopeOptions = [
    { label: 'Show only ingredients in stock', value: 'scopeInStock' },
    { label: 'Ingredients exhausted < 30 Days ago', value: 30 },
    { label: 'Ingredients exhausted < 60 Days ago', value: 60 },
    { label: 'Ingredients exhausted < 90 Days ago', value: 90 },
    { label: 'Ingredients exhausted < 180 Days ago', value: 180 },
    { label: 'Ingredients exhausted < 365 Days ago', value: 365 },
  ];

  const originalAmountDecimal = (rowData) => {
    return rowData.originalAmount ? rowData.originalAmount.toFixed(2) : '0.00';
  };

  const currentAmountDecimal = (rowData) => {
    return rowData.currentAmount ? rowData.currentAmount.toFixed(2) : '0.00';
  };

  const bestBeforeTemplate = (rowData) =>
    Math.floor(
      (new Date(
        parseInt(rowData.bestBefore.split('.')[2], 10),
        parseInt(rowData.bestBefore.split('.')[1], 10) - 1,
        parseInt(rowData.bestBefore.split('.')[0], 10)
      ) -
        new Date()) /
        (24 * 3600 * 1000)
    ) >= -1 ? (
      rowData.bestBefore
    ) : (
      <span style={{ color: 'red' }}>{rowData.bestBefore}</span>
    );

  const columns = [
    { header: 'Delivery', field: 'delivery' },
    { header: 'Ingredient', field: 'ingredient' },
    { header: 'Supplier', field: 'supplier' },
    { header: 'Supplier ID', field: 'supplierID' },
    { header: 'Date of Delivery', field: 'deliveryDate' },
    { header: 'Best Before', field: 'bestBefore', body: bestBeforeTemplate },
    {
      header: 'Original Amount',
      field: 'originalAmount',
      body: originalAmountDecimal,
    },
    {
      header: 'Current Amount',
      field: 'currentAmount',
      body: currentAmountDecimal,
    },
  ];

  useEffect(() => {
    fetchDeliveryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDeliveryList();
    setRefreshFoodList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFoodList]);

  useEffect(() => {
    const listTemp = [];
    if (!deliveryListRaw) return null;
    if (scope !== 'scopeInStock') {
      // eslint-disable-next-line no-unused-expressions
      Array.isArray(deliveryListRaw)
        ? deliveryListRaw.map((deliveryRaw) => {
            if (
              deliveryRaw.currentAmount === 0 &&
              (new Date() - new Date(deliveryRaw.lastUpdated)) /
                (1000 * 3600 * 24) <
                scope
            ) {
              listTemp.push(deliveryRaw);
            }
            return null;
          })
        : null;
    } else {
      // eslint-disable-next-line no-unused-expressions
      Array.isArray(deliveryListRaw)
        ? deliveryListRaw.map((deliveryRaw) =>
            deliveryRaw.currentAmount !== 0 ? listTemp.push(deliveryRaw) : null
          )
        : null;
    }
    setDeliveryList(listTemp);
  }, [scope, deliveryListRaw]);

  const fetchDeliveryList = async () => {
    try {
      const deliveryListTemp = await axiosConfig
        .get('/foodLogistics/allDelivery')
        .then((res) => res.data);
      setDeliveryListRaw(deliveryListTemp);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Food List',
      });
    }
  };

  const rowAction = (rowData) => (
    <Dropdown
      options={[
        { label: 'List all served charges', value: 'listCharges' },
        { label: 'List all served foods', value: 'listFood' },
        { label: 'Dispose of the rest', value: 'disposeRest' },
        // { label: 'Show QR Code', value: 'showQr' },
        { label: 'Save QR Code', value: 'downloadQr' },
        { label: 'Save Barcode', value: 'downloadBarcode' },
      ]}
      onChange={(e) => {
        setAction({ actionValue: e.value, actionData: rowData });
      }}
      placeholder="..."
    />
  );

  const actionDialog = () => {
    if (!action || action === null || action === undefined) return null;
    switch (action.actionValue) {
      case 'disposeRest':
        return (
          <FoodDeliveryDisposeRest
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'listCharges':
        return (
          <DeliveryCharges
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'listFood':
        return (
          <DeliveryFood
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'create':
        return (
          <DeliveryCreate
            data={action.actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshFoodList(true)}
          />
        );
      case 'downloadQr':
        generateQRCode(action.actionData?.delivery, (QRTemp) => {
          downloadQRCode(QRTemp, action.actionData.delivery);
          setAction(null);
          return null;
        });
        break;
      case 'downloadBarcode':
        generateBarcodePNG(action.actionData?.delivery, (barcode) => {
          downloadQRCode(barcode, action.actionData?.delivery);
          setAction(null);
          return null;
        });
        break;
      default:
        setAction(null);
        return null;
    }
  };
  return (
    <div className="main-card main-card-content">
      {action !== null && actionDialog()}
      <div className="formgrid grid">
        <div className="field col-1 mt-2 mb-5">
          <Button
            className="p-button-rounded p-button-outlined p-button-secondary"
            icon="pi pi-arrow-left"
            onClick={() => setShow(null)}
          />
        </div>
        <div className="field col-6 mt-2 mb-5">
          <h2 className="flex align-items-center">
            <FaTruck className="mr-3" /> Ingredient Deliveries
          </h2>
        </div>
        <div className="field col-5 align-items-right">
          <span>Set scope: </span>
          <Dropdown
            value={scope}
            options={scopeOptions}
            onChange={(e) => setScope(e.value)}
          />
        </div>
        <div className="field col-12">
          <DataTable
            emptyMessage={`No ingredient deliveries found for the scope: ${scope}`}
            value={deliveryList}
            responsiveLayout="scroll"
            removableSort
          >
            {columns.map(({ header, field, body }) => (
              <Column
                header={header}
                field={field}
                body={body || null}
                sortable
              />
            ))}
            <Column header="Action" body={rowAction} />
          </DataTable>
        </div>
        {/* */}
      </div>
      <div className="p-fluid formgrid grid">
        <div className="field col-12 mt-2 mb-5">
          <Button
            className="p-button-outlined p-button-success p-button-rounded"
            label="Create new ingredient delivery"
            onClick={() =>
              setAction({ actionValue: 'create', actionData: null })
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FoodDelivery;
