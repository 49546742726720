/* eslint-disable react/prop-types */
import { useState, useContext, useCallback } from 'react';
import axiosConfig from 'utils/axiosConfig';
import QrReader from 'react-qr-reader';

import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { InputMask } from 'primereact/inputmask';
import { handleFocus } from './EggQRScan';

export async function checkFood(
  foodId,
  itemList = [],
  foodShouldExist = true,
  foodShouldBeActive = false,
  recipeId = null
) {
  const food = await axiosConfig
    .get(`/foodLogistics/check`, {
      params: {
        foodId,
      },
    })
    .then((res) => res.data);

  // For adding new items to a list with unique foodIds
  switch (true) {
    case !food && foodShouldExist:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Food does not exist!',
      };

    case food && !foodShouldExist:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Food already exists!',
      };

    case (food.Status === 'active' || food.Status === 'new') &&
      !foodShouldBeActive:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Food is already active!',
      };
    case food.Status === 'empty' && foodShouldBeActive:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Food is empty!',
      };
    case food.Status === 'inactive' && foodShouldBeActive:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Food is inactive!',
      };

    case itemList.some((item) => Object.values(item).includes(foodId)):
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Food is already in List!',
      };
    case (food.Status === 'active' || food.Status === 'new') &&
      foodShouldBeActive &&
      recipeId &&
      food.recipeId !== recipeId:
      return {
        severity: ToastSeverity.ERROR,
        detail: 'Food has the incorrect Recipe ID',
      };

    default:
      return false;
  }
}

// handle external scans
export async function handleExternalFoodScan(
  scanResult,
  mask,
  ID,
  toast,
  onResult,
  onClose = null,
  recipeId = null
) {
  // Check if box is already in list
  if (
    scanResult === null &&
    scanResult.replace(/_/g, '').length === mask.length
  ) {
    toast.pushToast({
      severity: ToastSeverity.ERROR,
      detail: 'No QR Code found!',
    });
  }

  if (
    scanResult !== null &&
    scanResult.replace(/_/g, '').length === mask.length
  ) {
    const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

    if (resultItemPrefix !== ID || !resultItemIdValue) {
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'QR Code not valid!',
      });
      return false;
    }
    // // Check if eggs exist
    const toastMsg = await checkFood(scanResult, [], true, true, recipeId);
    // push toast message if there are any
    if (toastMsg) {
      toast.pushToast(toastMsg);
      return false;
    }
    onResult(scanResult);
    onClose && onClose();
    return true;
  }

  return false;
}

function FoodQRScan({
  onResult,
  onClose,
  display,
  foodShouldExist = true,
  foodShouldBeActive = false,
  itemList = [],
  recipeId = null,
}) {
  const toast = useContext(ToastContext);
  const MASK = 'aa-99999';

  // STATES

  const [triggerPressed, setTriggerPressed] = useState(false);

  const callbackRef = useCallback((inputElement) => {
    if (inputElement && !inputElement.props) {
      inputElement.focus();
      inputElement.select();
    }
  }, []);

  const closeModal = () => {
    onClose();
  };

  const parseResult = (scanResult) => {
    // format and check scanResult e.g. BX-0012, return
    const [resultItemPrefix, resultItemIdValue] = scanResult.split('-');

    const scannableItemsPrefixes = ['BX', 'EG', 'FD', 'PS'];

    const resultItemPrefixFound = scannableItemsPrefixes.find(
      (prefix) => prefix === resultItemPrefix
    );
    // const resultItemValue = resultItem ? parseInt(resultItemIdValue, 10) : null;
    const resultItemValue = resultItemPrefixFound ? resultItemIdValue : null;

    return [resultItemPrefixFound, resultItemValue];
  };

  // add error handling if something goes wrong with QR scanner (while scanning)
  const handleScanError = (error) => {
    console.error(error);
  };

  // This function has to be modified once DB is clearly set up
  const handleScan = async (scanResult) => {
    if (scanResult === null && triggerPressed) {
      setTriggerPressed(false);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'No QR Code found!',
      });
    }
    if (scanResult !== null && triggerPressed) {
      setTriggerPressed(false);

      const [parseResultItemPrefix, parseResultItemValue] =
        parseResult(scanResult);

      if (!parseResultItemPrefix || !parseResultItemValue) {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'QR Code does not belong to scannable Item!',
        });
        return false;
      }

      if (parseResultItemPrefix === 'FD') {
        const toastMsg = await checkFood(
          scanResult,
          foodShouldExist,
          foodShouldBeActive,
          itemList,
          recipeId
        );
        if (toastMsg) {
          toast.pushToast(toastMsg);
          return false;
        }
        onClose();
        onResult(scanResult);
      } else {
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: `Wrong QR code type! Got ${parseResultItemPrefix} but expected FD`,
        });
      }
    }
    return true;
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '30vw' }}
      modal
      visible={display}
      onHide={closeModal}
    >
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-end mb-2">
          <Button
            onClick={() => onClose()}
            icon="pi pi-times"
            className="p-button-rounded p-button-warning p-button-outlined "
          />
        </div>

        <div className="field col-12 ">
          <QrReader
            delay={300}
            // style={{ width: '100%' }}
            onError={handleScanError}
            onScan={handleScan}
          />
          <InputMask
            id="foodId"
            name="foodIdInputMask"
            ref={callbackRef}
            onFocus={handleFocus}
            mask={MASK}
            placeholder="Scan Food Barcode or QR Code"
            onChange={(e) =>
              handleExternalFoodScan(
                e.value,
                MASK,
                'FD',
                toast,
                onResult,
                onClose,
                recipeId
              )
            }
          />
        </div>

        <div className=" col-12 flex justify-content-end mt-2">
          <Button
            onClick={() => setTriggerPressed(true)}
            icon="pi pi-camera"
            className="p-button-rounded p-button-outlined"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default FoodQRScan;
