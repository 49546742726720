import { useContext, useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import axiosConfig from 'utils/axiosConfig';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

// eslint-disable-next-line react/prop-types
function WorkPlanEdit({ data, display, onClose, onCompletion, workPlanList }) {
  const [workPlan, setWorkPlan] = useState(
    data || {
      WorkPlanName: ' ',
      WorkPlanID: null,
      WorkPlanDescription: ' ',
    }
  );
  const [workSteps, setWorkSteps] = useState(null);
  const [maxWorkStepId, setMaxWorkStepId] = useState(0);
  const [allWorkPlans] = useState(workPlanList);
  const [emptyStepFields, setEmptyStepFields] = useState(false);
  const [active, setActive] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [attributeValues, setAttributeValues] = useState(null);
  const toast = useContext(ToastContext);

  const deleteWorkStepTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className=" p-button-sm p-button-outlined"
        onClick={() =>
          setWorkSteps(
            workSteps.filter(
              (workStep) => workStep.WorkStepID !== rowData.WorkStepID
            )
          )
        }
        disabled={rowData.WorkStepID === 1}
      />
    );
  };

  const daysTemplate = (rowData) => (
    <span>
      {rowData?.DaysAfterStabling || rowData?.DaysAfterStabling === 0
        ? rowData.DaysAfterStabling + 1
        : null}
    </span>
  );

  const columns = [
    {
      key: 'DaysAfterStabling',
      field: 'DaysAfterStabling',
      header: 'Days',
      body: daysTemplate,
      width: '10%',
    },
    {
      key: 'WorkTaskName',
      field: 'WorkTaskName',
      header: 'Task',
      width: '25%',
    },
    {
      key: 'Notes',
      field: 'Notes',
      header: 'Notes',
      width: '45%',
    },
    {
      key: 'Delete',
      field: 'Delete',
      header: 'Delete',
      width: '10%',
      body: deleteWorkStepTemplate,
    },
  ];

  const tasksList = [
    { label: 'Inspection' },
    { label: 'Dry Feeding' },
    { label: 'Sieving' },
    { label: 'Packaging' },
  ];

  const attributes = {
    Stabling: [
      { attribute: 'recipeId', name: 'Recipe ID' },
      { attribute: 'foodWeight', name: 'Food Weight' },
      { attribute: 'eggWeight', name: 'Egg Weight' },
    ],
    'Dry Feeding': [
      { attribute: 'recipeId', name: 'Recipe ID' },
      { attribute: 'foodWeight', name: 'Food Weight' },
    ],
    Inspection: [{ attribute: 'inspectionId', name: 'Inspection Id' }],
    Sieving: null,
    Positioning: null,
    Packaging: null,
  };

  const getMaxWorkStepID = async () => {
    try {
      return axiosConfig
        .get('/workPlan/workStepId')
        .then(
          (res) =>
            1 +
            Math.max(
              res.data,
              Array.isArray(workSteps)
                ? Math.max(...workSteps.map((o) => o.WorkStepID))
                : -Infinity
            )
        );
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching work steps ID',
      });
    }
    const workStepIdMax = await fetchWorkStepId().then(
      () =>
        1 +
        Math.max(
          maxWorkStepId,
          Array.isArray(workSteps) &&
            Math.max(...workSteps.map((o) => o.WorkStepID))
        )
    );
    return workStepIdMax;
  };

  // get all ids for attributes format: {attribute: [], attribute: [] ...}
  const fetchAttributes = async () => {
    try {
      await axiosConfig
        .get('/workPlan/attributes')
        .then((res) => res.data)
        .then((attributesTemp) => setAttributeValues(attributesTemp));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching attribute values',
      });
    }
  };

  const fetchNewWorkPlanID = async (cb) => {
    try {
      await axiosConfig.get('/workPlan/workPlanId').then((res) => cb(res.data));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching new work plan ID',
      });
    }
  };

  useEffect(() => {
    if (!data && allWorkPlans && allWorkPlans.length) {
      const maxWorkPlanID =
        1 + Math.max(...allWorkPlans.map((o) => o.WorkPlanID));
      setWorkPlan({
        ...workPlan,
        WorkPlanID: maxWorkPlanID > 0 ? maxWorkPlanID : null,
      });
    } else if (!data) {
      // set new work plan id
      fetchNewWorkPlanID((maxWorkPlanId) =>
        setWorkPlan({
          ...workPlan,
          WorkPlanID: maxWorkPlanId ? maxWorkPlanId + 1 : null,
        })
      );
    }
    fetchAttributes();
    fetchWorkStepId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchWorkStepId();
    // check for empty fields
    if (workSteps !== null && workSteps !== undefined) {
      setEmptyStepFields(false);
      // eslint-disable-next-line array-callback-return
      workSteps.map((wStep) => {
        if (
          wStep.WorkTaskName === undefined ||
          wStep.WorkTaskName === '' ||
          wStep.DaysAfterStabling === undefined ||
          wStep.DaysAfterStabling === '' ||
          wStep.DaysAfterStabling === null
        ) {
          setEmptyStepFields(true);
        }
      });
    } else {
      setEmptyStepFields(true);
      if (workPlan.WorkSteps !== undefined || workPlan.WorkSteps !== null) {
        setWorkSteps(workPlan.WorkSteps);
      }
    }

    // add stabling task to work plan
    if (
      !Array.isArray(workSteps) &&
      (!Array.isArray(workPlan.WorkSteps) || workPlan.WorkSteps.length === 0)
    ) {
      getMaxWorkStepID().then((maxWorkStepIdTemp) =>
        setWorkSteps([
          {
            WorkStepID: maxWorkStepIdTemp,
            WorkTaskName: 'Stabling',
            Notes: '',
            DaysAfterStabling: 0,
          },
        ])
      );
    } else if (
      !Array.isArray(workSteps) &&
      Array.isArray(workPlan.WorkSteps) &&
      workPlan.WorkSteps.length !== 0
    ) {
      if (
        workPlan.WorkSteps.some(
          ({ WorkTaskName }) => WorkTaskName === 'Stabling'
        )
      ) {
        setWorkSteps(workPlan.WorkSteps);
      } else {
        getMaxWorkStepID().then((maxWorkStepIdTemp) =>
          setWorkSteps([
            {
              WorkStepID: maxWorkStepIdTemp,
              WorkTaskName: 'Stabling',
              Notes: '',
              DaysAfterStabling: 0,
            },
            ...workPlan.WorkSteps,
          ])
        );
      }
    } else if (Array.isArray(workSteps)) {
      if (!workSteps.some(({ WorkTaskName }) => WorkTaskName === 'Stabling')) {
        getMaxWorkStepID().then((maxWorkStepIdTemp) =>
          setWorkSteps([
            {
              WorkStepID: maxWorkStepIdTemp,
              WorkTaskName: 'Stabling',
              Notes: '',
              DaysAfterStabling: 0,
            },
            ...workPlan.WorkSteps,
          ])
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workSteps]);

  useEffect(() => {
    if (workPlan) {
      if (
        workPlan?.WorkSteps &&
        workPlan.WorkSteps.length &&
        workSteps === null
      ) {
        setWorkSteps(workPlan.WorkSteps);
      }
    } else {
      setWorkSteps([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workPlan]);

  const handleFocus = (event) => event.target.select();

  const headerText = () => {
    const text = data ? 'Edit Work Plan' : 'Create Work Plan';
    return <h4>{text}</h4>;
  };

  const onCellEditComplete = (e, stepId, attributeList = null) => {
    const { rowData, newValue, field } = e;
    rowData[field] = newValue;
    if (attributeList) {
      const workStepTemp = workSteps;
      // Array.isArray(workStepTemp) &&
      workStepTemp[
        workStepTemp.findIndex((workStep) => workStep.WorkStepID === stepId)
      ].Attributes = JSON.stringify(attributeList);
      setWorkSteps(workStepTemp);
    }
  };

  const dayEditor = (options) => {
    return (
      <InputNumber
        value={
          options.rowData[options.field] || options.rowData[options.field] === 0
            ? options.rowData[options.field] + 1
            : null
        }
        min={0}
        max={300}
        onChange={(e) => {
          let resultEvent =
            (e.value || e.value === 0) && e.value > 0 ? e.value - 1 : 0;
          if (resultEvent > 300) {
            resultEvent = 300;
          }
          const newWorkSteps = [...workSteps];
          newWorkSteps[
            newWorkSteps.findIndex(
              (workStep) => workStep.WorkStepID === options.rowData.WorkStepID
            )
          ][options.field] = resultEvent;
          setWorkSteps(newWorkSteps);
          options.editorCallback(resultEvent);
        }}
        onFocus={handleFocus}
        disabled={options.rowIndex === 0}
      />
    );
  };

  const handleAttributeChangeDropdown = (options, e) => {
    // normal callback doesn't work for some reason
    const newWorkSteps = [...workSteps];
    const workStepIndex = newWorkSteps.findIndex(
      (workStep) => workStep.WorkStepID === options.rowData.workStepId
    );
    const attributesTemp = JSON.parse(newWorkSteps[workStepIndex].Attributes);
    const attributeIndex = attributesTemp.findIndex(
      ({ attribute }) => attribute === options.rowData.attribute
    );
    attributesTemp[attributeIndex].value = e.value;
    newWorkSteps[workStepIndex].Attributes = JSON.stringify(attributesTemp);
    setWorkSteps(newWorkSteps);
    options.editorCallback(e.value);
  };

  const weightEditor = (options) => (
    <div className="p-inputgroup">
      <InputNumber
        id="weight"
        value={
          options.rowData?.attribute === 'eggWeight'
            ? (options.rowData[options.field] || 0) * 1000
            : options.rowData[options.field]
        }
        onValueChange={
          options.rowData?.attribute === 'eggWeight'
            ? (e) => options.editorCallback(e.value / 1000)
            : (e) => options.editorCallback(e.value)
        }
        mode="decimal"
        locale="de-DE"
        minFractionDigits={options.rowData?.attribute === 'eggWeight' ? 0 : 2}
        onFocus={handleFocus}
      />
      <span
        style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
        className="p-inputgroup-addon"
        title="Weight per box"
      >
        {options.rowData?.attribute === 'eggWeight' ? 'g' : 'kg'}
      </span>
    </div>
  );

  const valueEditor = (options) => {
    switch (options.rowData.attribute) {
      case 'recipeId':
        return (
          <Dropdown
            id="recipeId"
            value={options.rowData[options.field]}
            onChange={(e) => handleAttributeChangeDropdown(options, e)}
            options={
              Array.isArray(attributeValues?.recipeId) &&
              attributeValues?.recipeId.map(({ Name, RecipeID }) => ({
                label: Name,
                value: RecipeID,
              }))
            }
            placeholder="Select the recipe..."
          />
        );
      case 'foodWeight':
        return weightEditor(options);
      case 'eggWeight':
        return weightEditor(options);
      case 'inspectionId':
        return (
          <Dropdown
            id="inspectionId"
            value={options.rowData[options.field]}
            onChange={(e) => handleAttributeChangeDropdown(options, e)}
            options={
              Array.isArray(attributeValues?.inspectionId) &&
              attributeValues?.inspectionId.map(
                ({ inspectionName, inspectionId }) => ({
                  label: inspectionName,
                  value: inspectionId,
                })
              )
            }
            placeholder="Select the inspection plan..."
          />
        );
      default:
        return (
          <InputNumber
            value={options.rowData[options.field]}
            onValueChange={(e) => options.editorCallback(e.value)}
            mode="decimal"
            locale="de-DE"
            minFractionDigits={2}
            onFocus={handleFocus}
          />
        );
    }
  };

  const taskEditor = (options) => {
    return (
      <Dropdown
        value={options.rowData[options.field]}
        options={tasksList}
        onChange={(e) => {
          const newWorkSteps = [...workSteps];
          newWorkSteps[
            newWorkSteps.findIndex(
              (workStep) => workStep.WorkStepID === options.rowData.WorkStepID
            )
          ][options.field] = e.value.label;
          setWorkSteps(newWorkSteps);
          options.editorCallback(e.value.label);
        }}
        placeholder={options.rowData[options.field]}
        disabled={options.rowIndex === 0}
      />
    );
  };

  const notesEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.rowData[options.field]}
        onChange={(e) => {
          const newWorkSteps = [...workSteps];
          newWorkSteps[
            newWorkSteps.findIndex(
              (workStep) => workStep.WorkStepID === options.rowData.WorkStepID
            )
          ][options.field] = e.target.value.substring(0, 150);
          setWorkSteps(newWorkSteps);
          options.editorCallback(e.target.value.substring(0, 150));
        }}
      />
    );
  };

  const cellEditor = (options) => {
    switch (options.field) {
      case 'DaysAfterStabling':
        return dayEditor(options);
      case 'WorkTaskName':
        return taskEditor(options);
      case 'Notes':
        return notesEditor(options);
      case 'Delete':
        return deleteWorkStepTemplate(options);
      case 'value':
        return valueEditor(options);
      default:
        return null;
    }
  };

  const attributeValue = (rowData) => {
    switch (rowData.attribute) {
      case 'recipeId':
        return attributeValues[rowData.attribute].find(
          ({ RecipeID }) => RecipeID === rowData.value
        )?.Name;
      case 'inspectionId':
        return attributeValues[rowData.attribute].find(
          ({ inspectionId }) => inspectionId === rowData.value
        )?.inspectionName;
      case 'foodWeight':
        return `${rowData.value || 0} kg`;
      case 'eggWeight':
        return `${(rowData.value || 0) * 1000} g`;
      default:
        return rowData.value;
    }
  };

  const workPlanFields = (dataInput) => {
    return (
      <>
        <h5>Work Plan Information</h5>
        <div className="formgrid grid align-items-end">
          <div className="field col-6">
            <label htmlFor="WorkPlanName">Work Plan Name</label>
            <InputText
              value={workPlan.WorkPlanName}
              onChange={(e) =>
                setWorkPlan({
                  ...workPlan,
                  WorkPlanName: e.target.value.substring(0, 50),
                })
              }
              id="WorkPlanName"
              type="text"
              onFocus={handleFocus}
            />
          </div>
          <div className="field col-6">
            <label htmlFor="WorkPlanID">Work Plan ID</label>
            <InputText
              value={workPlan.WorkPlanID}
              id="WorkPlanID"
              type="text"
              disabled
            />
          </div>
          <div className="field col-12">
            <label htmlFor="description">Description</label>
            <InputText
              value={workPlan.WorkPlanDescription}
              onChange={(e) =>
                setWorkPlan({
                  ...workPlan,
                  WorkPlanDescription: e.target.value.substring(0, 500),
                })
              }
              id="description"
              type="text"
            />
          </div>
        </div>
      </>
    );
  };

  const attributeNameTemplate = (rowData) =>
    rowData.name === 'Inspection Id' ? 'Inspection Name' : rowData.name;

  const columnsSubTable = [
    {
      header: 'Attribute',
      field: 'name',
      body: attributeNameTemplate,
    },
    {
      header: 'Value',
      field: 'value',
      editor: cellEditor,
      body: attributeValue,
    },
  ];

  const fetchWorkStepId = async () => {
    try {
      await axiosConfig
        .get('/workPlan/workStepId')
        .then((res) => setMaxWorkStepId(res.data));
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching work steps ID',
      });
    }
  };

  const addRow = async () => {
    return getMaxWorkStepID().then((maxWorkStepID) => {
      if (Array.isArray(workSteps)) {
        setWorkSteps([
          ...workSteps,
          {
            WorkStepID: maxWorkStepID,
            Notes: ' ',
            WorkTaskName: 'Inspection',
            createdAt: Date(),
          },
        ]);
      } else {
        setWorkSteps([
          {
            WorkStepID: maxWorkStepID,
            Notes: ' ',
            WorkTaskName: 'Inspection',
            createdAt: Date(),
          },
        ]);
      }
      setEmptyStepFields(true);
    });
  };

  const reset = () => {
    setWorkSteps(workPlan.WorkSteps);
  };

  const incrementName = (listOfNames, name) => {
    if (listOfNames) {
      let equalName = 0;
      const match = name.match(/\[\d+\]/);
      const nameTemp = match
        ? name.substring(0, name.length - (1 + match[0].length))
        : name;

      // eslint-disable-next-line array-callback-return
      listOfNames.map((n) => {
        equalName +=
          (n.match(/\[\d+\]/)
            ? n.substring(0, n.length - (1 + n.match(/\[\d+\]/)[0].length))
            : n) === nameTemp
            ? 1
            : 0;
      });
      return equalName > 0 ? `${nameTemp} [${equalName}]` : name;
    }
    return name;
  };

  const editWorkPlan = async (workPlanData, createNewName = true) => {
    const workPlanTemp = {
      ...workPlanData,
      WorkPlanName: createNewName
        ? incrementName(
            allWorkPlans.map((o) => o.WorkPlanName),
            workPlan.WorkPlanName
          )
        : workPlan.WorkPlanName,
    };
    // remove unnecessary variables from temp if they exist
    workPlanTemp?.NumberOfSteps && delete workPlanTemp.NumberOfSteps;
    workPlanTemp?.NumberOfCharges && delete workPlanTemp.NumberOfCharges;
    workPlanTemp?.WorkSteps && delete workPlanTemp.WorkSteps;

    // get associations
    const listOfWorkStepID = [];
    workSteps.map((o) =>
      listOfWorkStepID.push({
        WorkPlanID: workPlanData.WorkPlanID,
        WorkStepID: o.WorkStepID,
      })
    );
    try {
      await axiosConfig.post('workPlan/workSteps', workSteps);

      await axiosConfig.post('workPlan/workPlans', {
        ...workPlanTemp,
        Status: 'active',
      });

      await axiosConfig.post('workPlan/workPlansWorkSteps', listOfWorkStepID);

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Created new work plan',
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
    reset();
    onCompletion();
    onClose();
  };

  const createNewWorkPlan = () => {
    const maxWorkPlanID =
      1 + Math.max(...allWorkPlans.map((o) => o.WorkPlanID));
    editWorkPlan(
      {
        ...workPlan,
        WorkPlanID: maxWorkPlanID,
        WorkPlanName: workPlan.WorkPlanName,
      },
      true
    );
  };

  const attributeTable = (rowData) => {
    let attributeList = rowData?.Attributes && JSON.parse(rowData?.Attributes);

    // case 1: no attributes saved and attributes in list for work step
    if (
      !Array.isArray(attributes[rowData?.WorkTaskName]) ||
      attributes[rowData?.WorkTaskName].length === 0
    ) {
      return <span>No attributes available</span>;
    }

    // case 2: no attributes in list for work step
    if (
      (!Array.isArray(attributeList) || attributeList.length === 0) &&
      Array.isArray(attributes[rowData?.WorkTaskName])
    ) {
      attributeList = attributes[rowData?.WorkTaskName].map(
        (attribute, prop) => ({
          key: prop,
          attribute: attribute.attribute,
          name: attribute.name,
          value: null,
          workStepId: rowData?.WorkStepID,
        })
      );
    }
    // case 3: some attributes  do not exist in attribute list
    else if (
      Array.isArray(attributeList) &&
      Array.isArray(attributes[rowData?.WorkTaskName])
    ) {
      attributeList = attributes[rowData?.WorkTaskName].map((attribute) => ({
        attribute: attribute.attribute,
        name: attribute.name,
        value: attributeList.find(
          (attributeObj) => attributeObj.attribute === attribute.attribute
        )?.value,
        workStepId: rowData?.WorkStepID,
      }));
    }
    return (
      <div className="col-12">
        <DataTable
          value={attributeList}
          size="small"
          editMode="cell"
          className="editable-cells-table"
          responsiveLayout="scroll"
        >
          {columnsSubTable.map((column) => {
            const { header, field, editor, body, minWidth } = column;
            return (
              <Column
                field={field}
                header={header}
                editor={editor}
                onCellEditComplete={(e) =>
                  onCellEditComplete(e, rowData.WorkStepID, attributeList)
                }
                body={body}
                style={{ minWidth }}
              />
            );
          })}
        </DataTable>
      </div>
    );
  };

  const removeAssignedCharges = async () => {
    try {
      await axiosConfig.post('workPlan/deleteWorkPlanCharges', workPlan);

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Unassigned charges',
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
    editWorkPlan(workPlan, false);
  };

  const confirmationDialog = () => {
    if (workPlan.NumberOfCharges === 0) {
      editWorkPlan(workPlan, !data);
      return null;
    }
    return (
      <Dialog
        className="dialog-card"
        showHeader={false}
        style={{ width: '50vw' }}
        modal
        visible={display}
        onHide={onClose}
      >
        <div className="p-fluid formgrid grid">
          <div className=" col-12 flex justify-content-between mb-2">
            <h4>Confirm Reassignment</h4>
          </div>
        </div>
        <div className="field col-12">
          <span>
            The work plan has been assigned to {workPlan.NumberOfCharges}{' '}
            charges. How do you wish to proceed?
          </span>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col-6 flex">
            <Button
              onClick={() => {
                removeAssignedCharges();
              }}
              label="Save and unassign"
              className="p-button-rounded p-button-success"
            />
          </div>
          {/* TODO update assignmet */}
          {/* <div className="field col-4 flex">
            <Button
              onClick={() => {
                editWorkPlan(workPlan, false);
              }}
              label="Save and keep assignment"
              className="p-button-rounded p-button-success"
            />
          </div> */}
          <div className="field col-6 flex">
            <Button
              onClick={() => {
                createNewWorkPlan();
              }}
              label="Save as new work plan"
              className="p-button-rounded p-button-success"
            />
          </div>
          <div className="field col-12">
            <Button
              onClick={() => {
                reset();
                setActive(null);
              }}
              label="Cancel"
              className="p-button-rounded p-button-secondary"
            />
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '80vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      {active === true && confirmationDialog(active)}
      <div className="p-fluid formgrid grid">
        <div className=" col-12 flex justify-content-between mb-2">
          {headerText()}
        </div>
        <div className="field col-12">{workPlanFields(data)}</div>
        <div className="field col-12">
          <h5>Work Steps</h5>
          <DataTable
            value={workSteps}
            expandedRows={expandedRows}
            // rowClassName={rowClassName}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={attributeTable}
            showGridlines
            scrollable
            size="small"
            scrollHeight="50vh"
            sortField="DaysAfterStabling"
            sortOrder={1}
            editMode="cell"
          >
            <Column expander style={{ minWidth: '5%' }} />
            {columns.map(({ key, field, header, width, body }) => (
              <Column
                key={key}
                field={field}
                header={header}
                style={{ minWidth: width }}
                editor={(options) =>
                  key !== 'Delete' ? cellEditor(options) : body(options.rowData)
                }
                body={body}
              />
            ))}
          </DataTable>
        </div>
      </div>
      <div className="p-fluid formgrid grid">
        <div className="field col-12">
          <Button
            onClick={() => addRow()}
            label="Add new work step"
            className="p-button-rounded p-button-success p-button-outlined"
          />
        </div>
      </div>
      {(!workPlan?.WorkPlanID ||
        !workPlan?.WorkPlanName ||
        emptyStepFields ||
        !workSteps?.length) && (
        <span style={{ color: 'orange' }}>
          Saving disabled due to missing:{' '}
          {!workPlan?.WorkPlanID ? 'work plan id, ' : null}
          {!workPlan?.WorkPlanName ? 'work plan name, ' : null}
          {emptyStepFields ? 'work step fields like "Days", ' : null}
          {!workSteps?.length ? 'work steps' : null}
        </span>
      )}
      <div className="p-fluid formgrid grid">
        <div className="field col-8" />
        <div className="field col-2 ">
          <Button
            onClick={() => {
              reset();
              onClose();
            }}
            label="Cancel"
            className="p-button-rounded p-button-secondary"
          />
        </div>
        <div className="field col-2 ">
          <Button
            onClick={() => {
              !data || workPlan?.NumberOfCharges === 0
                ? editWorkPlan(workPlan, !data)
                : setActive(true);
            }}
            label="Confirm"
            className="p-button-rounded p-button-success"
            disabled={
              !workPlan ||
              !workPlan.WorkPlanID ||
              !workPlan.WorkPlanName ||
              emptyStepFields ||
              !workSteps?.length
            }
          />
        </div>
      </div>
    </Dialog>
  );
}

export default WorkPlanEdit;
