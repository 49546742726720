import { FaClipboardList } from 'react-icons/fa';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';
import { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import WorkPlanDelete, { addPrefix } from './WorkPlanDelete';
import WorkPlanEdit from './WorkPlanEdit';
import WorkPlanAssign from './WorkPlanAssign';

function WorkPlans() {
  const toast = useContext(ToastContext);
  const [workPlanList, setWorkPlanList] = useState(null);
  const [workPlanListRaw, setWorkPlanListRaw] = useState([]);
  const [refreshWorkPlanList, setRefreshWorkPlanList] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [action, setAction] = useState(null);
  const [actionData, setActionData] = useState(null);

  const daysTemplate = (rowData) => (
    <span>
      {rowData?.DaysAfterStabling || rowData?.DaysAfterStabling === 0
        ? rowData.DaysAfterStabling + 1
        : null}
    </span>
  );

  const columnsTableExpansion = [
    { header: 'Name', field: 'WorkPlanName' },
    {
      header: 'Work Plan ID',
      field: 'WorkPlanID',
      body: (data) => addPrefix(data.WorkPlanID, 'WP', 5),
    },
    { header: 'Work Step', field: 'NumberOfSteps' },
    { header: 'Charges Assigned', field: 'NumberOfCharges' },
    { header: 'Description', field: 'WorkPlanDescription' },
  ];

  const workPlanSubTable = (data) => {
    const columnsSubTable = [
      {
        header: 'Days After Stabling',
        field: 'DaysAfterStabling',
        body: daysTemplate,
      },
      { header: 'Name', field: 'WorkTaskName' },
      { header: 'Note', field: 'Notes' },
    ];
    if (data === null) {
      return <span>no data</span>;
    }
    const workData = data.WorkSteps.sort(
      (a, b) => a.DaysAfterStabling - b.DaysAfterStabling
    );
    return (
      <div className="orders-subtable">
        <h5>Work Steps for {data.WorkPlanName}</h5>
        <DataTable value={workData}>
          {columnsSubTable.map((column) => {
            const { header, field, body } = column;
            return (
              <Column field={field} header={header} body={body} sortable />
            );
          })}
        </DataTable>
      </div>
    );
  };

  const fetchTableData = async () => {
    try {
      const workPlansTemp = await axiosConfig
        .get('/workPlan/workPlans')
        .then((res) => res.data);
      setWorkPlanListRaw(workPlansTemp);
      const workPlans = [];
      // eslint-disable-next-line no-unused-expressions
      Array.isArray(workPlansTemp)
        ? workPlansTemp.map(async (workPlan) => {
            if (workPlan.Status === 'active') {
              const NumberOfSteps = Array.isArray(workPlan.WorkSteps)
                ? workPlan.WorkSteps.length
                : 0;
              const NumberOfCharges = Array.isArray(workPlan.Charges)
                ? workPlan.Charges.length
                : 0;
              // eslint-disable-next-line no-unused-expressions
              workPlans.push({
                ...workPlan,
                NumberOfSteps,
                NumberOfCharges,
              });
            }
          })
        : null;
      setWorkPlanList(workPlans);
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Data',
      });
    }
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTableData();
    setRefreshWorkPlanList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshWorkPlanList]);

  const collapseAll = () => {
    setExpandedRows(null);
  };

  // eslint-disable-next-line consistent-return
  const dialog = (mode, data) => {
    switch (mode) {
      case 'delete':
        return (
          <WorkPlanDelete
            data={actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshWorkPlanList(true)}
          />
        );

      case 'edit':
        return (
          <WorkPlanEdit
            data={actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshWorkPlanList(true)}
            workPlanList={workPlanListRaw}
          />
        );
      case 'create':
        return (
          <WorkPlanEdit
            data={null}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshWorkPlanList(true)}
            workPlanList={workPlanListRaw}
          />
        );

      case 'assign':
        return (
          <WorkPlanAssign
            data={actionData}
            display={Boolean(true)}
            onClose={() => setAction(null)}
            onCompletion={() => setRefreshWorkPlanList(true)}
          />
        );

      default:
        return null;
    }
  };

  const editRow = (rowData) => {
    const actionOptions = [
      { label: 'Delete', value: 'delete' },
      { label: 'Edit', value: 'edit' },
      // TODO update workplan assignment
      // { label: 'Assign', value: 'assign' },
    ];
    return (
      <Dropdown
        value="..."
        options={actionOptions}
        onChange={(e) => {
          setAction(e.value);
          setActionData(rowData);
        }}
        placeholder="..."
      />
    );
  };

  return (
    <div className="main-card main-card-content">
      {action != null && dialog(action, actionData)}
      <div className="p-fluid formgrid grid">
        <div className="col-10">
          <div className="mt-2 mb-5">
            <h2 className="flex align-items-center">
              <FaClipboardList className="mr-3" /> Work Plans
            </h2>
          </div>
        </div>
        <div className="col-2 align-items-right">
          <Button
            className="p-button-secondary"
            label="Collapse All"
            onClick={collapseAll}
          />
        </div>
      </div>
      <DataTable
        value={workPlanList}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        responsiveLayout="scroll"
        dataKey="WorkPlanID"
        rowExpansionTemplate={workPlanSubTable}
        emptyMessage="No work plans found"
      >
        <Column expander style={{ width: '3em' }} />
        {columnsTableExpansion.map((column) => {
          const { header, field, body } = column;
          return (
            <Column
              key={header}
              field={field}
              header={header}
              body={body || null}
              sortable
            />
          );
        })}
        <Column header="Action" style={{ width: '3em' }} body={editRow} />
      </DataTable>
      <div className="p-fluid formgrid grid">
        <Button
          className="p-button-rounded p-button-success p-button-outlined"
          label="Create new work plan"
          onClick={(_) => {
            setAction('create');
            setActionData(workPlanListRaw);
          }}
        />
      </div>
    </div>
  );
}

export default WorkPlans;
