import { useContext } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import axiosConfig from 'utils/axiosConfig';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

// eslint-disable-next-line react/prop-types
function FoodDisposeRest({ data, display, onClose, onCompletion }) {
  const toast = useContext(ToastContext);
  // eslint-disable-next-line react/prop-types
  const { name, foodID, currentAmount } = data;

  const disposeRest = async (FoodID) => {
    try {
      const ID = parseInt(FoodID.substring(3), 10);
      await axiosConfig.post('/foodLogistics/disposeRest/', { ID });
      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Disposed remaining food',
      });
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while disposing food',
      });
    }
    onClose();
    onCompletion();
  };

  const headerText = () => {
    const text = 'Confirm Disposing Remaining Food';
    return <h3>{text}</h3>;
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '50vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      <div className="p-fluid formgrid grid">
        <div className="field col-12 flex justify-content-between mb-2">
          {headerText()}
        </div>
        <div className="field col-12">
          <span />
          {`Are you sure to you want to manually dispose of the remaining ${currentAmount} kg ${name} (ID: ${foodID})? \n  On confirmation the remaining amount will be set to 0`}
        </div>
        <div className=" col-8" />
        <div className=" col-2 ">
          <Button
            onClick={() => onClose()}
            label="Cancel"
            className="p-button-rounded p-button-secondary"
          />
        </div>
        <div className="field col-2 ">
          <Button
            onClick={() => disposeRest(foodID)}
            label="Confirm"
            className="p-button-rounded p-button-success"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default FoodDisposeRest;
